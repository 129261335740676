import { Injectable, EventEmitter } from '@angular/core';

/**
 * Сервис сохранения настроек в локальное хранилище браузера
 */
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private localStorageEventEmitter: EventEmitter<string[]> = new EventEmitter(); // генератор событий об изменения в local storage

  // получить event emitter
  public getLocalStorageEventEmitter(): EventEmitter<any> {
    return this.localStorageEventEmitter;
  }

  // получить по идентификатору
  public getObjectById<T = any>(objectId: string): T {
    return objectId ? this.getObjectByName(String(objectId)) : null;
  }

  // получить по имени
  public getObjectByName<T = any>(objectName: string): T {
    // getFromLocalStorage
    const resultAsString: string = localStorage.getItem(objectName);
    if (resultAsString === null || !objectName) {
      return null;
    }
    return objectName ? JSON.parse(resultAsString) : null;
  }

  // сохранение в локальное хранилище по идентификатору
  public setObjectById(objectName: string, object: any): void {
    this.setObjectByName(String(objectName), object);
    this.localStorageEventEmitter.emit([objectName]);
  }

  // сохранение в локальное хранилище по имени
  public setObjectByName(objectName: string, object: any): void {
    // setToLocalStorage
    localStorage.setItem(objectName, JSON.stringify(object));
    this.localStorageEventEmitter.emit([objectName]);
  }

  // удалить по идентификатору
  public removeObjectById(objectId: string): void {
    this.removeObjectByName(String(objectId));
    this.localStorageEventEmitter.emit([objectId]);
  }

  // удалить по имени
  public removeObjectByName(objectName: string): void {
    localStorage.removeItem(objectName);
    this.localStorageEventEmitter.emit([objectName]);
  }

  // удалить по массиву имен
  public removeObjectsByNames(objectNames: string[]): void {
    for (const objectName of objectNames) {
      localStorage.removeItem(objectName);
    }
    this.localStorageEventEmitter.emit(objectNames);
  }

  // очистить полностью
  public removeAll(): void {
    localStorage.clear();
    this.localStorageEventEmitter.emit(null);
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { map, share } from "rxjs/operators";
import {IAdminUser, IUpdateUserParams, IUserSessionInfo, IUsersReportParams} from "@core/interfaces/admin-users";
import { IGridRequestPayload, IPagedResponse } from "@shared/components/base/ag-base-grid/ag-base-grid.interface";
import { Observable } from "rxjs";
import { IDictionaryBody } from "@core/interfaces/dictionary";
import {FileService} from "@core/services/file.service";

@Injectable({
  providedIn: 'root'
})
export class AdminUsersApiService {

  constructor(
    private httpClient: HttpClient,
    private fileService: FileService
  ) { }

  public getUser(id: number): Observable<IAdminUser> {
    return this.httpClient.get<IAdminUser>(`${environment.API_URL}/user/info/${id}`).pipe(share());
  }

  public addUser(params: IUpdateUserParams) {
    return this.httpClient.post(`${environment.API_URL}/user/create`, params).pipe(share());
  }

  public updateUser(params: IUpdateUserParams) {
    return this.httpClient.put(`${environment.API_URL}/user/update`, params).pipe(share());
  }

  public deleteUser(id: number) {
    return this.httpClient.delete(`${environment.API_URL}/user/delete/${id}`).pipe(share());
  }

  public unlockUser(id: number) {
    return this.httpClient.post(`${environment.API_URL}/user/${id}/block/clear`, {}).pipe(share());
  }

  public clearUserSession(id: number) {
    return this.httpClient.post(
      `${environment.API_URL}/user/session/clear/${id}`,
      {}
    ).pipe(share());
  }

  public getUsersGrid(requestPayload: IGridRequestPayload): Observable<IPagedResponse> {
    return this.httpClient.post<IPagedResponse>(
      `${environment.API_URL}/user/grid`,
      requestPayload
    ).pipe(share());
  }

  public getUserDictionary(requestPayload: IGridRequestPayload)
    : Observable<IDictionaryBody> {
    return this.httpClient.post<IPagedResponse>(
      `${environment.API_URL}/user/grid`,
      requestPayload
    ).pipe(
      map((item: IPagedResponse) => {
        const list = item && item.elements && item.elements.length > 0 ? item.elements : [];
        item.elements = list && list.length > 0
          ? list.map((l) => {
            return { key: l.id, value: l.login };
          }) : [];
        return item;
      }), share());
  }

  public syncUserList(subsystemId: string | number): Observable<any> {
    return this.httpClient.post<any>(`${environment.API_URL}/user/sync/${subsystemId}`, {}).pipe(share());
  }

  public getIpAddressGrid(userId: number, requestPayload: IGridRequestPayload): Observable<IPagedResponse> {
    return this.httpClient.post<IPagedResponse>(
      `${environment.API_URL}/user/${userId}/ip/grid`,
      requestPayload
    ).pipe(share());
  }

  public addIpAddress(userId: number, ip: string) {
    return this.httpClient.post(
      `${environment.API_URL}/user/${userId}/ip/create`,
      { ip_address: ip }
    ).pipe(share());
  }

  public deleteIpAddress(userId: number, ipId: number) {
    return this.httpClient.delete(
      `${environment.API_URL}/user/${userId}/ip/delete/${ipId}`
    ).pipe(share());
  }

  public getUserSessionInfo(id: number): Observable<IUserSessionInfo> {
    return this.httpClient.get<IUserSessionInfo>(
      `${environment.API_URL}/user/${id}/activity`
    ).pipe(share());
  }

  public getUsersReport(params: IUsersReportParams) {
    return this.fileService.downloadFile(
      `${environment.API_URL}/report/generate`, null, params, true);
  }
}

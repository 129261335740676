import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {IGridPaginationSize} from "@shared/components/base/ag-base-grid/ag-base-grid.interface";

@Component({
  selector: 'app-ag-grid-pagination',
  templateUrl: './ag-grid-pagination.component.html',
  styleUrls: ['./ag-grid-pagination.component.scss']
})
export class AgGridPaginationComponent implements OnInit {

  public enableClickOutside = false; // Флаг управления работы outsideClickEvent
  public sizes: IGridPaginationSize[] = [];
  private footerText: any;
  private defaultInitCountVisibleRows = 10;

  @Input() sizeG: number[] = [5, 10, 20, 30, 50, 100, 200, 500, 1000];
  @Input() gridAllData = 0;
  @Input() startData = 0;
  @Input() endData = 0;
  @Input() disablePrevBtn = true; // флаг блокировки кнопки Назад
  @Input() disableNextBtn = true; // флаг блокировки кнопки Вперед
  @Input() initCountVisibleRows: number = this.defaultInitCountVisibleRows; // количества строк в гриде при первой загрузке
  @Input() disableCount: boolean = false;
  @Input() currentRowCount = 0;
  @Output() btPrevious: EventEmitter<any> = new EventEmitter();
  @Output() btNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeCountVisibleRows: EventEmitter<IGridPaginationSize> = new EventEmitter();

  constructor(
    protected translate: TranslateService,
  ) {
    translate.get(['GRID.OTHER.PAGE']).subscribe(
      res => {
        this.footerText = {
          otherPage: res['GRID.OTHER.PAGE']
        }
      }
    )
  }

  ngOnInit() {
    for (let number of this.sizeG) {
      let size: IGridPaginationSize = {
        value: number,
        text: String(number),
        active: false
      };

      this.sizes.push(size);
    }

    this.sizes[0].text = [this.sizes[0].text, this.footerText.otherPage].join(' ');
    const idxInitCount = this.sizes.findIndex(item => item.value === this.initCountVisibleRows);

    idxInitCount !== -1
      ? this.sizes[idxInitCount].active = true
      : this.sizes[this.defaultInitCountVisibleRows].active = true
  }

  public showSize(val: boolean): void {
    this.enableClickOutside = val;
  }

  public onBtPrevious(): void {
    this.btPrevious.emit();
  }

  public onBtNext(): void {
    this.btNext.emit();
  }

  public onChangeSize(item: IGridPaginationSize) {
    this.sizes.forEach(item => item.active = (false) ? true : false);
    this.enableClickOutside = false;
    item.active = true;
    this.changeCountVisibleRows.emit(item)
  }


}

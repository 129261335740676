import { EventEmitter, Input, Output } from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";
import { BaseFieldComponent } from "./base-field.component";
import * as dayjs from 'dayjs';
import { BehaviorSubject } from "rxjs";
import { DateFormat } from "@app/shared/services/date-time.service";
import { IPopupContainerCommonProperties } from "../popup-container/components/popup-container-base/popup-container-base";
import { getEventComposedPath } from "@app/shared/functions";

/**
 * Интерфейс с общими свойствами календарей.
 * Некоторые компоненты не расширяют {@link BaseCalendarComponent},
 * например, date-time-input / time-select-input,
 * однако имеют общие свойства.
 */
export interface IBaseCalendarProperties<T> {
  /**
   * Выбранное значение.
   */
  selected: T;
  /**
   * Событие изменения значения.
   */
  readonly OnSelect: EventEmitter<T>;
  /**
   * Формат даты {@link DateFormat}
   */
  format?: DateFormat;
  /**
   * Флаг возможности изменения минут.
   */
  withMinutes?: boolean;
  /**
   * Флаг отображения секунд.
   */
  withSeconds?: boolean;
  /**
   * Минимальная дата в формате {@link format}
   */
  minDate?: string;
  /**
   * Максимальная дата в формате {@link format}
   */
  maxDate?: string;
  /**
   * Минимальный месяц в минимальном году.
   */
  minMonth?: number;
  /**
   * Максимальный месяц в максимальном году.
   */
  maxMonth?: number;
  /**
   * Минимальный год.
   */
  minYear?: number;
  /**
   * Максимальный год.
   */
  maxYear?: number;
  /**
   * Флаг необходимости ограничения минимальных/максимальных значений.
   */
  isNeedLimit?: boolean;
  /**
   * Флаг отображения для календаря
   */
  forCalendar?: boolean;
  /**
   * Флаг запрета ручного ввода.
   */
  noManualInput?: boolean;
  /**
   * Флаг наличия иконки очистки.
   */
  withRemove?: boolean;
  /**
   * Флаг отображения иконок календаря.
   */
  withIcon?: boolean;
  /**
   * Флаг отображения стрелок переключения.
   */
  withArrows?: boolean;
  /**
   * Флаг наличия общего стиля при наведении курсора на поле.
   */
  withHover?: boolean;
}


export abstract class BaseCalendarComponent<T>
  extends BaseFieldComponent
  implements ControlValueAccessor,
  IPopupContainerCommonProperties,
  IBaseCalendarProperties<T> {
  /**
   * Сеттер выбранного значения.
   * Вызывает {@link writeValue}
   */
  @Input('selected') public set _selected(value: T) {
    this.writeValue(value);
  }
  /**
   * @inheritdoc
   */
  public selected: T;
  /**
   * Текущий момент
   */
  protected currentMoment: dayjs.Dayjs = dayjs();
  /**
    * Минимальный год
    * @default текущий год - 20
    */
  @Input() minYear: number = this.currentMoment.year() - 20;
  /**
   * Максимальный год
   * @default текущий год + 20
   */
  @Input() maxYear: number = this.currentMoment.year() + 20;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() isNeedLimit: boolean = true;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withRemove: boolean = true;
  /**
   * Флаг отображения основной иконки
   * @default true
   */
  @Input() withIcon: boolean = true;
  /**
   * Тултип иконки блокировки.
   */
  @Input() disabledTooltip: string | string[];
  /**
   * Направление открытия
   * @default 'bottom center'
   * @options 'bottom', 'top', 'left', 'right', 'center'
   */
  @Input() openDirection: string = 'bottom center';
  /**
   * @inheritdoc
   */
  @Input() popupContainerTarget?: HTMLElement;
  /**
   * @inheritdoc
   */
  @Input() popupContainerClass: string = null;
  /**
   * @inheritdoc
   * @default 0
   */
  @Input() popupContainerOffsetTop: number = 0;
  /**
   * @inheritdoc
   * @default 0
   */
  @Input() popupContainerOffsetLeft: number = 0;
  /**
   * @inheritdoc
   * @default [0,5]
   */
  @Input() popupContainerOffsetRelative: [number, number] = [0, 5];
  /**
   * @inheritdoc
   * @default true
   */
  @Input() popupContainerPreventOutsideWindow: boolean = true;
  /**
   * Флаг открытия выпадающего списка
   */
  public readonly openedSelect$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * @inheritdoc
   */
  @Output() readonly OnSelect: EventEmitter<T> = new EventEmitter<T>();

  /**
   * Коллбек на событие clickOutside
   * @param $event событие
   * @param balloon попап
   * @returns действительно ли произошел клик вне компонента
   * (втч вне попапа).
   */
  public onClickOutside($event: Event, balloon: HTMLElement): boolean {
    const composedPath = getEventComposedPath($event);
    const balloonClick: boolean = !!composedPath.find((el) =>
      el instanceof Node ? balloon.contains(el) : false
    );
    if (!balloonClick) {
      this.toggleOpen();
    }
    return !balloonClick;
  }

  /**
   * Открытие/закрытие попапа.
   */
  public toggleOpen() {
    if (this.disabled) {
      return;
    }
    this.openedSelect$.next(
      !this.openedSelect$.getValue()
    );
  }

  /**
   * Функция передачи выбранного значения.
   * Вызывает OnSelect и onChange (при наличии).
   * @param value значение
   */
  protected emitChanges(value: T): void {
    if (this.onChange) {
      this.onChange(value);
    }
    this.OnSelect.next(value);
  }

  /**
   * Метод очистки значения.
   */
  public clear() {
    this.openedSelect$.next(false);
    this.writeValue(null);
    this.emitChanges(null);
  }
}

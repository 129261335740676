import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IQuarterYear } from '@app/core/interfaces/select-item';
import { TranslateService } from "@ngx-translate/core";
import * as dayjs from 'dayjs';
import * as dayjsQuarter from 'dayjs/plugin/quarterOfYear';
dayjs.extend(dayjsQuarter);
import * as cloneDeep from 'lodash.clonedeep';
import { IBaseCalendarProperties } from '../../base-components/base-calendar.component';
import { SelectFieldComponent } from '../../select-field/select-field.component';
import { CalendarWithApplySupportComponent } from '../calendar-with-apply/calendar-with-apply-support.component';

interface IQuarterListItem {
  key: number;
  value: string;
  icon: string;
}

@Component({
  selector: 'app-quarter-select',
  templateUrl: './quarter-select.component.html',
  styleUrls: ['./quarter-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuarterSelectComponent),
      multi: true
    },
    {
      provide: CalendarWithApplySupportComponent,
      useExisting: forwardRef(() => QuarterSelectComponent)
    }
  ]
})
export class QuarterSelectComponent
  extends CalendarWithApplySupportComponent<IQuarterYear>
  implements ControlValueAccessor,
  IBaseCalendarProperties<IQuarterYear> {
  /**
   * Переводы кварталов
   */
  private readonly quarterTranslates =
    this.translateService.instant('DATE.QUARTERS');
  /**
   * Текущий год
   */
  public readonly currentYear = this.currentMoment.year();
  /**
   * Текущий квартал
   */
  public readonly currentQuarter = this.currentMoment.quarter();
  /**
   * Список кварталов
   * IE fix
   */
  public quarterList: IQuarterListItem[] = Object.keys(
    this.quarterTranslates || {}
  ).map((key: string, index: number) => ({
    key: index + 1,
    value: this.quarterTranslates[key],
    icon: `icon-quarter_${index + 1}`,
  }));

  /**
   * Минимальный квартал в минимальном году.
   * @default 1
   */
  @Input() minQuarter: number = 1;
  /**
   * Максимальный квартал в максимальном году.
   * @default текущий квартал
   */
  @Input() maxQuarter: number = this.currentQuarter;
  /**
   * Плейсхолдер квартала.
   */
  @Input() quarterPlaceholder: string = this.translateService.instant('DATE.QUARTER');
  /**
   * Плейсхолдер года.
   */
  @Input() yearPlaceholder: string = this.translateService.instant('DATE.YEAR');
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withHover: boolean = true;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withArrows: boolean = true;

  private get defaultSelected(): IQuarterYear {
    return { quarter: this.currentQuarter, year: this.currentYear };
  }

  @ViewChild(SelectFieldComponent, { read: ElementRef }) selectFieldComponent: ElementRef;

  constructor(
    private translateService: TranslateService
  ) {
    super();
  }

  /**
   * Функция проверки и замены
   * мин/макс значений.
   */
  private checkAndReplaceMinMax() {
    if (this.isNeedLimit) {
      if (this.selected.year === this.maxYear && this.selected.quarter > this.maxQuarter) {
        this.selected.quarter = this.maxQuarter;
      }
      else if (this.selected.year === this.minYear && this.selected.quarter < this.minQuarter) {
        this.selected.quarter = this.minQuarter;
      }
    }
  }

  /**
   * Коллбек выбора года.
   * @param year год.
   */
  public onSelectYear(year: number): void {
    if (!year) {
      this.clear();
      return;
    }
    if (!this.selected) {
      this.selected = this.defaultSelected;
    }
    this.selected.year = year;
    this.checkAndReplaceMinMax();
    this.emitChanges(this.selected);
  }

  /**
   * Коллбек выбора квартала.
   * @param quarter квартал.
   */
  public onSelectQuarter(quarter: number): void {
    this.openedSelect$.next(false);
    if (!this.selected) {
      this.selected = this.defaultSelected;
    }
    this.selected.quarter = quarter;
    this.checkAndReplaceMinMax();
    this.emitChanges(this.selected);
  }

  /**
   * @inheritdoc
   */
  public arrowClick(direction: 'forward' | 'back') {
    this.openedSelect$.next(false);
    const selected = this.selected ? this.selected : this.defaultSelected;
    if ((direction == 'forward' && selected.quarter == this.maxQuarter && selected.year == this.maxYear) ||
      (direction == 'back' && selected.quarter == this.minQuarter && selected.year == this.minYear)) {
      return;
    }
    let djs = dayjs([selected.year]).quarter(selected.quarter);
    djs = djs.add(
      direction == 'forward' ? 1 : -1,
      'quarter'
    );
    this.selected = {
      year: djs.year(),
      quarter: djs.quarter()
    };
    this.checkAndReplaceMinMax();
    this.emitChanges(this.selected);
  }

  /**
   * @inheritdoc
   */
  writeValue(value: IQuarterYear): void {
    this.selected = cloneDeep(value);
  }
}

import {
    AfterViewInit,
    Component,
    ElementRef,
    Host,
    Inject,
    OnDestroy,
    Optional,
    SkipSelf,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { PopupContainerDirective } from "../../directives/popup-container.directive";
import { PopupContainerService } from "../../services/popup-container.service";

/**
 * Компонент-шаблон попапа.
 * Сам по себе ничего не отображает.
 * Принимает контент и настройки,
 * и передает их в контейнер попапа.
 */
@Component({
    selector: 'app-popup-container-template',
    templateUrl: './popup-container-template.component.html',
    styleUrls: ['./popup-container-template.component.scss'],
    providers: [PopupContainerService]
})
export class PopupContainerTemplate
    extends PopupContainerDirective
    implements AfterViewInit, OnDestroy {

    /**
     * @inheritdoc
     * @readonly
     * @default контент компонента
     */
    @ViewChild(TemplateRef)
    readonly popupContainer: TemplateRef<any>;

    constructor(
        protected popupContainerService: PopupContainerService,
        @Host() protected host: ElementRef,
        @SkipSelf()
        @Inject(PopupContainerTemplate)
        @Optional()
        protected parent?: PopupContainerTemplate
    ) {
        super(popupContainerService, host, parent);
    }
}

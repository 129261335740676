/**
 * Декоратор задержки выполнения функции.
 * @param timeout время в мс
 */
export function ngDebounce(timeout: number) {
    let timeoutRef = null;
    return function (target, propertyKey: string, descriptor: PropertyDescriptor) {
        const original = descriptor.value;
        descriptor.value = function (...args) {
            clearTimeout(timeoutRef);
            timeoutRef = setTimeout(() => {
                original.apply(this, args);
            }, timeout);
        }
        return descriptor;
    }
}
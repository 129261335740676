import { Component, Input, forwardRef, EventEmitter, Output, Host, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { createAutoCorrectedDatePipe } from "text-mask-addons/dist/textMaskAddons";
import * as dayjs from 'dayjs';
import { BaseFieldComponent } from '../../base-components/base-field.component';
import { IBaseCalendarProperties } from '../../base-components/base-calendar.component';
import { ITextMaskConfig } from '@app/core/interfaces/text-mask';
import { DateFormat } from '@app/shared/services/date-time.service';

@Component({
  selector: 'app-time-select-input',
  templateUrl: './time-select-input.component.html',
  styleUrls: ['./time-select-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeSelectInputComponent),
      multi: true
    }
  ]
})
export class TimeSelectInputComponent
  extends BaseFieldComponent
  implements ControlValueAccessor,
  IBaseCalendarProperties<string> {
  /**
   * Стандартное значение.
   */
  private readonly defaultValue: string = '00:00:00';
  /**
   * Стандартное значение одного инпута.
   */
  private readonly inputDefaultValue: string = '00';
  /**
   * Выбранное значение.
   * @default '00:00:00'
   */
  @Input('selected') set _selected(value: string) {
    this.writeValue(value);
  }
  selected: string = this.defaultValue;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withMinutes: boolean = true;
  /**
   * @inheritdoc
   * @default false
   */
  @Input() withSeconds: boolean = false;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withIcon: boolean = true;
  /**
   * @inheritdoc
   */
  @Output() readonly OnSelect: EventEmitter<string> = new EventEmitter<string>();

  public readonly mask = [/\d/, /\d/];
  private readonly maskConfig: ITextMaskConfig = {
    mask: this.mask,
    showMask: false,
    guide: false,
    keepCharPositions: true
  };
  public readonly hourMaskConfig: ITextMaskConfig = { ...this.maskConfig, ...{ pipe: createAutoCorrectedDatePipe('HH') } };
  public readonly minuteMaskConfig: ITextMaskConfig = { ...this.maskConfig, ...{ pipe: createAutoCorrectedDatePipe('MM') } };
  public readonly secondMaskConfig: ITextMaskConfig = { ...this.maskConfig, ...{ pipe: createAutoCorrectedDatePipe('SS') } };

  /**
   * Текущее значение часов.
   */
  public hour: string = this.inputDefaultValue;
  /**
   * Текущее значение минут.
   */
  public minute: string = this.inputDefaultValue;
  /**
   * Текущее значение секунд.
   */
  public second: string = this.inputDefaultValue;
  public get format(): DateFormat {
    return this.withSeconds
      ? DateFormat.TIME_WITH_SECONDS
      : DateFormat.TIME;
  }

  constructor(
    @Host() private host: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  /**
   * Изменение значений с помощью стрелок на клавиатуре.
   * @param unit единица измерения.
   * @param operation операция.
   */
  public changeByArrows(
    unit: 'hours' | 'minutes' | 'seconds',
    operation: 'add' | 'substract'
  ) {
    const djs = dayjs(this.selected, this.format);
    this.selected = (operation == 'add'
      ? djs.add(1, unit)
      : djs.subtract(1, unit))
      .format(this.format);
    this.setValues(this.selected);
    this.emitChanges(this.selected);
  }

  /**
   * Метод изменения значений часов, минут, секунд из общей строки.
   */
  private setValues(value: string) {
    const time = value ? value.split(':') : [];
    this.hour = !!time.length ? time[0] : this.inputDefaultValue;
    this.minute = !!time.length ? time[1] : this.inputDefaultValue;
    this.second = !!time.length ? time[2] : this.inputDefaultValue;
    requestAnimationFrame(() => {
      this.cdr.markForCheck();
    });
  }


  /**
   * Объединить значения в одну строку.
   * @param hours Часы.
   * @param minutes Минуты.
   * @param seconds Секунды.
   */
  private prepareTimeString(hours: string, minutes: string, seconds: string, format: DateFormat): string {
    let res: string = `${this.prepareTimeStringValue(hours)}:${this.prepareTimeStringValue(minutes)}`;
    if (format === DateFormat.TIME_WITH_SECONDS) {
      res += `:${this.prepareTimeStringValue(seconds)}`;
    }
    return res;
  }

  /**
   * Подготовить значение инпута,
   * добавить 0 если нужно.
   * @param value значение.
   */
  private prepareTimeStringValue(value: string): string {
    return !!value
      ? value.length === 1
        ? '0' + value
        : value
      : this.inputDefaultValue;
  }

  public onHoursBlur() {
    this.hour = this.prepareInputValue(this.hour, 0, 23);
    this.hour = this.prepareTimeStringValue(this.hour);
  }

  public onMinutesBlur() {
    this.minute = this.prepareInputValue(this.minute, 0, 59);
    this.minute = this.prepareTimeStringValue(this.minute);
  }

  public onSecondsBlur() {
    this.second = this.prepareInputValue(this.second, 0, 59);
    this.second = this.prepareTimeStringValue(this.second);
  }

  /**
   * Коллбек общего блюра.
   */
  public onFocusOut(event: FocusEvent) {
    // Игнорируем событие при перемещении
    // курсора между датой/временем.
    if (this.host.nativeElement.contains(event.relatedTarget)) {
      return;
    }
    const timeStr = this.prepareTimeString(this.hour, this.minute, this.second, this.format);
    this.emitChanges(timeStr);
  }

  /**
   * Подготовить значение инпута,
   * учитывая ограничения единиц измерения.
   * @param value значение.
   * @param min минимальное значение.
   * @param max максимальное значение.
   */
  private prepareInputValue(value: string, min: number, max: number): string {
    if (!value || Number(value) < min) {
      return this.inputDefaultValue;
    }
    if (Number(value) > max) {
      return max.toString();
    }
    return value;
  }

  private emitChanges(value: string) {
    if (this.onChange) {
      this.onChange(value);
    }
    this.OnSelect.emit(value);
  }

  writeValue(value: string): void {
    this.selected = value ? value : this.defaultValue;
    this.setValues(this.selected);
  }
}

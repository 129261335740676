import { Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import { IBaseCalendarProperties } from '../../base-components/base-calendar.component';
import { SelectFieldComponent } from '../../select-field/select-field.component';
import { CalendarWithApplySupportComponent } from '../calendar-with-apply/calendar-with-apply-support.component';

@Component({
  selector: 'app-year-select',
  templateUrl: './year-select.component.html',
  styleUrls: ['./year-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YearSelectComponent),
      multi: true
    },
    {
      provide: CalendarWithApplySupportComponent,
      useExisting: forwardRef(() => YearSelectComponent)
    }
  ]
})
export class YearSelectComponent
  extends CalendarWithApplySupportComponent<number>
  implements OnChanges,
  ControlValueAccessor,
  IBaseCalendarProperties<number> {

  public readonly currentYear = this.currentMoment.year();

  /**
   * Плейсхолдер
   * @default DATE.YEAR
   */
  @Input() placeholder: string = this.translateService.instant('DATE.YEAR');
  /**
   * @inheritdoc
   * @default false
   */
  @Input() forCalendar: boolean = false;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withHover: boolean = true;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withArrows: boolean = true;
  /**
   * Список значений
   */
  public list: number[] = [];

  @ViewChild(SelectFieldComponent, { read: ElementRef }) selectFieldComponent: ElementRef;

  constructor(
    protected translateService: TranslateService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.minYear || changes.maxYear) {
      this.list = [];
      for (let year = this.minYear; year <= this.maxYear; year++) {
        this.list.push(year);
      }
    }
  }

  /**
   * Функция выбора значения
   * @param year
   */
  public onSelectYear(value: number): void {
    this.openedSelect$.next(false);
    this.selected = value;
    this.emitChanges(this.selected);
  }

  /**
   * @inheritdoc
   */
  public arrowClick(direction: 'forward' | 'back') {
    this.openedSelect$.next(false);
    if (!this.selected) {
      this.selected = this.currentYear;
    }
    direction == 'forward'
      ? this.selected++
      : this.selected--;
    this.selected = Math.max(this.selected, this.minYear);
    this.selected = Math.min(this.selected, this.maxYear);
    this.emitChanges(this.selected);
  }

  /**
   * @inheritdoc
   */
  writeValue(year: number): void {
    this.selected = year;
  }
}

import { IAdminNsiDictionary } from "@core/interfaces/admin-nsi-dictionaries";

export const passwordPatterns = {
  // глобал флаг обязателен для regexp,
  // используемых при подсчете минимального
  // количества символов
  AZ: /[A-Z]+/g,
  az: /[a-z]+/g,
  num: /[0-9]+/g,
  spec: /[@#$\-_=;:<>`~!"№%^?&*()+\\|/.,'[\]{}]+/g,
  rus: /[^\sА-Яа-яЁё]/
}

export const password_pattern = /[^\sА-Яа-яЁё]/;
export const login_pattern = /[A-Za-z0-9]/;
export const name_pattern = /[А-Яа-яЁё -]/;
export const number_pattern = /[0-9]/;
export const code_pattern = /[A-Z]/;
export const float_pattern = /[0-9.]/;
export const date_full_ISO_pattern = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d[.:][0-5]\d|Z)$/;
export const date_ISO_pattern = /^\d{4}-[01]\d-[0-3]\d$/;
export const ip4regexp: RegExp = /^(?!\.)((^|\.)([1-9]?\d|1\d\d|2(5[0-5]|[0-4]\d))){4}$/;
export const ip6regexp: RegExp = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

export enum SYSTEM_PARAMETER_NAME {
  PASSWORD_RECOVERY_LINK_LIFETIME = 'PASSWORD_RECOVERY_LINK_LIFETIME',
  SUPPORT_PHONE = 'SUPPORT_PHONE',
  SUPPORT_EMAIL = 'SUPPORT_EMAIL',
  DEVELOPER_PHONE = 'DEVELOPER_PHONE',
  DEVELOPER_EMAIL = 'DEVELOPER_EMAIL',
  PHONE_LENGTH = 'PHONE_LENGTH',
  PRODUCT_FULL_NAME = 'PRODUCT_FULL_NAME',
  PRODUCT_SHORT_NAME = 'PRODUCT_SHORT_NAME',
  LOG_LIFETIME = 'LOG_LIFETIME',
  PASSWORD_MAX_LIFETIME = 'PASSWORD_MAX_LIFETIME',
  PASSWORD_MIN_LIFETIME = 'PASSWORD_MIN_LIFETIME',
  PASSWORD_MIN_NUM_CHANGING_CHARACTERS = 'PASSWORD_MIN_NUM_CHANGING_CHARACTERS',
  PASS_COMPLEXITY_LENGTH = 'PASS_COMPLEXITY_LENGTH',
  PASS_COMPLEXITY_LOWERCASES = 'PASS_COMPLEXITY_LOWERCASES',
  PASS_COMPLEXITY_NUMBERS = 'PASS_COMPLEXITY_NUMBERS',
  PASS_COMPLEXITY_SPECIAL_CHARACTERS = 'PASS_COMPLEXITY_SPECIAL_CHARACTERS',
  PASS_COMPLEXITY_UPPERCASES = 'PASS_COMPLEXITY_UPPERCASES',
  LOGIN_TRY_COUNT = 'LOGIN_TRY_COUNT'
}

export enum SUBSYSTEM {
  ASUER = 'ASUER',
  GKH = 'GKH'
}

export const MENU = [
  {
    code: "m_User",
    name: "Пользователи",
    sortOrder: 1
  },
  {
    code: "m_System",
    name: "Системные параметры",
    sortOrder: 2
  },
  {
    code: "m_EventLog",
    name: "Мониторинг",
    sortOrder: 3
  },
  {
    code: "m_NSI",
    name: "НСИ",
    sortOrder: 4
  }
];

export enum NSI_DICTIONARY_CODE {
  SUBSYSTEMS = "SUBSYSTEMS",
  KAFKA_BROKER_VERSIONS = "KAFKA_BROKER_VERSION",
  LEGAL_ENTITY = "LEGAL_ENTITY",
  DEPARTMENT = "DEPARTMENT",
  JOB_POSITION = "JOB_POSITION"
}

export const DICTIONARIES: IAdminNsiDictionary[] = [
  {
    code: NSI_DICTIONARY_CODE.SUBSYSTEMS,
    name: "Подсистемы",
    readonly: false
  },
  {
    code: NSI_DICTIONARY_CODE.KAFKA_BROKER_VERSIONS,
    name: "Версии протокола брокера kafka",
    readonly: true
  },
  {
    code: NSI_DICTIONARY_CODE.LEGAL_ENTITY,
    name: "Юридическое лицо",
    readonly: false
  },
  {
    code: NSI_DICTIONARY_CODE.DEPARTMENT,
    name: "Подразделение",
    readonly: false
  },
  {
    code: NSI_DICTIONARY_CODE.JOB_POSITION,
    name: "Должность",
    readonly: false
  },
];

export enum GRID_ID {
  "ADMIN.USERS" = "auth-admin.users",
  "ADMIN.USERS.IP_ADDRESS" = "auth-admin.users.ip_address",
  "ADMIN.SUBSYSTEMS" = "auth-admin.subsystems",
  "ADMIN.KAFKA_BROKER_VERSIONS" = "auth-admin.kafka-broker-versions",
  "ADMIN.LEGAL_ENTITY" = "auth-admin.legal_entity",
  "ADMIN.DEPARTMENT" = "auth-admin.department",
  "ADMIN.JOB_POSITION" = "auth-admin.job_position",
  "ADMIN.SYSTEM_PARAMETERS" = "auth-admin.system_parameters",
  "EVENT_LOG" = "auth-admin.event_log"
}

export enum EventLogReportCode {
  IP_ACTIONS = "IP_ACTIONS", // Отчет действий с выбранного IP
  USER_AUTH_ATTEMPTS = "USER_AUTH_ATTEMPTS", // Отчет попыток аутентификации пользователя
  USER_ACTIONS = "USER_ACTIONS", // Отчет действий пользователя
  SECURITY_ACTIONS = "SECURITY_ACTIONS", // Отчет по событиям безопасности
  USER_LIST = "USER_LIST", // Список пользователей
  ORG_AUTH_ATTEMPTS = "ORG_AUTH_ATTEMPTS" // Отчет попыток аутентификации пользователей организации
}

export enum Criteria {
  equals = "equals",
  EQUALS_IGNORE_CASE = "EQUALS_IGNORE_CASE",
  EQUALS_OR_NULL = "EQUALS_OR_NULL",
  notEquals = "notEquals",
  greaterThan = "greaterThan",
  lessThan = "lessThan",
  greaterOrEquals = "greaterOrEquals",
  lessOrEquals = "lessOrEquals",
  between = "between",
  in = "in",
  notIn = "notIn",
  like = "like",
  notLike = "notLike",
  startFrom = "startFrom",
  notStartFrom = "notStartFrom",
  endTo = "endTo",
  notEndTo = "notEndTo",
  isNull = "isNull",
  isNotNull = "isNotNull",
  inOrNull = "inOrNull"
}

// типы фильтров
export enum FilterType {
  text = "text",
  number = 'number',
  date = "date",
  time = "time",
  dateRange = "dateRange",
  select = "select",
  multiSelect = "multiSelect",
  simpleDictionary = 'simpleDictionary',
  multiDictionary = 'multiDictionary',
  checkbox = 'checkbox'
}

export enum ColType {
  VARCHAR = 'VARCHAR',
  BIGINT = 'BIGINT',
  INTEGER = 'INTEGER',
  TIMESTAMP = 'TIMESTAMP',
  BOOL = 'BOOL',
  NUMBER = 'NUMBER'
}

export enum ColFilterLocation {
  MAIN = 'main',
  EXT = 'ext'
}

export enum BroadcastChannelKeys {
  AUTH = 'auth-broadcast-channel',
}

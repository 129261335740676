import { Component } from '@angular/core';
import { ModalRedirectCloseService } from '@app/shared/services/modal-redirect-close.service';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
  providers: [ModalRedirectCloseService]
})
export class ChangePasswordModalComponent {

  constructor(
    public activeModal: NgbActiveModal,
    private modalRedirectCloseService: ModalRedirectCloseService
  ) {}

  public onCancel () {
    this.activeModal.close();
  }
}

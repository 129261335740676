import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LockEditableComponent } from './lock-editable.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        LockEditableComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        TranslateModule
    ],
    exports: [
        LockEditableComponent
    ]
})
export class LockEditableModule { }

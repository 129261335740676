import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {share} from "rxjs/operators";
import {ISystemParam} from "@core/interfaces/system-params";
import {IGridRequestPayload, IPagedResponse} from "@shared/components/base/ag-base-grid/ag-base-grid.interface";
import {IAdminSystemParamUpdateParams} from "@core/interfaces/system-params";

@Injectable({
  providedIn: 'root'
})
export class SystemParamsApiService {

  constructor(
    private HttpClient: HttpClient
  ) {}

  public getPublicSystemParams(): Observable<ISystemParam[]> {
    return this.HttpClient.get<ISystemParam[]>(
      `${environment.API_URL}/public/parameters`
    ).pipe(share());
  }

  public getSystemParamsGrid(requestPayload: IGridRequestPayload): Observable<IPagedResponse> {
    return this.HttpClient.post<IPagedResponse>(
      `${environment.API_URL}/system/parameter/grid`,
      requestPayload
    ).pipe(share());
  }

  public updateSystemParameter(params: IAdminSystemParamUpdateParams) {
    return this.HttpClient.put(`${environment.API_URL}/system/parameter/update`, params).pipe(share());
  }
}

import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  forwardRef,
  ElementRef,
  ViewChild,
  Host,
  SimpleChanges
} from '@angular/core';
import { createAutoCorrectedDatePipe } from "text-mask-addons/dist/textMaskAddons";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';
import { BaseFieldComponent } from '../../base-components/base-field.component';
import { IBaseCalendarProperties } from '../../base-components/base-calendar.component';
import { DateFormat, DateTimeService } from '@app/shared/services/date-time.service';
import { ITextMaskConfig } from '@app/core/interfaces/text-mask';
import { ngDebounce } from '@app/shared/decorators/ng-debounce.decorator';

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeInputComponent),
      multi: true
    }
  ]
})
export class DateTimeInputComponent
  extends BaseFieldComponent
  implements OnChanges,
  ControlValueAccessor,
  IBaseCalendarProperties<string> {

  private readonly dateFormat = DateFormat.DATE;
  public readonly timeWithSecondFormat = DateFormat.TIME_WITH_SECONDS;

  /**
   * Переменная даты.
   */
  public selectedDate: string = null;
  /**
   * Переменная времени.
   */
  public selectedTime: string = null;
  /**
   * Флаг фокуса на компоненте
   * (инпут даты или времени).
   */
  public readonly isFocus$ = new BehaviorSubject<boolean>(false);

  /**
   * Текущий формат времени.
   * @default null
   */
  public selectedTimeFormat: string = null;
  /**
   * Текущая маска времени.
   * @default null
   */
  private timeMask = null;
  /**
   * Флаг использования даты.
   * @default true
   */
  public withDate: boolean = true;
  /**
   * Конфиг text-mask для даты.
   */
  public readonly dateInputConfig: ITextMaskConfig = {
    mask: this.dateTimeService.getDateMask(),
    showMask: true,
    guide: true,
    keepCharPositions: true,
    pipe: createAutoCorrectedDatePipe('dd.mm.yyyy')
  };
  /**
   * Конфиг text-mask для времени.
   */
  public timeInputConfig: ITextMaskConfig = {
    mask: this.timeMask,
    showMask: true,
    guide: true,
    keepCharPositions: true
  };
  /**
   * Выбранное значение.
   */
  @Input('selected') set _selected(value: string) {
    this.writeValue(value);
  }
  selected: string = null;
  /**
   * @inheritdoc
   * @default DateFormat.DATE
   */
  @Input() format: DateFormat = this.dateFormat;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withRemove: boolean = true;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withIcon: boolean = true;
  /**
   * Флаг наличия ошибки.
   */
  @Input() hasError: boolean = false;
  /**
   * Текст ошибки.
   */
  @Input() errorText: string = null;
  /**
   * Тултип блокировки.
   */
  @Input() disabledTooltip: string[] = null;
  /**
   * Положение тултипа.
   * @default 'top'
   */
  @Input() tooltipPlacement: string = 'top';
  /**
   * @inheritdoc
   */
  @Output() readonly OnSelect: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('dateInput') public readonly dateInputRef: ElementRef;
  @ViewChild('timeInput') public readonly timeInputRef: ElementRef;

  constructor(
    private dateTimeService: DateTimeService,
    @Host() private host: ElementRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.format) {
      this.prepareDateTimeMasks();
      this.setValues(this.selected);
    }
  }

  private prepareDateTimeMasks() {
    this.withDate = this.format.includes(this.dateFormat);
    const timeFormat: string = this.format !== this.dateFormat
      ? !!this.format.split(' ')[1]
        ? this.format.split(' ')[1]
        : this.format
      : null;
    this.selectedTimeFormat = timeFormat ? timeFormat : null;
    this.timeMask = timeFormat
      ? this.dateTimeService.getTimeMask(timeFormat)
      : null;
    this.timeInputConfig = {
      ...this.timeInputConfig,
      mask: this.timeMask,
      pipe: !!timeFormat
        ? createAutoCorrectedDatePipe(timeFormat.toUpperCase())
        : null
    }
  }

  /**
   * Метод изменения значений даты и времени из общей строки.
   * @param value значение
   */
  private setValues(value: string) {
    this.selectedDate = value && this.withDate
      ? this.dateTimeService.getDateStringFromString(value, this.format, this.dateFormat)
      : null;
    this.selectedTime = value && this.selectedTimeFormat
      ? this.dateTimeService.getTimeStringFromString(value, this.format, this.selectedTimeFormat)
      : null;
  }

  /**
   * Коллбэк на событие фокуса инпутов.
   * @param target инпут.
   */
  @ngDebounce(67)
  public onFocus(event: FocusEvent) {
    if (!this.isFocus$.getValue()) {
      this.OnFocus.emit(event);
    }
    this.isFocus$.next(true);
    this.setCaretPosition(event.target as HTMLInputElement);
  }

  /**
   * Метод установки курсора текста в положение,
   * где отсутствует введеное значение.
   * @param target инпут.
   */
  private setCaretPosition(target: HTMLInputElement): void {
    const value = target.value;
    if (!value || value.includes('_')) {
      const index = value && value.indexOf('_') ? value.indexOf('_') : 0;
      target.setSelectionRange(index, index);
    }
  };

  /**
   * Метод проверки, дополнения и сохранения значения
   * после события блюра.
   */
  private afterFocusOut() {
    const underlineRegexp = /_/g;
    if (this.selectedDate && this.withDate && this.selectedDate.includes('_')) {
      if (this.selectedDate === '__.__.____') {
        this.selectedDate = null;
        this.selectedTime = null;
        return;
      }
      else {
        this.selectedDate = this.selectedDate
          .split('.')
          .map((item: string, index: number) => {
            const countConcat: number = item.match(underlineRegexp) ? item.match(underlineRegexp).length : 0;
            if (countConcat === item.length) {
              if (index === 0) {
                item = dayjs().day().toString();
                if (item.length === 1) {
                  item = '0' + item;
                }
              }
              else if (index === 1) {
                item = (dayjs().month() + 1).toString();
                if (item.length === 1) {
                  item = '0' + item;
                }
              }
              else {
                item = dayjs().year().toString();
              }
            }
            else {
              item = item.replace(underlineRegexp, '0');
            }
            return item;
          })
          .join('.');
      }
    }
    if (this.selectedTimeFormat) {
      if (!!this.selectedDate && !this.selectedTime) {
        this.selectedTime = dayjs().startOf('day').format(this.selectedTimeFormat);
      }
      this.selectedTime = this.selectedTime && this.selectedTime.replace(underlineRegexp, '0') ? this.selectedTime.replace(underlineRegexp, '0') : null;
    }

    const value = this.combineValues();
    if (this.selected !== value) {
      this.selected = value;
      this.emitChanges(value);
    }
  }

  /**
   * Объединяет значения инпутов.
   * @returns Целую строку в выбранном формате,
   * или null если значение не полное.
   */
  private combineValues(): string {
    if ((!this.selectedDate && this.withDate) ||
      (!this.selectedTime && !!this.selectedTimeFormat)) {
      return null;
    }
    let value: string = this.withDate
      ? this.selectedDate
      : null;
    if (this.selectedTimeFormat) {
      value = !!value
        ? `${value} ${this.selectedTime}`
        : this.selectedTime;
    }
    return value;
  }

  public clearSelect() {
    this.writeValue(null);
    this.emitChanges(null);
  }

  /**
   * Коллбек на событие изменения инпута даты или времени.
   * @param value Значение.
   * @param subject Поле.
   */
  public onValueChange(value: string, subject: 'date' | 'time') {
    let target: HTMLInputElement;
    let next: HTMLInputElement;
    if (subject == 'date') {
      target = this.dateInputRef.nativeElement;
      next = this.timeInputRef && this.timeInputRef.nativeElement;
    }
    else {
      target = this.timeInputRef.nativeElement;
      next = this.dateInputRef && this.dateInputRef.nativeElement;
    }
    // Проверка полного ввода в инпут
    if (value && !value.includes('_')) {
      // Перемещаем фокус только если курсор в конце инпута и соседнее поле не заполнено
      // (чтобы избежать случаев переноса при редактировании уже заполненного значения).
      if (target.selectionStart == value.length &&
        next && next.value.includes('_')) {
        next.focus();
      }
      // Вызываем сохранение, если значение заполнено целиком.
      if (!next || !next.value || !next.value.includes('_')) {
        this.selected = this.combineValues();
        this.emitChanges(this.selected);
      }
    }
  }

  /**
   * Коллбэк на событие снятия фокуса с элемента.
   * @param event событие.
   */
  public onFocusOut(event: FocusEvent) {
    // Игнорируем событие при перемещении
    // курсора между датой/временем.
    if (this.host.nativeElement.contains(event.relatedTarget)) {
      return;
    }
    this.OnBlur.emit(event);
    this.isFocus$.next(false);
    this.afterFocusOut();
  }

  /**
   * Метод сохранения значений.
   * Вызывает forms api {@link onChange} (при наличии),
   * и {@link OnSelect}.
   * @param value
   */
  private emitChanges(value: string) {
    if (this.onChange) {
      this.onChange(value);
    }
    this.OnSelect.emit(value);
  }

  writeValue(value: string): void {
    this.selected = value;
    this.setValues(value);
  }
}

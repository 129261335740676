import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-filter-panel-toggle-btn',
  templateUrl: './filter-panel-toggle-btn.component.html',
  styleUrls: ['./filter-panel-toggle-btn.component.scss']
})
export class FilterPanelToggleBtnComponent implements OnInit {

  @Input() openedFilter: boolean = false; // флаг открытой/закрытой панели фильтров
  @Input() hasFilters: boolean = false; // флаг наличия выбранных фильтров
  @Output() OnToggle: EventEmitter<boolean> = new EventEmitter<boolean>(); // событие открытия/закрытия панели фильтров

  constructor() { }

  ngOnInit() {
  }

  public toggleFilter() {
    this.openedFilter = !this.openedFilter;
    this.OnToggle.emit(this.openedFilter);
  }

}

export class EnvService {

  // The values that are defined here are the default values that can
  // be overridden by env.js

  // главная иконка
  public mainIcon = '/assets/images/gerb_minobr.png';

  // текст под главной иконкой
  public mainIconText = '/assets/images/minobr.png';

  // favicon
  public favicon = 'favicon.ico';

  constructor() {
  }

}

import { Injectable, Injector, Type } from "@angular/core";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ContextMenuService } from "ngx-contextmenu";
import { from, fromEvent, of } from "rxjs";
import { catchError, filter, takeUntil, throttleTime } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OpenModalService {

  constructor(
    private modalService: NgbModal,
    private injector: Injector,
    private contextMenuService: ContextMenuService
  ) { }

  /**
   * Новая реализация отображения модального окна.
   * @param params Параметры.
   */
  public show<T extends Object>(params: ModalParams<T>): NgbModalRef;

  /**
   * Старая реализация отображения модального окна.
   * @param component Компонент окна.
   * @param config Входные параметры для компонента.
   * @param closeFunctionName Название функции перед закрытием модального окна - для выполнения при закрытии по фону.
   * @param options Конфигурация модального окна.
   * @deprecated Следует использовать новую реализацию функции с одним параметром-конфигом
   */
  public show(component: Type<any>, config?: any, closeFunctionName?: string, options?: NgbModalOptions): NgbModalRef;

  public show(cmpOrParams: any, config?: any, closeFunctionName?: string, options?: NgbModalOptions): NgbModalRef {
    this.closeAllContextMenu();
    return !config && cmpOrParams && cmpOrParams.component
    && cmpOrParams.component.hasOwnProperty('prototype')
      ? this.newShow(cmpOrParams)
      : this.oldShow(cmpOrParams, config, closeFunctionName, options);
  }

  private oldShow(component: Type<any>, config?: any, closeFunctionName?: string, options?: NgbModalOptions): NgbModalRef {
    options.injector = options.injector ? options.injector : this.injector;
    const modalRef = this.modalService.open(component, options);
    if (config) {
      for (const key in config) {
        if (config.hasOwnProperty(key)) {
          modalRef.componentInstance[key] = config[key];
        }
      }
      modalRef.result.catch(() => {
        if (closeFunctionName && config[closeFunctionName]) {
          config[closeFunctionName]();
        }
      })
    }
    return modalRef;
  }

  private newShow<T extends Object>(params: ModalParams<T>): NgbModalRef {
    if (!!params.options) {
      params.options.injector = params.options.injector ? params.options.injector : this.injector;
    }

    const modalRef = this.modalService.open(params.component, params.options);
    Object.assign(modalRef.componentInstance, params.data);

    const rootView: HTMLElement = (modalRef as any)._windowCmptRef
    && (modalRef as any)._windowCmptRef.location
    && (modalRef as any)._windowCmptRef.location.nativeElement
      ? (modalRef as any)._windowCmptRef.location.nativeElement
      : null;

    if (!!rootView) {
      requestAnimationFrame(() => {
        // Фокус на открытое модальное окно,
        // если оно (или дочерний элемент) не в фокусе.
        if (!rootView.contains(document.activeElement)) {
          rootView.focus();
        }
      });

      if (!!params.callbacks) {
        const destroy$ = from(modalRef.result).pipe(catchError(() => of({})));

        fromEvent(document.body, 'keydown')
          .pipe(
            filter(e => e instanceof KeyboardEvent &&
              // проверка, что евент поступил от нужного окна
              (rootView === e.target ||
                rootView.contains(e.target as Element)) &&
              // проверка, что есть коллбэк на эту кнопку
              !!params.callbacks[e.key]
            ),
            throttleTime(100),
            takeUntil(destroy$)
          )
          .subscribe((e: KeyboardEvent) => {
            modalRef.componentInstance[params.callbacks[e.key]]();
          });
      }
    }

    return modalRef;
  }

  private closeAllContextMenu(): void {
    // IE fix
    if (this.contextMenuService && this.contextMenuService.closeAllContextMenus) {
      try {
        this.contextMenuService.closeAllContextMenus({
          eventType: 'cancel',
          event: new KeyboardEvent('keydown', { code: 'ESCAPE' }),
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
}

/**
 * Параметры для модального окна.
 */
export type ModalParams<T extends Object> = {
  /**
   * Класс компонента.
   */
  component: Type<T>;
  /**
   * Настройки для NgbModal.
   */
  options?: NgbModalOptions;
  /**
   * Данные для компонента.
   */
  data?: {
    [Property in keyof T]?: T[Property];
  },
  /**
   * Наименования методов компонента,
   * которые следует вызывать при нажатии Enter/Escape.
   * Например, вызывать метод компонента onCancel,
   * в котором есть подтверждение отмены,
   * при нажатии Escape.
   */
  callbacks?: {
    Enter?: keyof T;
    Escape?: keyof T;
  }
}

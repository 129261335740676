import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Location} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {catchError, map} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {PasswordApiService} from "@core/services/api/password-api.service";

@Injectable()
export class RestoreAccessGuardService implements CanActivate {
  constructor(
    private router: Router,
    private location: Location,
    private toastr: ToastrService,
    private passwordApiService: PasswordApiService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const secretCode: string = route.paramMap.get('key');
    return this.checkSecretCode(secretCode);
  }

  private checkSecretCode(secretCode: string) {
    if (secretCode) {
      return this.passwordApiService.checkSecretCode(secretCode).pipe(
        map((result) => {
          return true;
        }),
        catchError((error) => {
          this.router.navigate(['/404']);
          if (error.error && error.error['message']) {
            this.toastr.error(error.error['message']);
          }
          return of(false);
        })
      );
    } else {
      this.router.navigate(['/404']);
      return of(false);
    }
  }
}

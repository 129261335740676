import { HttpErrorResponse } from '@angular/common/http';
import { ITokenResponse } from './../auth/oauth2.service';
import {BehaviorSubject, EMPTY, throwError} from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, catchError, filter, take, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  private isRefreshing: boolean = false;
  private token$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isAuthorized() && !req.headers.get('Authorization') && !req.url.includes('/oauth/token')) {
      req = this.addToken(req, this.authService.getToken());
    }
    return next.handle(req).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && req && !req.url.includes('/oauth/token')) {
        return this.handleUnauthorizedError(req, next);
      } else if (error instanceof HttpErrorResponse && error.status === 502) {
        this.router.navigate(['/page-wrong']);
        return EMPTY;
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`,
        'Content-type': req.headers.get('Content-Type')
          ? req.headers.get('Content-Type')
          : 'application/json; charset=utf-8'
      }
    });
  }

  private handleUnauthorizedError(req: HttpRequest<any>, next: HttpHandler) {
    // обновляем токен и возвращаем запрос с новым токеном
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.token$.next(null);
      return this.authService.refreshToken().pipe(
        finalize(() => this.isRefreshing = false),
        catchError(error => {
          this.router.navigate(['/auth']);
          return throwError(error);
        }),
        switchMap((res: ITokenResponse) => {
          this.token$.next(res.access_token);
          return next.handle(this.addToken(req, res.access_token));
        })
      );
    }
    // если запрос пришел во время обновления токена
    // ждем обновления и возвращаем запрос с новым токеном
    else {
      return this.token$.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => next.handle(this.addToken(req, token)))
      )
    }
  }
}

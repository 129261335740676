import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PopupContainerTemplate } from "./components/popup-container-template/popup-container-template.component";
import { PopupContainerComponent } from "./components/popup-container/popup-container.component";
import { PopupContainerDirective } from "./directives/popup-container.directive";

@NgModule({
  declarations: [
    PopupContainerDirective,
    PopupContainerComponent,
    PopupContainerTemplate,
  ],
  imports: [CommonModule],
  exports: [
    PopupContainerDirective,
    PopupContainerComponent,
    PopupContainerTemplate,
  ],
  entryComponents: [PopupContainerComponent],
})
export class PopupContainerModule {}

import { fromEvent, ReplaySubject, Subscription } from 'rxjs';
import { Component, EventEmitter, Input, Output, OnDestroy, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements AfterViewInit, OnInit, OnDestroy {

  @Input() value: string = null; //значение
  @Input() searchId: string = 'search'; //ид поля
  @Input() placeholder: string = 'Поиск'; // плейсхолдер
  @Input() searchWithDebounce: boolean = true; // поиск с зажержкой после ввода
  @Input() debounce: number = 3000; // время задержки в миллисекундах
  @Input() withTransparentBackground: boolean = false; // для отображения с прозрачным фоном
  @Input() errorText: string = null;
  @Input() validFunction: Function = null;
  @Input() patternInput: RegExp = null;
  @Output() OnSearch: EventEmitter<string> = new EventEmitter<string>(); //применение значения по кнопке Поиск, по Enter, при очистке

  @ViewChild('inputField') private inputField: ElementRef;
  private onInput$: Subscription = new Subscription();

  public hasError: boolean = false;

  private destroy$ = new ReplaySubject<boolean>(1);

  ngAfterViewInit(): void {
    if (this.searchWithDebounce) {
      this.onInput$ = fromEvent(this.inputField.nativeElement, 'input')
        .pipe(
          debounceTime(this.debounce),
          map((event: any) => <string>event.target.value)
        )
        .subscribe(
          (res) => {
            if (res == '') {
              res = null;
            }
            if (this.value !== res) {
              this.value = res;
              if (this.validFunction) {
                this.hasError = this.validFunction(this.value);
              }
              if (!this.hasError) {
                this.OnSearch.emit(this.value);
              }
            }
          }
        );
    }
  }

  ngOnInit(): void {
    if (this.patternInput) {
      fromEvent<any>(this.inputField.nativeElement, 'input')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          const currentValue = this.value;

          if (!currentValue) {
            return;
          }

          let value = '';
          for (const char of currentValue) {
            if (char.match(this.patternInput)) {
              value += char;
            }
          }
          this.value = value;
        });
    }
  }

  ngOnDestroy(): void {
    this.onInput$.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Ручной поиск по нажатию Enter / клику на иконку
   */
  public search($event) {
    if($event) {
      $event.stopPropagation();
    }
    let res = this.inputField.nativeElement.value;
    this.value = res && res != '' ? res : null;
    if (this.validFunction) {
      this.hasError = this.validFunction(this.value);
    }
    if (!this.hasError) {
      this.OnSearch.emit(this.value);
    }
  }

  public clear() {
    this.value = null;
    this.OnSearch.emit(null);
  }

  public focus() {
    if (this.inputField && this.inputField.nativeElement) {
      this.inputField.nativeElement.focus();
    }
  }
}

import { Component, Input, Output } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {IMultiSelectItem, ISelectItem} from "@core/interfaces/select-item";
import {DictionaryService} from "@shared/services/dictionary.service";
import {IExtendFilter, ISelectExtendFilter} from "@shared/components/base/ag-base-grid/ag-base-grid.interface";
import {IDictionaryField, IDictionaryParams} from "@core/interfaces/dictionary";
import {Criteria, FilterType} from "@app/app.enums";
import { ModalRedirectCloseService } from '@app/shared/services/modal-redirect-close.service';

@Component({
  selector: 'app-extend-filter-panel',
  templateUrl: './extend-filter-panel.component.html',
  styleUrls: ['./extend-filter-panel.component.scss'],
  providers: [ModalRedirectCloseService]
})
export class ExtendFilterPanelComponent {

  @Input() set selectedFilters(value: ISelectExtendFilter[]) {
    this.selectedExtendFilters = [...value ? value : [], ...[]];
    if (this.extendFilters.length > 0) {
      this.prepareSelectedFilters();
    }
  };
  @Input() set filters(value: IExtendFilter[]) {
    this.filterArray = this.prepareFilterList(value ? value : []);
    this.extendFilters = this.prepareExtendFilters(value ? value : []);
    if (this.selectedExtendFilters.length > 0) {
      this.prepareSelectedFilters();
    }
  };
  @Output() changeFilters: Function; //изменение фильтров
  @Output() changeApplyingAll: Function; //изменение флага Учитывать каждый заданный параметр. Связывание фильтров: true - AND, false - OR

  public selectedExtendFilters: ISelectExtendFilter[] = [];
  public extendFilters: IExtendFilter[] = [];
  public filterArray: ISelectItem[] = [];
  public selectedFilter: string | number = null;
  private criteriaName = null;
  public filterType = FilterType;
  public criteria = Criteria;

  constructor(
    private translateService: TranslateService,
    private activeModal: NgbActiveModal,
    private dictionaryService: DictionaryService,
    private modalRedirectCloseService: ModalRedirectCloseService
  ) {
    this.translateService.get(['CRITERIA']).subscribe(result => {
      this.criteriaName = result['CRITERIA'];
    });
  }

  private prepareSelectedFilters() {
    this.selectedExtendFilters = this.selectedExtendFilters.map((filter: ISelectExtendFilter) => {
      const extFilter = this.extendFilters.find((item: IExtendFilter) => item.field === filter.field);
      filter.listField = extFilter ? extFilter.listField : null;
      return filter;
    });
  }

  private prepareFilterList(extendFilters: IExtendFilter[]): ISelectItem[] {
    let result = [];
    if (extendFilters && extendFilters.length > 0) {
      result = extendFilters.map((filter: IExtendFilter) => {
        return {
          key: filter.field,
          value: filter.name
        }
      });
    }
    return result;
  }

  private prepareExtendFilters(extendFilters: IExtendFilter[]): IExtendFilter[] {
    let result = [];
    if (extendFilters && extendFilters.length > 0) {
      result = extendFilters.map((filter: IExtendFilter) => {
        filter.criteriaList = this.getCriteriaList(filter.criterias);
        return filter;
      });
    }
    return result;
  }

  private getCriteriaList(criterias: Criteria[]): ISelectItem[] {
    const result: ISelectItem[] = [];
    criterias.forEach((criteria: Criteria) => {
      result.push({
        key: criteria,
        value: this.criteriaName[criteria]
      });
    });
    return result;
  }


  public addExtendFilter(filter: ISelectItem) {
    this.selectedFilter = filter.key;
    const extendFilter = this.extendFilters.find((extFilter: IExtendFilter) => extFilter.field === filter.key);
    const item: ISelectExtendFilter = {
      ...extendFilter,
      ...{ value: null, criteria: extendFilter.criterias ? extendFilter.criterias[0] : null }
    };
    this.selectedExtendFilters.push(item);
    setTimeout(() => { this.selectedFilter = null }, 0);
  }

  public deleteExtendFilter(index: number) {
    this.selectedExtendFilters = this.selectedExtendFilters.filter((filter: ISelectExtendFilter, ind: number) => ind !== index);
  }

  public clearExtendFilters() {
    this.selectedExtendFilters = [];
    if (this.changeFilters) {
      this.changeFilters(this.selectedExtendFilters);
    }
  }

  public applyExtendFilters() {
    // фикс для сохранения значений инпута по onFocus
    // при нажатии кнопки Применить, пока поле еще активно
    setTimeout(() => {
      if (this.changeFilters) {
        this.changeFilters(this.selectedExtendFilters);
      }
      this.close();
    }, 100);
  }

  public close() {
    this.activeModal.close();
  }

  public changeFilterParam(paramItem: ISelectItem, index: number) {
    const extendFilter = this.extendFilters.find((extFilter: IExtendFilter) => extFilter.field === paramItem.key);
    this.selectedExtendFilters[index] = {
      ...extendFilter,
      ...{ value: null, criteria: extendFilter.criterias ? extendFilter.criterias[0] : null }
    };
  }

  public changeFilterCriteria(criteriaItem: ISelectItem, filter: ISelectExtendFilter) {
    filter.criteria = Criteria[criteriaItem.key];
    if (Criteria[criteriaItem.key] === Criteria.isNull || Criteria[criteriaItem.key] === Criteria.isNotNull) {
      filter.value = null;
    }
  }

  public changeFilterValue(value: any, filter: ISelectExtendFilter) {
    filter.value = value;
  }

  public changeFilterSelectValue(value: ISelectItem, filter: ISelectExtendFilter) {
    filter.value = value ? value.key : null;
  }

  public changeFilterMultiSelectValue(value: IMultiSelectItem[], filter: ISelectExtendFilter) {
    filter.value = value ? value.map((item: IMultiSelectItem) => item.key) : null;
  }

  public updateDictionaryList(params: IDictionaryParams, dictionary: IDictionaryField) {
    this.dictionaryService.updateList(dictionary, params);
  }
}

import {Component, Input} from "@angular/core";

@Component({
  selector: 'app-lock-editable',
  templateUrl: './lock-editable.component.html',
  styleUrls: ['./lock-editable.component.scss']
})
export class LockEditableComponent {

  public tooltipTextForList: string[] = [];

  @Input() set tooltipText(value: any) {
    this.tooltipTextForList = [];
    if (value) {
      if (typeof value === "string") {
        this.tooltipTextForList.push(value);
      } else {
        for (const key in value) {
          if (value.hasOwnProperty(key)) {
            this.tooltipTextForList.push(value[key]);
          }
        }
      }
    }
  };

}

import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'app-arrow',
    templateUrl: './arrow.component.html',
    styleUrls: ['./arrow.component.scss']
})
export class ArrowComponent {
    /**
     * Флаг отключения.
     * @default false
     */
    @Input() disabled: boolean = false;
    /**
     * Размер шрифта в пикселях.
     * @default 8
     */
    @Input() fontSize: number = 8;
    /**
     * Направление стрелки.
     * @options 'top' | 'bottom' | 'left' | 'right'
     * @default 'top'
     */
    @Input() direction: 'top' | 'bottom' | 'left' | 'right' = 'top';
    /**
     * Событие клика.
     */
    @Output() readonly OnClick = new EventEmitter<MouseEvent>();

    public onClick($event: MouseEvent) {
        if (this.disabled) {
            return;
        }
        this.OnClick.emit($event);
    }
}

import { AbstractControl, ValidatorFn } from '@angular/forms';
import { passwordPatterns } from '@app/app.enums';
import { IValidator, IValidatorArray } from "@core/interfaces/validator";

export function PasswordValidator(
  pasMinUpper: number,
  pasMinLower: number,
  pasMinNumbers: number,
  pasMinSpec: number,
  errorKey: string = 'password',
  errorsPrefix: string = 'password-'
): ValidatorFn {
  const counts = {
    AZ: pasMinUpper,
    az: pasMinLower,
    num: pasMinNumbers,
    spec: pasMinSpec
  };
  return (control: AbstractControl): IValidatorArray => {
    if (!control.value) {
      return null;
    }

    const errors: IValidator[] = [];
    for (const key of Object.keys(passwordPatterns)) {
      const regexp = passwordPatterns[key];
      if (key === 'rus') {
        if (!regexp.test(control.value)) {
          errors.push({ [`${errorsPrefix}${key}`]: true })
        }
      }
      else {
        const count = (control.value.match(regexp) || []).join('').length;
        if (count < counts[key]) {
          errors.push({ [`${errorsPrefix}${key}`]: true })
        }
      }
    }
    return errors.length ? { [errorKey]: errors } : null
  }
}

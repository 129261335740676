import { Component, ElementRef, EventEmitter, Host, Renderer2 } from "@angular/core";

/**
 * Компонент с кнопками для календаря с подтверждением.
 */
@Component({
    selector: 'app-calendar-with-apply',
    templateUrl: './calendar-with-apply.component.html',
    styleUrls: ['./calendar-with-apply.component.scss']
})
export class CalendarWithApplyComponent {
    public readonly OnApply = new EventEmitter<MouseEvent>();
    public readonly OnCancel = new EventEmitter<MouseEvent>();

    public set isVisible(value: boolean) {
        this.renderer2.setStyle(
            this.host.nativeElement,
            'display',
            value ? 'block' : 'none'
        );
    }

    constructor(
        @Host() private host: ElementRef,
        private renderer2: Renderer2
    ) { }
}

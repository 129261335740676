import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root"
})
export abstract class AuthService {
  abstract login(username, password): Observable<any>;

  abstract logout(): Observable<any>;

  abstract refreshToken(): Observable<any>;

  abstract getToken(): string;

  abstract setCredentials(tokenResponse: any): void;

  abstract clearCredentials(): void;

  /**
   * Проверить, авторизован ли пользователь
   */
  abstract isAuthorized(): Boolean;

  /**
   * Получить код авторизации приложения фронта
   */
  abstract getAppAuthorizationCode(): string;
}

import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() title: string = ''; // текст кнопки
  @Input() disabled: boolean = false; // флаг блокировки кнопки
  @Input() icon: string | null = null; // класс иконки для кнопки
  @Input() iconSize: number | null = null; // размер иконки для кнопки
  @Input() iconColor: string | null = null; // цвет иконки для кнопки
  @Input() iconSlot: 'start' | 'end' = 'start';
  @Output() OnClick: EventEmitter<any> = new EventEmitter(); // событие клика

  constructor() { }

  onClickBtn($event: any = null): void {
    if (this.disabled && $event) {
      $event.stopPropagation();
    } else {
      this.OnClick.emit($event);
    }
  }

}

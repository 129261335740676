import { Injectable, OnDestroy } from '@angular/core';

/**
 * Сервис обмена информацией между несколькими вкладками, браузерами и тд
 */
@Injectable({
  providedIn: 'root',
})
export class BroadcastChannelService implements OnDestroy {
  private broadcastChannelsMap: Map<string, any> = new Map<string, any>();

  constructor() {}

  ngOnDestroy(): void {
    for (const key of this.broadcastChannelsMap.keys()) {
      this.broadcastChannelsMap.get(key).close();
    }
  }

  public setBroadcastChannelByName(name: string) {
    try {
      const broadcastChannel = new BroadcastChannel(name);
      this.broadcastChannelsMap.set(name, broadcastChannel);
      return broadcastChannel;
    } catch (error) {
      console.log(error);
    }
  }

  public getBroadcastChannelByName(name: string) {
    return this.broadcastChannelsMap.get(name);
  }

  public postMessageToBroadcastChannel(message: any, name: string): void {
    try {
      const broadcastChannel = this.broadcastChannelsMap.get(name);
      if(broadcastChannel) {
        broadcastChannel.postMessage(message);
      }
    } catch(error) {
      console.log(error)
    }
  }

  public addMessageListener(callback: () => any, name: string): void {
    try {
      const broadcastChannel = this.broadcastChannelsMap.get(name);
      if(broadcastChannel) {
        broadcastChannel.onmessage = callback;
      }
    } catch(error) {
      console.log(error);
    }
  }
}

import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";
import { generatePseudoUid } from "@app/shared/functions/generate-pseudo-uid.function";


export abstract class BaseFieldComponent implements ControlValueAccessor {
    /**
     * ID поля
     * @default "уникальный" id из generatePseudoUid
     */
    @Input() id: string = generatePseudoUid();
    /**
     * Блокировка
     */
    @Input() disabled: boolean = false;
    /**
     * Только чтение
     */
    @Input() readonly: boolean = false;
    /**
     * Флаг отображения иконки блокировки
     * @default true
     */
    @Input() withLockIcon: boolean = true;

    /**
     * Событие фокуса
     */
    @Output() readonly OnFocus = new EventEmitter<any>();
    /**
     * Событие снятия фокуса
     */
    @Output() readonly OnBlur = new EventEmitter<any>();

    /**
     * Коллбек изменения значения из Angular forms API
     */
    protected onChange: Function = () => { };
    /**
     * Коллбек изменения состояния touched из Angular forms API
     */
    protected onTouched: Function = () => { };

    /**
     * Функция отправки события focus
     * @param event событие
     */
    emitFocusEvent(event: any) {
        this.OnFocus.emit(event);
    }

    /**
     * Функция отправки события blur
     * @param event событие
     */
    emitBlurEvent(event: any) {
        this.OnBlur.emit(event);
        this.onTouched();
    }

    /**
     * Функция записи значения в компонент.
     * Вызывается из forms API при изменении значения контрола.
     * @param value значение
     */
    abstract writeValue(value: any): void;

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
}

import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {EnvService} from "@core/services/env.service";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  public mainIconUrl: string = null;

  constructor(
    private router: Router,
    private envService: EnvService
  ) {
    this.mainIconUrl = this.envService.mainIcon;
  }

  toMainPage() {
    this.router.navigate(['/home']);
  }
}

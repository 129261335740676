import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { animationFrameScheduler, BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import {IMultiSelectItem, ISelectItem} from "@core/interfaces/select-item";
import {IDictionaryBody, IDictionaryParams} from "@core/interfaces/dictionary";
import { ModalRedirectCloseService } from '@app/shared/services/modal-redirect-close.service';
import { scrollToTop } from '@app/shared/functions';

@Component({
  selector: 'app-simple-dictionary-modal',
  templateUrl: './simple-dictionary-modal.component.html',
  styleUrls: ['./simple-dictionary-modal.component.scss'],
  providers: [ModalRedirectCloseService]
})
export class SimpleDictionaryModalComponent implements OnInit {

  @Input() loadingInProgress: BehaviorSubject<boolean>; // флаг загрузки
  @Input() selected: ISelectItem = null; // выбранное значение
  @Input() list: BehaviorSubject<IDictionaryBody>; // список значений
  @Input() params: IDictionaryParams; // список значений
  @Input() title: string = null; // тайтл при отсутствии выбранного значения (placeholder)
  @Output() OnSelect: Function; // применить выбранное значение
  @Output() OnLoadList: Function; //загрузить список значений
  @Output() OnClose: Function; //функция при закрытии

  @ViewChild('dictList') dictList: ElementRef;

  public showList$: Observable<boolean>;
  public elements$: Observable<IMultiSelectItem[]>;
  public loadedCount$: Observable<number>;
  public totalCount$: Observable<number>;

  public selectedItem: ISelectItem = null; // выбранное значение
  public activeApply: boolean = false;

  public onScroll$: Subject<Event> = new Subject<Event>();
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public activeModal: NgbActiveModal,
    private modalRedirectCloseService: ModalRedirectCloseService
  ) { }

  ngOnInit() {
    this.showList$ = this.list.pipe(
      map((res) => res && res.elements && res.elements.length > 0)
    );
    this.elements$ = this.list.pipe(
      map((res) => res && res.elements && res.elements.length > 0 ? res.elements : [])
    );
    this.loadedCount$ = this.list.pipe(
      map((res) => res && res.elements && res.elements.length > 0 ? res.elements.length : 0)
    );
    this.totalCount$ = this.list.pipe(
      map((res) => res && res.total ? res.total : 0)
    );
    this.onScroll$
      .pipe(
        filter(res => {
          const element = res.target;
          return element['scrollHeight'] - element['scrollTop'] <= element['clientHeight'] + 10;
        }),
        throttleTime(100, animationFrameScheduler),
        takeUntil(this.onDestroy$)
      )
      .subscribe(res => {
        this.loadMore();
      });
    this.selectedItem = this.selected ? this.selected : null;
    if (this.params && this.params.pagination && this.params.pagination.pageNumber
      && this.params.pagination.pageNumber === 1
      && this.list && this.list.getValue() && this.list.getValue().elements
      && this.list.getValue().elements.length === 0) {
      if (this.OnLoadList) {
        this.OnLoadList(this.params);
      }
    }
    this.checkActiveApply();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private checkActiveApply() {
    this.activeApply = (this.selectedItem && this.selected && this.selectedItem.key.toString() !== this.selected.key.toString())
      || (this.selectedItem && !this.selected);
  }
  /**
   * функция выбора значения
   * @param item
   */
  public onSelectItem(item: ISelectItem): void {
    this.selectedItem = item;
    this.checkActiveApply();
  }

  /**
   * применить
   */
  public apply() {
    if (!this.selectedItem) {
      return;
    }
    if (this.OnSelect) {
      this.OnSelect(this.selectedItem);
    }
    this.cancel();
  }

  /**
   * подгрузка списка
   */
  public loadMore() {
    if (this.params && this.params.pagination && this.params.pagination.pageNumber
      && this.list && this.list.getValue() && this.list.getValue().totalPages
      && this.params.pagination.pageNumber < this.list.getValue().totalPages) {
      this.params.pagination.pageNumber = this.params.pagination.pageNumber + 1;
      if (this.OnLoadList) {
        this.OnLoadList(this.params);
      }
    }
  }

  /**
   * сортировка списка
   */
  public changeSorting() {
    if (this.params.sortings && this.params.sortings.length > 0 && this.params.sortings[0].direction === 'asc') {
      this.params.sortings[0].direction = 'desc';
    } else {
      this.params.sortings[0].direction = 'asc';
    }
    this.params.pagination.pageNumber = 1;
    if (this.dictList) {
      this.scrollToTop();
    }
    if (this.OnLoadList) {
      this.OnLoadList(this.params);
    }
  }

  /**
   * поиск по списку
   * @param value
   */
  public search(value: string) {
    this.params.search = value;
    this.params.pagination.pageNumber = 1;
    if (this.dictList) {
      this.scrollToTop();
    }
    if (this.OnLoadList) {
      this.OnLoadList(this.params);
    }
  }

  /**
   * отмена
   */
  public cancel(): void {
    if (this.OnClose) {
      this.OnClose();
    }
    this.activeModal.close();
  }

  /**
   * скролл карточки наверх
   */
    private scrollToTop(): void {
      scrollToTop(this.dictList);
    }
}

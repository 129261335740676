import { Component, Input, Output } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import { ModalRedirectCloseService } from '@app/shared/services/modal-redirect-close.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  providers: [ModalRedirectCloseService]
})
export class ConfirmModalComponent {

  @Input() title: string = null; //заголовок
  @Input() question: string = null; //текст
  @Input() iconQuestion: string = 'icon-exclam_point'; //иконка
  @Input() iconQuestionSize: number = 65; //размер иконки
  @Input() iconQuestionColor: string; //цвет иконки
  @Input() cancelTitle: string = null; //текст кнопки Отмена
  @Input() applyTitle: string; //текст кнопки Ок
  @Input() iconCancel: string; //класс иконки кнопки Отмена
  @Input() iconCancelSize: number; //размер иконки Отмена
  @Input() iconCancelColor: string; //цвет иконки Отмена
  @Input() iconApply: string; //класс иконки кнопки Ок
  @Input() iconApplySize: number; //размер иконки Ок
  @Input() iconApplyColor: string; //цвет иконки Ок
  @Input() applyButtonDisabled: boolean = false; //флаг недоступности кнопки Ок
  @Input() cancelButtonDisabled: boolean = false; //флаг недоступности кнопки Отмена

  @Output() public onCancel: Function; //событие на кнопку Отмена
  @Output() public onApply: Function; //событие на кнопку Ок

  public cancelTitleDefault: string = '';
  public applyTitleDefault: string = '';

  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal,
    private modalRedirectCloseService: ModalRedirectCloseService
  ) {
    this.translateService
      .get(["GENERAL.OK", "GENERAL.CANCEL"])
      .subscribe((res) => {
        this.cancelTitleDefault = res["GENERAL.CANCEL"];
        this.applyTitleDefault = res["GENERAL.OK"];
        if (!this.applyTitle) {
          this.applyTitle = this.applyTitleDefault;
        }
        if (!this.cancelTitle) {
          this.cancelTitle = this.cancelTitleDefault;
        }
      });

  }

  public cancel(): void {
    if (this.cancelButtonDisabled) {
      return;
    }
    if (this.onCancel) {
      this.onCancel();
    }
    this.activeModal.close();
  }

  public apply(): void {
    if (this.applyButtonDisabled) {
      return;
    }
    if (this.onApply) {
      this.onApply();
    }
    this.activeModal.close();
  }

}

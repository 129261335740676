import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { LockEditableModule } from "../lock-editable/lock-editable.module";
import { SelectFieldComponent } from "./select-field.component";

@NgModule({
    declarations: [
        SelectFieldComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        LockEditableModule
    ],
    exports: [
        SelectFieldComponent
    ]
})
export class SelectFieldModule { }

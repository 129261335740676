import {Component, EventEmitter, forwardRef, Input, OnChanges, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {IMultiSelectItem} from "@core/interfaces/select-item";
import {IDictionaryBody, IDictionaryParams} from "@core/interfaces/dictionary";
import {OpenModalService} from "@shared/services/open-modal.service";
import {DictionaryService} from "@shared/services/dictionary.service";
import {MultiDictionaryModalComponent} from "@shared/components/multi-dictionary/multi-dictionary-modal/multi-dictionary-modal.component";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import {BehaviorSubject} from "rxjs";


@Component({
  selector: 'app-multi-dictionary',
  templateUrl: './multi-dictionary.component.html',
  styleUrls: ['./multi-dictionary.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiDictionaryComponent),
      multi: true
    }
  ]
})
export class MultiDictionaryComponent implements OnChanges, ControlValueAccessor {

  @Input() loadingInProgress: BehaviorSubject<boolean>; // флаг загрузки
  @Input() selected: IMultiSelectItem[] = null; // список выбранных значений
  @Input() list: BehaviorSubject<IDictionaryBody> = this.dictionaryService.getDefaultList$(); // список значений
  @Input() params: IDictionaryParams = this.dictionaryService.getDefaultParams(); // параметры справочника
  @Input() title: string = null; // тайтл при отсутствии выбранного значения (placeholder)
  @Input() titleDictionary: string = null; // тайтл справочника
  @Input() forFilter: boolean = false; // флаг типа селекта - для панели фильтров или для полей
  @Input() withFullWidth: boolean = false; // флаг широкого окна справочника - для справочника Адресов
  @Input() disabled: boolean = false; // блокировка
  @Input() countShowValuesForFilter: number = 1; // кол-во отображаемых выбранных значений для панели фильтров
  @Output() OnSelect: EventEmitter<IMultiSelectItem[]> = new EventEmitter<IMultiSelectItem[]>(); // применение выбора
  @Output() OnLoadList: EventEmitter<IDictionaryParams> = new EventEmitter<IDictionaryParams>(); // загрузить список значений

  private multiDictionaryModal: NgbModalRef;
  public selectedItemsValue: string[] = []; // выбранные значения - value
  public _onChange: any = () => {};
  public _onTouch: any = () => {};

  constructor(
    private modalService: OpenModalService,
    private dictionaryService: DictionaryService
  ) { }

  ngOnChanges() {
    this.selectedItemsValue = this.getSelectedValues();
    this._onChange(this.selected);
  }

  /**
   * открытие окна справочника
   */
  public open() {
    let windowClass = 'dictionary-modal';
    if (this.withFullWidth) {
      windowClass += ' dictionary-modal-full-width';
    }
    if (this.params && this.params.pagination && this.params.pagination.pageNumber
      && this.params.pagination.pageNumber > 1) {
      this.params.pagination.pageNumber = 1;
    }
    this.multiDictionaryModal = this.modalService.show(
      {
        component: MultiDictionaryModalComponent,
        data: {
          selected: this.selected,
          disabled: this.disabled,
          list: this.list,
          params: this.params,
          title: this.titleDictionary ? this.titleDictionary : this.title,
          loadingInProgress: this.loadingInProgress,
          OnSelect: (items: IMultiSelectItem[]) => {
            this.apply(items);
          },
          OnLoadList: (params: IDictionaryParams) => {
            this.loadList(params);
          },
          OnClose: () => {
            this.params = {...this.params, ...this.dictionaryService.getDefaultParams()};
            this.list.next(
              this.dictionaryService.getDefaultList()
            );
          }
        },
        options: {
          centered: true,
          windowClass: windowClass,
        },
        callbacks: {
          Enter: 'apply',
          Escape: 'cancel'
        }
      });
  }

  /**
   * сбросить все выбранные значения
   */
  public removeSelection(): void {
    this.writeValue(null);
    this.OnSelect.emit(null);
  }

  /**
   * удалить конкретное выбранное значение
   * @param item
   */
  public removeSelectItem(item: IMultiSelectItem): void {
    this.selected = this.selected.filter((selectItem: IMultiSelectItem) => selectItem.key.toString() !== item.key.toString());
    this.selectedItemsValue = this.getSelectedValues();
    this.writeValue(this.selected);
    this.OnSelect.emit(this.selected);
  }

  /**
   * получить список выбранных values
   */
  private getSelectedValues() {
    return this.selected && this.selected.length > 0
      ? this.selected.map((item: IMultiSelectItem) => {return item.value})
      : [];
  }

  /**
   * применить
   * @param items
   */
  public apply(items: IMultiSelectItem[]) {
    this.writeValue(items);
    this.OnSelect.emit(items);
  }

  /**
   * подгрузка списка
   * @param params
   */
  public loadList(params: IDictionaryParams) {
    this.params = params;
    this.OnLoadList.emit(this.params);
  }

  writeValue(items: IMultiSelectItem[]): void {
    this.selected = items;
    this.selectedItemsValue = this.getSelectedValues();
    this._onChange(items);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}

import { Injectable } from "@angular/core";
import {IDayMonthYear, IMonthYear} from "@core/interfaces/select-item";
import { date_full_ISO_pattern, date_ISO_pattern } from "@app/app.enums";
import * as dayjs from 'dayjs';
import * as dayjsUtc from 'dayjs/plugin/utc';
import * as dayjsFormats from 'dayjs/plugin/customParseFormat';
import * as dayjsRu from 'dayjs/locale/ru';
import * as dayjsQuarter from 'dayjs/plugin/quarterOfYear';
import * as dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Dayjs } from "dayjs";
dayjs.extend(dayjsQuarter);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsFormats);
dayjs.extend(dayjsIsSameOrBefore);
dayjs.extend(dayjsIsSameOrAfter);
dayjs.locale(dayjsRu);

export enum DateFormat {
  DATE = 'DD.MM.YYYY',
  TIME = 'HH:mm',
  TIME_WITH_SECONDS = 'HH:mm:ss',
  DATE_TIME = 'DD.MM.YYYY HH:mm',
  DATE_TIME_WITH_SECONDS = 'DD.MM.YYYY HH:mm:ss',
  DATE_TIME_FOR_DATE = 'MM.DD.YYYY HH:mm',
  DATE_FOR_FILE = 'DD.MM.YYYY_HH:mm:ss',
  BACKEND_ONLY_DATE = 'YYYY-MM-DD'
}

export enum TimeUnit {
  DAY = "day",
  WEEK = "week",
  QUARTER = "quarter",
  MONTH = "month",
  YEAR = "year",
  HOUR = "hour",
  MINUTE = "minute",
  SECOND = "second"
}

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  public readonly DIGIT_REG_EXP = /\d/;
  public readonly DATE_SEPARATOR = '.';
  public readonly TIME_SEPARATOR = ':';

  private UNIT_OF_TIME_MAP = {
    year: 'year',
    month: 'month',
    week: 'week',
    iso_week: 'isoWeek',
    day: 'day',
    hour: 'hour',
    minute: 'minute',
    second: 'second'
  };

  private dateFormat = {
    date: 'DD.MM.YYYY',
    time: 'HH:mm',
    timeWithSecond: 'HH:mm:ss',
    dateTime: 'DD.MM.YYYY HH:mm',
    dateTimeWithSecond: 'DD.MM.YYYY HH:mm:ss',
    dateTimeForDate: 'MM.DD.YYYY HH:mm',
    dateForFile: 'DD.MM.YYYY_HH-mm-ss',
  };

  constructor() {
  }

  //возвращает строку с датой для бэка из строки в заданном формате
  public getBackendDateFromString(date: string, format: string = this.dateFormat.dateTime): any {
    if (date) {
      let res = dayjs(date, format).format();
      const str = res ? res.split('+') : null;
      return str ? str.length > 1 ? `${str[0]}.0+${str[1]}` : res : null;
    } else {
      return null;
    }
  }

  //возвращает строку с датой для бэка из строки в заданном формате
  public getBackendDateFromStringForFile(date: string, format: string = this.dateFormat.dateTime): any {
    if (date) {
      let res = dayjs(date, format).format(this.dateFormat.dateForFile);
      let dateWithOffset = this.getTimeZoneOffsetString(date, format);
      dateWithOffset = dateWithOffset.replace(':', '');
      return `${res}${dateWithOffset}`;
    } else {
      return null;
    }
  }

  //возвращает строку с датой в заданном формате из даты с бэка
  public getDateFromBackend(date: string, format: string = this.dateFormat.dateTime): string {
    if (this.isBackendDate(date)) {
      return dayjs(date).format(format);
    }
    return 'Invalid date';
  }

  private isBackendDate(date): boolean {
    return date && date.indexOf('T') > -1;
  }

  private isISOFullDate(date): boolean {
    return !!(date && String(date).match(date_full_ISO_pattern));
  }

  private isISODate(date): boolean {
    return !!(date && String(date).match(date_ISO_pattern));
  }

  //возвращает строку с датой в заданном формате из даты с бэка или текст
  public getDateOrText(
    date: string,
    format: string = this.dateFormat.dateTime,
    keepLocalTime: boolean = true): string {
    if (this.isISOFullDate(date)) {
      return keepLocalTime
        ? dayjs(date).format(format)
        : dayjs(date).utc().format();
    } else if (this.isISODate(date)) {
      return dayjs(date).format(this.dateFormat.date)
    }
    return date;
  }

  public getDateFormat(type: string): string {
    return this.dateFormat[type];
  }

  public getDateMask(): any[] {
    return [
      this.DIGIT_REG_EXP,
      this.DIGIT_REG_EXP,
      this.DATE_SEPARATOR,
      this.DIGIT_REG_EXP,
      this.DIGIT_REG_EXP,
      this.DATE_SEPARATOR,
      this.DIGIT_REG_EXP,
      this.DIGIT_REG_EXP,
      this.DIGIT_REG_EXP,
      this.DIGIT_REG_EXP
    ];
  }

  public getTimeMask(timeFormat: string = this.dateFormat.timeWithSecond): any[] {
    if (timeFormat === this.dateFormat.timeWithSecond) {
      return [
        this.DIGIT_REG_EXP,
        this.DIGIT_REG_EXP,
        this.TIME_SEPARATOR,
        this.DIGIT_REG_EXP,
        this.DIGIT_REG_EXP,
        this.TIME_SEPARATOR,
        this.DIGIT_REG_EXP,
        this.DIGIT_REG_EXP
      ];
    } else {
      return [
        this.DIGIT_REG_EXP,
        this.DIGIT_REG_EXP,
        this.TIME_SEPARATOR,
        this.DIGIT_REG_EXP,
        this.DIGIT_REG_EXP
      ];
    }
  }

  // получение IDayMonthYear из строки даты
  public getDayMonthYearFromString(date: string, format: string = this.dateFormat.date): IDayMonthYear {
    if (date) {
      const dateM = dayjs(date, format);
      return {
        day: dateM.date(),
        month: dateM.month() + 1,
        year: dateM.year()
      }
    } else {
      return null;
    }
  }

  // получение строки даты из IDayMonthYear
  public getStringFromDayMonthYear(value: IDayMonthYear, format: string = this.dateFormat.date): string {
    if (value) {
      return dayjs(
        new Date(value.year, value.month - 1, value.day)
      ).format(format);
    } else {
      return null;
    }
  }

  public getTimeStringFromString(
    value: string,
    inputFormat: string = this.dateFormat.dateTimeWithSecond,
    outputFormat: string = this.dateFormat.timeWithSecond
  ): string {
    if (value) {
      const dateM = dayjs(value, inputFormat);
      if (dateM.year() === 0) {
        dateM.year(dayjs().year());
      }
      return dateM.format(outputFormat) !== 'Invalid date' ? dateM.format(outputFormat) : null;
    } else {
      return null;
    }
  }

  public getDateStringFromString(
    value: string,
    inputFormat: string = this.dateFormat.dateTimeWithSecond,
    outputFormat: string = this.dateFormat.date
  ): string {
    if (value) {
      const dateM = dayjs(value, inputFormat);
      if (dateM.year() === 0) {
        dateM.year(dayjs().year());
      }
      return dateM.format(outputFormat) !== 'Invalid date' ? dateM.format(outputFormat) : null;
    } else {
      return null;
    }
  }

  /**
   * Возвращает количество дней в выбранном месяце
   * @param value - дата в формате IMonthYear
   * @returns - количество дней месяце
   */
  public getDaysInMonthCount(value: IMonthYear): number {
    const date = new Date(value.year, value.month - 1);
    return dayjs(date).daysInMonth();
  }

  /**
   * Возвращает дату в ЧЗ клиента
   * @param value - дата
   * @param inputFormat - входной формат даты
   * @param outputFormat - выходной формат даты
   * @returns - дата в ЧЗ клиента
   */
  public getLocalDateFromDateString(
    value: string,
    inputFormat?: string,
    outputFormat: string = this.dateFormat.dateTime
  ): string {
    if(!inputFormat) {
      return dayjs(value).local().format(outputFormat);
    }
    return dayjs(value, inputFormat).local().format(outputFormat);
  }

  /**
   * Получить строку временной зоны
   * @returns +03:00 / -05:00
   */
  public getTimeZoneOffsetString(date?: string, format?: string) {
    let jsonDate = dayjs(date, format).format();
    return jsonDate.includes('+')
      ? jsonDate.substr(jsonDate.indexOf('+'))
      : jsonDate.substr(jsonDate.lastIndexOf('-'));
  }

    /**
   * Возвращает разницу между временным отрезком
   * @param timeUnit - в чем нужно получить разницу (enum TimeUnit);
   * @param firstDate - дата, до которой нужно рассчитать разницу;
   * @param firstDateFormat - формат даты, до которой нужно рассчитать разницу. По умолчанию 'YYYY-MM-DD';
   * @param secondDate - дата, с которой нужно рассчитать разницу. По умолчанию текущая дата;
   * @param secondDateFormat - формат даты, с которой нужно рассчитать разницу. По умолчанию 'YYYY-MM-DD'.
   */
    public getDateDifference(
      timeUnit: TimeUnit,
      firstDate: string | Date | Dayjs,
      firstDateFormat: string = DateFormat.DATE,
      secondDate?: string | Date | Dayjs,
      secondDateFormat: string = DateFormat.DATE) {

      let firstDateTemp: Dayjs = dayjs(firstDate, firstDateFormat);
      let secondDateTemp: Dayjs | string;

      switch (timeUnit) {
        case TimeUnit.DAY:
        case TimeUnit.HOUR:
        case TimeUnit.MINUTE:
          secondDate = secondDate ? secondDate : dayjs().format(secondDateFormat);
          secondDateTemp = dayjs(secondDate, secondDateFormat);
          break;
        default:
          secondDateTemp = 'Invalid date';
      }
      return firstDateTemp.diff(secondDateTemp, timeUnit);
    }
}

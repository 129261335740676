import { Directive, HostBinding, Input, TemplateRef } from "@angular/core";
import { IKeyValuePairs } from "@app/core/interfaces/key-value-pairs";

/**
 * Интерфейс с общими свойствами для контейнера попапов.
 */
export interface IPopupContainerCommonProperties {
    /**
     * Целевой компонент, к которому следует привязать попап.
     */
    popupContainerTarget?: HTMLElement;
    /**
     * z-index попапа.
     */
    popupContainerZIndex?: number;
    /**
     * Задержка коллбека на события resize / scroll.
     */
    popupContainerEventsThrottle?: number;
    /**
     * Сдвиг по вертикали.
     */
    popupContainerOffsetTop?: number;
    /**
     * Сдвиг по горизонтали.
     */
    popupContainerOffsetLeft?: number;
    /**
     * Сдвиг относительно оборажения {@link popupContainerOpenDirection}.
     * @example
     *                        offset[1]
     *                            ↑
     *                          'top'
     * offset[0] ← 'left' [target element] 'right' → offset[0]
     *                         'bottom'
     *                            ↓
     *                        offset[1]
     */
    popupContainerOffsetRelative?: [number, number];
    /**
     * Флаг предотвращения выхода элемента за границы окна.
     */
    popupContainerPreventOutsideWindow?: boolean;
    /**
     * Класс контейнера попапов.
     * Применяется к host элементу контейнера.
     * Класс должен быть задекларирован в общих стилях.
     */
    popupContainerClass?: string;
    /**
     * CSS свойства контейнера.
     */
    popupContainerCssProperties?: IKeyValuePairs;
    /**
     * Новая цель событий DOM, происходящих в попапе
     * (например, чтобы избежать срабатывания clickOutside).
     */
    popupContainerEventTarget?: HTMLElement;
    /**
     * Список событий, которые необходимо перехватывать и направлять в {@link popupContainerEventTarget}.
     * @requires popupContainerEventTarget
     */
    popupContainerEventsList?: string[];
}

/**
 * Базовый класс попапов.
 * Объединяет общие свойства:
 * Компонента-шаблона app-popup-container-template;
 * Директивы [popupContainer];
 * Компонента-контейнера app-popup-container.
 */

export abstract class PopupContainerBase
    implements IPopupContainerCommonProperties {
    /**
     * ng-template шаблон попапа
     */
    @Input() popupContainer: TemplateRef<any>;
    /**
     * @inheritdoc
     */
    @Input() popupContainerTarget: HTMLElement = null;
    /**
     * Направление отображения попапа.
     * @default 'bottom center'
     * @options bottom, top, left, right, center
     */
    @Input() popupContainerOpenDirection: string = 'bottom center';
    /**
     * @inheritdoc
     * @default 999
     */
    @Input() popupContainerZIndex: number = 999;
    /**
     * @inheritdoc
     * @default 0
     */
    @Input() popupContainerEventsThrottle: number = 0;
    /**
     * @inheritdoc
     * @default 0
     */
    @Input() popupContainerOffsetTop: number = 0;
    /**
     * @inheritdoc
     * @default 0
     */
    @Input() popupContainerOffsetLeft: number = 0;
    /**
     * @inheritdoc
     * @default [0,0]
     */
    @Input() popupContainerOffsetRelative: [number, number] = [0, 0];
    /**
     * @inheritdoc
     * @default true
     */
    @Input() popupContainerPreventOutsideWindow: boolean = true;
    /**
     * @inheritdoc
     */
    @Input() popupContainerEventTarget: HTMLElement = null;
    /**
     * @inheritdoc
     * @default ['click', 'contextmenu']
     */
    @Input() popupContainerEventsList: string[] = ['click', 'contextmenu'];
    /**
     * @inheritdoc
     */
    @Input()
    @HostBinding('class')
    popupContainerClass: string = null;
    /**
     * @inheritdoc
     */
    @Input() popupContainerCssProperties: IKeyValuePairs;

    constructor() { }
}

import { Component, EventEmitter, forwardRef, Input, OnChanges, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SimpleDictionaryModalComponent } from "./simple-dictionary-modal/simple-dictionary-modal.component";
import { BehaviorSubject } from 'rxjs';
import {ISelectItem} from "@core/interfaces/select-item";
import {IDictionaryBody, IDictionaryParams} from "@core/interfaces/dictionary";
import {OpenModalService} from "@shared/services/open-modal.service";
import {DictionaryService} from "@shared/services/dictionary.service";

@Component({
  selector: 'app-simple-dictionary',
  templateUrl: './simple-dictionary.component.html',
  styleUrls: ['./simple-dictionary.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleDictionaryComponent),
      multi: true
    }
  ]
})
export class SimpleDictionaryComponent implements OnChanges, ControlValueAccessor {

  @Input() loadingInProgress: BehaviorSubject<boolean>; // флаг загрузки
  @Input() selected: ISelectItem = null; // выбранное значение
  @Input() list: BehaviorSubject<IDictionaryBody> = this.dictionaryService.getDefaultList$(); // список значений
  @Input() params: IDictionaryParams = this.dictionaryService.getDefaultParams();
  @Input() title: string = null; // тайтл при отсутствии выбранного значения (placeholder)
  @Input() titleDictionary: string = null; // тайтл справочника
  @Input() forFilter: boolean = false; // флаг типа селекта - для панели фильтров или для полей
  @Input() disabled: boolean = false; // блокировка
  @Input() disabledTooltip: string[] = null; // тултип блокировки
  @Input() withLockIcon: boolean = true; // флаг отображения иконки блокировки
  @Input() withRemove = false; // флаг отображение иконки сброса значения
  @Input() forAddRow = false; // флаг отображения для добавления записи с +/текстом
  @Input() withPlus = false; // флаг отображения для добавления записи с +
  @Output() OnSelect: EventEmitter<ISelectItem> = new EventEmitter<ISelectItem>();
  @Output() OnLoadList: EventEmitter<IDictionaryParams> = new EventEmitter<IDictionaryParams>(); //загрузить список значений

  public _onChange: any = () => { };
  public _onTouch: any = () => { };

  // protected simpleDictionaryModal: NgbModalRef;

  constructor(
    protected modalService: OpenModalService,
    protected dictionaryService: DictionaryService
  ) { }

  ngOnChanges() {
    this._onChange(this.selected);
  }

  public open() {
    if (this.disabled) {
      return;
    }
    if (this.params && this.params.pagination && this.params.pagination.pageNumber
      && this.params.pagination.pageNumber > 1) {
      this.params.pagination.pageNumber = 1;
    }
    this.modalService.show({
      component: SimpleDictionaryModalComponent,
      data: {
        selected: this.selected,
        list: this.list,
        params: this.params,
        title: this.titleDictionary ? this.titleDictionary : this.title,
        loadingInProgress: this.loadingInProgress,
        OnSelect: (item: ISelectItem) => {
          this.apply(item);
          if (this.forAddRow) {
            this.writeValue(null);
          }
        },
        OnLoadList: (params: IDictionaryParams) => {
          this.loadList(params);
        },
        OnClose: () => {
          this.params = { ...this.params, ...this.dictionaryService.getDefaultParams() };
          this.list.next(
            this.dictionaryService.getDefaultList()
          );
        }
      },
      options: {
        centered: true,
        windowClass: 'dictionary-modal',
      },
      callbacks: {
        Enter: 'apply',
        Escape: 'cancel'
      }
    });
  }

  public removeSelection(): void {
    this.writeValue(null);
    this.OnSelect.emit(null);
  }

  public apply(item: ISelectItem) {
    this._onChange(item);
    this.OnSelect.emit(item);
    this.writeValue(item);
  }

  public loadList(params: IDictionaryParams) {
    this.params = params;
    this.OnLoadList.emit(this.params);
  }

  writeValue(item: ISelectItem): void {
    this.selected = item;
    this._onChange(item);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

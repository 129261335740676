import { Injectable } from '@angular/core';
import { Observable } from "rxjs/internal/Observable";
import { LocalStorageService } from "@core/services/local-storage.service";
import { BehaviorSubject } from "rxjs";
import { IUserMenu } from "@core/interfaces/user";
import { AuthService } from "@core/services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private openedMenuKey: string = 'adminMenuOpened';
  private openedMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public userMenuKey: string = 'adminMenu';
  private userMenu$: BehaviorSubject<IUserMenu[]> = new BehaviorSubject<IUserMenu[]>([]);
  private menuUrls = {
    m_NSI: '/access-admin/nsi',
    m_User: '/access-admin/users',
    m_System: '/access-admin/system-parameters',
    m_EventLog: '/access-admin/monitoring'
  };
  private menuIcons = {
    m_NSI: 'icon-section_nsi',
    m_User: 'icon-section_usr',
    m_System: 'icon-section_sysparam',
    m_EventLog: 'icon-section_mon'
  };

  private activeUrl: string = null;

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
  ) {
    this.getUserMenuTree();
  }

  public getUserMenuTree() {
    const menuFromStorage: IUserMenu[] = this.localStorageService.getObjectByName(this.userMenuKey);
    if (menuFromStorage && this.authService.isAuthorized()) {
      this.setUserMenu(menuFromStorage);
    }
  }

  public getUserMenu(): Observable<IUserMenu[]> {
    return this.userMenu$.asObservable();
  }

  public setUserMenu(userMenu: IUserMenu[]) {
    this.userMenu$.next(userMenu);
    this.localStorageService.setObjectByName(this.userMenuKey, userMenu);
  }

  public setUserMenuWithPrepare(menu: IUserMenu[]) {
    this.setActiveMenu(this.prepareMenu(menu), this.activeUrl);
  }

  public setOpenedMenu(open: boolean) {
    this.openedMenu$.next(open);
    this.localStorageService.setObjectByName(this.openedMenuKey, open);
  }

  public getOpenedMenu(): Observable<boolean> {
    return this.openedMenu$.asObservable();
  }

  private prepareMenu(menu: IUserMenu[]): IUserMenu[] {
    let result: IUserMenu[] = [];
    result = menu.map((level: IUserMenu) => {
      return {
        name: level.name,
        code: level.code,
        sortOrder: level.sortOrder,
        isActive: false,
        url: this.getMenuUrl(level.code),
        iconClass: this.getMenuIconClass(level.code)
      }
    });
    return result.length > 0
      ? result.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
      : [];
  }

  private getMenuIconClass(code: string) {
    const iconClass = this.menuIcons[code];
    return iconClass ? iconClass : null;
  }

  private getMenuUrl(code: string) {
    const url = this.menuUrls[code];
    return url ? url : null;
  }

  public setActiveMenu(menu: IUserMenu[], url: string) {
    if (menu && menu.length > 0) {
      menu = menu.map((item: IUserMenu) => {
        item.isActive = item.url === url;
        return item;
      });
      this.setUserMenu(menu);
    }
  }

  public setActiveUrl(url: string) {
    this.activeUrl = url;
    this.setActiveMenu(this.userMenu$.getValue(), this.activeUrl);
    this.setOpenedMenu(this.activeUrl === '/access-admin');
  }
}

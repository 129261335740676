import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {MenuService} from "@shared/services/menu.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public mainUrl: string = '/home';

  constructor(
    private router: Router,
    private menuService: MenuService,
  ) { }

  ngOnInit() {
  }

  public toogleMenu(open: boolean) {
    this.menuService.setOpenedMenu(open);
  }

  public downloadGuide() {
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    const url = '/assets/documents/Руководство_пользователя_подсистемы_аутентификации_и_авторизации_УЦП_МО_РФ.docx';
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', 'Руководство_пользователя_подсистемы_аутентификации_и_авторизации_УЦП_МО_РФ.docx');
    downloadLink.click();
  }

}

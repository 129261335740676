import { Directive } from "@angular/core";
import { BaseCalendarComponent, IBaseCalendarProperties } from "../../base-components/base-calendar.component";

/**
 * Компонент календаря с подтверждением.
 * Открывает и декларирует некоторые поля,
 * своей логики не имеет.
 * Применяется в качестве провайдера в компоненте,
 * и в качестве наследуемого класса.
 */

export abstract class CalendarWithApplySupportComponent<T>
    extends BaseCalendarComponent<T>
    implements IBaseCalendarProperties<T> {
    /**
     * @inheritdoc
     * Публичный метод в {@link CalendarWithApplySupportComponent}.
     */
    public emitChanges(value: T): void {
        super.emitChanges(value);
    }
    /**
     * Коллбек стрелок календаря.
     * @param direction направление.
     */
    public arrowClick?(direction: 'back' | 'forward'): void;
}

import { Component, Input, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IMonthYear } from '@app/core/interfaces/select-item';
import { TranslateService } from "@ngx-translate/core";
import * as dayjs from 'dayjs';
import * as cloneDeep from 'lodash.clonedeep';
import { IBaseCalendarProperties } from '../../base-components/base-calendar.component';
import { SelectFieldComponent } from '../../select-field/select-field.component';
import { CalendarWithApplySupportComponent } from '../calendar-with-apply/calendar-with-apply-support.component';

@Component({
  selector: 'app-month-select',
  templateUrl: './month-select.component.html',
  styleUrls: ['./month-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthSelectComponent),
      multi: true
    },
    {
      provide: CalendarWithApplySupportComponent,
      useExisting: forwardRef(() => MonthSelectComponent)
    }
  ]
})
export class MonthSelectComponent
  extends CalendarWithApplySupportComponent<IMonthYear>
  implements ControlValueAccessor,
  IBaseCalendarProperties<IMonthYear> {
  /**
   * переводы месяцев
   */
  private readonly monthTranslates =
    this.translateService.instant('DATE.MONTHS');

  /**
   * Текущий год
   */
  public readonly currentYear = this.currentMoment.year();
  /**
   * Текущий месяц
   */
  public readonly currentMonth = this.currentMoment.month() + 1;
  /**
   * Список месяцев.
   * IE fix
   */
  public readonly monthList: string[] = Object.keys(
    this.monthTranslates || {}
  ).map((key) => this.monthTranslates[key]);
  /**
   * @inheritdoc
   * @default 1
   */
  @Input() minMonth: number = 1;
  /**
   * @inheritdoc
   * @default текущий месяц
   */
  @Input() maxMonth: number = this.currentMonth;
  /**
   * @inheritdoc
   * @default false
   */
  @Input() forCalendar: boolean = false;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withHover: boolean = true;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withArrows: boolean = true;
  /**
   * Плейсхолдер месяца.
   * @default 'DATE.MONTH'
   */
  @Input() monthPlaceholder: string = this.translateService.instant('DATE.MONTH');
  /**
   * Плейсхолдер года.
   * @default 'DATE.YEAR'
   */
  @Input() yearPlaceholder: string = this.translateService.instant('DATE.YEAR');

  private get defaultSelected(): IMonthYear {
    return { month: this.currentMonth, year: this.currentYear };
  }

  @ViewChild(SelectFieldComponent, { read: ElementRef }) selectFieldComponent: ElementRef;

  constructor(
    private translateService: TranslateService
  ) {
    super();
  }

  /**
   * @inheritdoc
   */
  public arrowClick(direction: 'forward' | 'back') {
    this.openedSelect$.next(false);
    const selected = this.selected ? this.selected : this.defaultSelected;
    if ((direction == 'forward' && selected.month == this.maxMonth && selected.year == this.maxYear) ||
      (direction == 'back' && selected.month == this.minMonth && selected.year == this.minYear)) {
      return;
    }
    let djs = dayjs([selected.year, selected.month - 1]);
    djs = djs.add(
      direction == 'forward' ? 1 : -1,
      'month'
    );
    this.selected = {
      year: djs.year(),
      month: djs.month() + 1
    };
    this.checkAndReplaceMinMax();
    this.emitChanges(this.selected);
  }

  /**
   * Проверка и замена мин/макс значений.
   */
  private checkAndReplaceMinMax() {
    if (this.isNeedLimit) {
      if (this.selected.year === this.maxYear && this.selected.month > this.maxMonth) {
        this.selected.month = this.maxMonth;
      }
      else if (this.selected.year === this.minYear && this.selected.month < this.minMonth) {
        this.selected.month = this.minMonth;
      }
    }
  }

  /**
   * Коллбек выбора года.
   * @param year год.
   */
  public onSelectYear(year: number): void {
    if (!year) {
      this.clear();
      return;
    }
    if (!this.selected) {
      this.selected = this.defaultSelected;
    }
    this.selected.year = year;
    this.checkAndReplaceMinMax();
    this.emitChanges(this.selected);
  }

  /**
   * Коллбек выбора месяца.
   * @param $event Событие клика.
   * @param month месяц.
   */
  public onSelectMonth(month: number): void {
    this.openedSelect$.next(false);
    if (!this.selected) {
      this.selected = this.defaultSelected;
    }
    this.selected.month = month;
    this.checkAndReplaceMinMax();
    this.emitChanges(this.selected);
  }

  /**
   * @inheritdoc
   */
  writeValue(value: IMonthYear): void {
    this.selected = cloneDeep(value);
  }
}

import {Component, EventEmitter, OnInit, Input, Output} from '@angular/core';
import {OpenModalService} from "@shared/services/open-modal.service";
import {ConfirmModalComponent} from "@shared/components/confirm-modal/confirm-modal.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss']
})
export class FilterPanelComponent implements OnInit {

  @Input() hasFilters: boolean = false; // флаг наличия выбранных фильтров
  @Input() openedFilter: boolean = false; // флаг открытой/закрытой панели фильтров
  @Input() nearGrid: boolean = false; // флаг отображения рядом с гридом (пример Администрирование - Расписание запуска задач)
  @Input() asDropdown: boolean = false; // флаг отображения в виде выпадающего меню
  @Output() removeAll: EventEmitter<boolean> = new EventEmitter<boolean>(); // события очистки всех фильтров

  public enableClickOutside: boolean = false;
  private confirmRemove: string = '';
  private confirmApplyRemove: string = '';
  private confirmCancelRemove: string = '';

  constructor(
      private modalService: OpenModalService,
      private translateService: TranslateService
  ) {
    this.translateService.get(['GENERAL']).subscribe(result => {
      this.confirmRemove = result['GENERAL']['FILTER_CLEAR_QUESTION'];
      this.confirmApplyRemove = result['GENERAL']['CLEAR'];
      this.confirmCancelRemove = result['GENERAL']['CANCEL'];
    })
  }

  ngOnInit() {
  }

  public toggleFilter(open: boolean) {
    this.openedFilter = open;
    this.enableClickOutside = open;
  }

  public closeFilter(event, open: boolean) {
    if (this.asDropdown) {
      this.enableClickOutside = open;
      this.openedFilter = open;
    }
  }

  public remove() {
    this.modalService.show({
      component: ConfirmModalComponent,
      data: {
        question: this.confirmRemove,
        applyTitle: this.confirmApplyRemove,
        cancelTitle: this.confirmCancelRemove,
        onApply: () => {
          this.removeAll.emit();
        },
      },
      options: {
        centered: true,
        windowClass: 'modal-confirm',
      },
      callbacks: {
        Enter: 'apply',
        Escape: 'cancel'
      }
    });
  }

}

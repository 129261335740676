import { Options } from "@angular-slider/ngx-slider";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ngDebounce } from "@app/shared/decorators/ng-debounce.decorator";
import { DateFormat } from "@app/shared/services/date-time.service";
import { TranslateService } from "@ngx-translate/core";
import * as dayjs from 'dayjs';
import { IBaseCalendarProperties } from "../../base-components/base-calendar.component";
import { SelectFieldComponent } from "../../select-field/select-field.component";
import { CalendarWithApplySupportComponent } from "../calendar-with-apply/calendar-with-apply-support.component";

@Component({
    selector: 'app-time-select',
    templateUrl: './time-select.component.html',
    styleUrls: ['./time-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeSelectComponent),
            multi: true
        },
        {
            provide: CalendarWithApplySupportComponent,
            useExisting: forwardRef(() => TimeSelectComponent)
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeSelectComponent
    extends CalendarWithApplySupportComponent<string>
    implements ControlValueAccessor,
    IBaseCalendarProperties<string> {

    private readonly defaultSelected: string = '00:00:00';
    private readonly defaultSelectedPart: string = '00';
    public readonly hourSliderOptions: Options = {
        floor: 0,
        ceil: 23,
        showTicks: true,
        showSelectionBar: true,
        showTicksValues: true,
        ticksArray: [0, 6, 12, 18, 23]
    };
    public readonly minuteSliderOptions: Options = {
        ...this.hourSliderOptions,
        ceil: 59,
        ticksArray: [0, 10, 20, 30, 40, 50, 59]
    };
    public readonly secondSliderOptions: Options = {
        ...this.minuteSliderOptions
    };
    // IE11 fix
    public readonly manualRefresh: EventEmitter<void> = new EventEmitter<void>();

    /**
     * @inheritdoc
     * @default true
     */
    @Input() withMinutes: boolean = true;
    /**
     * @inheritdoc
     * @default false
     */
    @Input() withSeconds: boolean = false;
    /**
     * @inheritdoc
     * @default false
     */
    @Input() forCalendar: boolean = false;
    /**
     * @inheritdoc
     * @default true
     */
    @Input() withHover: boolean = true;
    /**
     * Плейсхолдер
     * @default DATE.TIME
     */
    @Input() placeholder: string = this.translateService.instant('DATE.TIME');

    public hours: string = null;
    public minutes: string = null;
    public seconds: string = null;

    @ViewChild(SelectFieldComponent, { read: ElementRef }) selectFieldComponent: ElementRef;

    constructor(
        private translateService: TranslateService
    ) {
        super();
    }

    /**
     * Установить значения часов, минут, секунд.
     * @param value
     */
    private setInnerValues(value: string) {
        this.hours = value && value.split(':')[0] ? value.split(':')[0] : this.defaultSelectedPart;
        this.minutes = this.selected && this.selected.split(':')[1] ? this.selected.split(':')[1] : this.defaultSelectedPart;
        this.seconds = this.selected && this.selected.split(':')[2] ? this.selected.split(':')[2] : this.defaultSelectedPart;
    }

    /**
     * Коллбек изменения через поле ввода.
     * @param value значение.
     */
    onChangeTime(value: string) {
        this.selected = value;
        this.setInnerValues(this.selected);
        this.emitChanges(this.selected);
    }

    /**
     * Коллбек изменения через слайдеры.
     * @param unit единица измерения.
     * @param value значение.
     */
    onChangeSlider(unit: dayjs.UnitType, value: number) {
        this.selected = dayjs(
            this.selected ? this.selected : this.defaultSelected,
            this.withSeconds
                ? DateFormat.TIME_WITH_SECONDS
                : DateFormat.TIME
        )
            .set(unit, value)
            .format(this.withSeconds
                ? DateFormat.TIME_WITH_SECONDS
                : DateFormat.TIME
            );
        this.setInnerValues(this.selected);
        this.emitChanges(this.selected);
    }

    /**
     * @inheritdoc
     */
    public writeValue(value: string): void {
        this.selected = value;
        this.setInnerValues(this.selected);
    }

    public toggleOpen() {
      super.toggleOpen();
      // IE11 fix
      setTimeout(() => this.manualRefresh.emit());
    }

    /**
     * @inheritdoc
     * С таймером для предотвращения слищком
     * частых событий при перемещении слайдеров.
     */
    @ngDebounce(100)
    public emitChanges(value: string): void {
        super.emitChanges(value);
    }
}

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ISelectItem } from "@app/core/interfaces/select-item";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-select-field',
    templateUrl: './select-field.component.html',
    styleUrls: ['./select-field.component.scss']
})
export class SelectFieldComponent {

    /**
     * ID поля выбора
     */
    @Input() fieldId: string;
    /**
     * Флаг открытия элемента
     * @default false
     */
    @Input() opened: boolean = false;
    /**
     * флаг стиля селекта - для панели фильтров или для полей
     * @default false
     */
    @Input() forFilter: boolean = false;
    /**
     * кол-во отображаемых выбранных значений для панели фильтров
     * @default 1
     */
    @Input() countShowValuesForFilter: number = 1;
    /**
     * Отображение со стрелкой.
     * Игнорируется при forFilter=true
     * @default false
     */
    @Input() withStr: boolean = false;
    /**
     * флаг отображения иконки сброса значения
     * @default false
     */
    @Input() withRemove = false;
    /**
     * Текст при отсуствии выбранного значения (placeholder)
     */
    @Input() title: string = this.translateService.instant('GENERAL.SELECT');
    /**
     * Режим выбора элементов
     * @default false
     */
    @Input() multiSelect: boolean = false;
    /**
     * Флаг блокировки
     * @default false
     */
    @Input() disabled: boolean = false;
    /**
     * Тултип блокировки
     */
    @Input() disabledTooltip: string[] = null;
    /**
     * Флаг отображения для добавления записи
     */
    @Input() forAddRow: boolean = false;
    /**
     * Флаг отображения для добавления записи с +
     */
    @Input() withPlus: boolean = false;
    /**
     * Флаг отображения иконки блокировки
     * @default false
     */
    @Input() withLockIcon: boolean = false;
    /**
     * Флаг сортировки выбранных значений по алфавиту
     * (отображение выбора)
     * @default false
     */
    @Input() withSortingValues: boolean = false;
    /**
     * Выбранные значения
     */
    @Input('selected') set _selected(value: ISelectItem | ISelectItem[]) {
        if (!value) {
            this.selected = [];
            this.selectedValues = [];
        }
        else {
            this.selected = Array.isArray(value)
                ? value
                : [value];
            this.selectedValues = this.selected.map(this.displaySelectionGetter);
            if (this.withSortingValues) {
                this.selectedValues = this.selectedValues.sort((a, b) => a.toString().localeCompare(b.toString(), 'string', { numeric: true }));
            }
        }
    }
    public selected: ISelectItem[];
    public selectedValues: Array<number | string>;
    /**
     * Функция получения значений для отображения на шаблоне (поле).
     * @default item => item.value;
     */
    @Input() displaySelectionGetter: (item: ISelectItem) => any = item => item.value;
    /**
     * Событие клика на элемент.
     */
    @Output() readonly OnClick = new EventEmitter<Event>();
    /**
     * Событие удаления одного элемента.
     */
    @Output() readonly OnRemove = new EventEmitter<ISelectItem>();
    /**
     * Событие очистки выбора.
     */
    @Output() readonly OnClear = new EventEmitter<void>();

    /**
     * Ссылка на div стрелки для привязки попапов.
     */
    @ViewChild('arrowDiv', { read: ElementRef }) public readonly arrowDiv: ElementRef;
    /**
     * Ссылка на div поля, для привязки попапов.
     */
    @ViewChild('valueDiv', { read: ElementRef }) public readonly valueDiv: ElementRef;

    constructor(
        private translateService: TranslateService
    ) { }

    public click($event: Event) {
        if (this.withStr && !($event.target as HTMLElement).className.includes('font-icons icon-arrow_down')) {
            $event.preventDefault();
            $event.stopPropagation();
            return;
        }
        this.OnClick.emit($event)
    }

    public remove(item: ISelectItem) {
        this.OnRemove.emit(item);
    }

    public clear() {
        this.OnClear.emit();
    }
}

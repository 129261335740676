import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import * as dayjs from 'dayjs';
import * as dayjsFormats from 'dayjs/plugin/customParseFormat';
import * as dayjsArraySupport from 'dayjs/plugin/arraySupport';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateFormat, DateTimeService } from '@app/shared/services/date-time.service';
import { IPopupContainerCommonProperties } from '../../popup-container/components/popup-container-base/popup-container-base';
import { BaseCalendarComponent, IBaseCalendarProperties } from '../../base-components/base-calendar.component';
import { IDayMonthYear, IMonthYear } from '@app/core/interfaces/select-item';
import { IRangeDate, IWeekDay, TDayInRange } from '@app/core/interfaces/calendar';
dayjs.extend(dayjsFormats);
dayjs.extend(dayjsArraySupport);

@Component({
  selector: 'app-calendar-month',
  templateUrl: './calendar-month.component.html',
  styleUrls: ['./calendar-month.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarMonthComponent),
      multi: true
    }
  ]
})
export class CalendarMonthComponent
  extends BaseCalendarComponent<string>
  implements OnChanges,
  IBaseCalendarProperties<string>,
  IPopupContainerCommonProperties {

  public readonly dateFormat = DateFormat.DATE;
  public readonly timeFormat = DateFormat.TIME_WITH_SECONDS;
  private readonly currentDay = this.currentMoment.date();
  private readonly currentYear = this.currentMoment.year();
  private readonly currentMonth = this.currentMoment.month() + 1;
  private get defaultSelectedDate(): IDayMonthYear {
    return {
      day: this.currentDay,
      month: this.currentMonth,
      year: this.currentYear
    };
  }
  private get defaultSelectedTime(): string {
    return this.format && this.format.includes(DateFormat.TIME_WITH_SECONDS)
      ? '00:00:00'
      : '00:00';
  }

  /**
   * Массив календаря.
   */
  public weeks: Array<IWeekDay[]> = [];
  public readonly daysOfWeek: Array<{ number: number, name: string }> = [
    { number: 1, name: 'пн' },
    { number: 2, name: 'вт' },
    { number: 3, name: 'ср' },
    { number: 4, name: 'чт' },
    { number: 5, name: 'пт' },
    { number: 6, name: 'сб' },
    { number: 0, name: 'вс' }
  ];

  /**
   * Выбнанная дата.
   */
  public selectedDate: IDayMonthYear = this.defaultSelectedDate;
  /**
   * Выбранное время.
   */
  public selectedTime: string = this.defaultSelectedTime;

  public selectedMoment: dayjs.Dayjs = dayjs([this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day]);

  public isMinMonth: boolean = false;
  public isMaxMonth: boolean = false;

  private minMoment: dayjs.Dayjs = null;
  private maxMoment: dayjs.Dayjs = null;

  /**
   * @inheritdoc
   * @default DateFormat.DATE
   */
  @Input() format: DateFormat = this.dateFormat;
  /**
   * Флаг отображения времени.
   */
  @Input() withTime: boolean = false;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withMinutes: boolean = true;
  /**
   * @inheritdoc
   * @default false
   */
  @Input() withSeconds: boolean = false;
  /**
   * Флаг отображения для начала периода.
   */
  @Input() asStartRange: boolean = false;
  /**
   * Флаг отображения для конца периода.
   */
  @Input() asEndRange: boolean = false;
  /**
   * Минимальная дата в формате DateFormat.DATE.
   */
  @Input() minDate: string = null;
  /**
   * Максимальная дата в формате DateFormat.DATE.
   */
  @Input() maxDate: string = null;
  /**
   * @inheritdoc
   * @default 1
   */
  @Input() minMonth: number = 1;
  /**
   * @inheritdoc
   * @default текущий месяц.
   */
  @Input() maxMonth: number = this.currentMonth;
  /**
   * Текущий выбранный период.
   * Используется для подсветки выбранных дней в периоде.
   */
  @Input() selectedRange: IRangeDate = null;

  constructor(
    private dateTimeService: DateTimeService
  ) {
    super();
  }

  ngOnChanges() {
    if (!this.selected) {
      this.selectedDate = this.defaultSelectedDate;
      this.selectedTime = this.defaultSelectedTime;
    } else {
      this.selectedDate = this.dateTimeService.getDayMonthYearFromString(this.selected, this.format);
      this.selectedTime = this.dateTimeService.getTimeStringFromString(
        this.selected,
        this.format,
        this.format.includes(DateFormat.TIME_WITH_SECONDS)
          ? DateFormat.TIME_WITH_SECONDS
          : DateFormat.TIME
      );
    }
    this.selectedMoment = dayjs([this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day]);
    this.setMinMaxMonth();
    this.makeCalendar();
  }

  private setMinMaxMonth() {
    this.isMinMonth = this.isNeedLimit && this.selectedDate.month === this.minMonth && this.selectedDate.year == this.minYear;
    this.isMaxMonth = this.isNeedLimit && this.selectedDate.month === this.maxMonth && this.selectedDate.year == this.maxYear;
    this.minMoment = this.isNeedLimit && this.minDate ? dayjs(this.minDate, this.dateFormat) : null;
    this.maxMoment = this.isNeedLimit && this.maxDate ? dayjs(this.maxDate, this.dateFormat) : null;
  }

  private prepareValue(date: IDayMonthYear, time: string) {
    const dateString = this.dateTimeService.getStringFromDayMonthYear(date);
    return this.withTime
      ? `${dateString} ${time}`
      : dateString;
  }

  public onChangeTime(value: string) {
    this.selectedTime = value;
    this.emitChanges(
      this.prepareValue(this.selectedDate, this.selectedTime)
    );
  }

  public onChangeMonth(value: IMonthYear) {
    const daysInMonth = this.dateTimeService.getDaysInMonthCount(value);
    if (this.selectedDate && (daysInMonth < this.selectedDate.day)) {
      this.selectedDate.day = daysInMonth;
    }
    this.selectedDate.month = value.month;
    this.selectedDate.year = value.year;
    this.selectedMoment = dayjs([this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day]);
    if (this.minMoment && this.minMoment.isAfter(this.selectedMoment)) {
      this.selectedMoment = this.minMoment.clone();
    }
    if (this.maxMoment && this.maxMoment.isBefore(this.selectedMoment)) {
      this.selectedMoment = this.maxMoment.clone();
    }
    this.selectedDate = {
      year: this.selectedMoment.year(),
      month: this.selectedMoment.month() + 1,
      day: this.selectedMoment.date()
    };
    this.emitChanges(
      this.prepareValue(this.selectedDate, this.selectedTime)
    );
    this.setMinMaxMonth();
    this.makeCalendar();
  }

  public onChangeDay(day: IWeekDay) {
    const disabled = day.disabled ||
      (day.monthState == 'next' && this.isMaxMonth)
      || (day.monthState == 'prev' && this.isMinMonth);
    if (disabled === true) {
      return;
    }
    this.selectedMoment = day.moment.clone();
    this.selectedDate.day = day.date;

    if (day.monthState == 'current') {
      this.emitChanges(
        this.prepareValue(this.selectedDate, this.selectedTime)
      );
    } else {
      this.onChangeMonth({ month: this.selectedMoment.month() + 1, year: this.selectedMoment.year() });
    }
  }

  private makeCalendar() {
    let start = this.selectedMoment.clone().startOf('month');
    const end = this.selectedMoment.clone().endOf('month');
    const countOfWeekday: number = 7;
    this.weeks = [];

    const selectedStart = this.selectedRange && this.selectedRange.start
      ? dayjs(this.selectedRange.start, this.format)
      : null;
    const selectedEnd = this.selectedRange && this.selectedRange.end
      ? dayjs(this.selectedRange.end, this.format)
      : null;

    while (start.day() !== 1) {
      start = start.subtract(1, 'day');
    }

    while (start.isSameOrBefore(end, 'day')) {
      const week: IWeekDay[] = [];
      for (let i = 1; i <= countOfWeekday; i++) {
        let monthState: 'current' | 'prev' | 'next' = 'current';
        if (start.month() > this.selectedMoment.month() || start.year() > this.selectedMoment.year()) {
          monthState = 'next';
        }
        if (start.month() < this.selectedMoment.month() || start.year() < this.selectedMoment.year()) {
          monthState = 'prev';
        }
        week.push({
          date: start.date(),
          monthState,
          isToday: start.isSame(this.currentMoment, 'day'),
          moment: start.clone(),
          disabled: this.isNeedLimit
            && (this.minMoment && start.isBefore(this.minMoment))
            || (this.maxMoment && start.isAfter(this.maxMoment)),
          inRange: this.isDayInRange(start, selectedStart, selectedEnd)
        });
        start = start.add(1, 'days');
      }
      this.weeks.push(week);
    }
  }

  private isDayInRange(
    day: dayjs.Dayjs,
    selectedStart: dayjs.Dayjs,
    selectedEnd: dayjs.Dayjs,
  ): TDayInRange {
    if (!selectedStart || !selectedEnd) {
      return false;
    }
    if (day.isSame(selectedStart, 'day')) {
      return 'first';
    }
    if (day.isSame(selectedEnd, 'day')) {
      return 'last';
    }
    return day.isSameOrAfter(selectedStart, 'day') && day.isSameOrBefore(selectedEnd, 'day');
  }

  emitChanges(value: string) {
    if (this.onChange) {
      this.onChange(value);
    }
    this.OnSelect.next(value);
  }

  writeValue(value: string) {
    this.selected = value;
  }
}

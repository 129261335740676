import {
    AfterViewInit,
    ComponentRef,
    Directive,
    ElementRef,
    Host,
    Inject,
    Input,
    OnDestroy,
    Optional,
    SkipSelf
} from "@angular/core";
import { animationFrameScheduler, BehaviorSubject, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { PopupContainerBase } from "../components/popup-container-base/popup-container-base";
import { PopupContainerComponent } from "../components/popup-container/popup-container.component";
import { PopupContainerService } from "../services/popup-container.service";

/**
 * Директива для отображения попапа в body
 * @param popupContainer ng-template шаблон попапа
 */
@Directive({
    selector: '[popupContainer]',
    providers: [PopupContainerService]
})
export class PopupContainerDirective
    extends PopupContainerBase
    implements AfterViewInit, OnDestroy {

    /**
     * Флаг отображения попапа
     * @default true
     */
    @Input() set popupContainerShow(value: boolean) {
        this.popupContainerShow$.next(value);
    }
    protected readonly popupContainerShow$ = new BehaviorSubject<boolean>(true);
    /**
     * Флаг отключения попапа.
     */
    @Input() popupContainerDisabled: boolean = false;
    /**
     * Геттер основного DOM элемент компонента попапа
     */
    protected get popup(): HTMLElement {
        return this.componentRef && (this.componentRef as any).hostView && (this.componentRef as any).hostView.rootNodes ? (this.componentRef as any).hostView.rootNodes[0] : null;
    }
    /**
     * Ссылка на компоннент попапа
     */
    protected componentRef: ComponentRef<PopupContainerComponent> = null;
    /**
     * Поток уничтожения компонента.
     * Next и complete d ngOnDestroy
     */
    protected readonly destroy$ = new Subject<void>();

    constructor(
        protected popupContainerService: PopupContainerService,
        @Host()
        protected host: ElementRef,
        @SkipSelf()
        @Inject(PopupContainerDirective)
        @Optional()
        protected parent?: PopupContainerDirective
    ) {
        super();
        this.popupContainerTarget = host.nativeElement;
    }

    ngAfterViewInit(): void {
        this.popupContainerShow$
            .pipe(
                debounceTime(17, animationFrameScheduler),
                distinctUntilChanged(),
                takeUntil(this.destroy$)
            )
            .subscribe(show => this.onShowChanged(show));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.onShowChanged(false);
    }

    /**
     * Коллбек на изменение состояния отображения попапа
     * @param show флаг отображения
     */
    protected onShowChanged(show: boolean): void {
        if (show) {
            this.componentRef = this.createComponent();
        }
        else {
            this.componentRef = null;
            this.deleteComponent();
        }
    }

    /**
     * Метод создания компонента попапа.
     * @param params свойства {@link PopupContainerComponent},
     * которые необходимо передать.
     * @default все инпуты {@link PopupContainerBase}
     */
    protected createComponent(
        params?: Partial<PopupContainerComponent>
    ): ComponentRef<PopupContainerComponent> {
        if (this.popupContainerDisabled) {
            return;
        }
        return this.popupContainerService.createPopup({
            popupContainer: this.popupContainer,
            popupContainerTarget: this.popupContainerTarget,
            popupContainerEventTarget: this.popupContainerEventTarget,
            popupContainerEventsList: this.popupContainerEventsList,
            popupContainerOpenDirection: this.popupContainerOpenDirection,
            popupContainerZIndex: this.popupContainerZIndex,
            popupContainerEventsThrottle: this.popupContainerEventsThrottle,
            popupContainerOffsetTop: this.popupContainerOffsetTop,
            popupContainerOffsetLeft: this.popupContainerOffsetLeft,
            popupContainerPreventOutsideWindow: this.popupContainerPreventOutsideWindow,
            popupContainerClass: this.popupContainerClass,
            popupContainerCssProperties: this.popupContainerCssProperties,
            popupContainerOffsetRelative: this.popupContainerOffsetRelative,
            parent: this.parent,
            ...params
        });
    }

    /**
     * Метод удаления компонента попапа
     */
    protected deleteComponent() {
        this.popupContainerService.deletePopup();
    }
}

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd, Route} from "@angular/router";
import {filter} from "rxjs/operators";
import {IBreadcrum} from "@core/interfaces/breadcrum";
import {ISelectItem} from "@core/interfaces/select-item";

@Component({
  selector: 'app-breadcrums',
  templateUrl: './breadcrums.component.html',
  styleUrls: ['./breadcrums.component.scss']
})
export class BreadcrumsComponent implements OnInit {

  public breadcrums: IBreadcrum[] = [];
  public list: ISelectItem[] = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrums = this.createBreadcrumbs(this.activatedRoute.root);
      });
  }

  ngOnInit() {

  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrum[] = []) {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      const items = route.snapshot.parent && route.snapshot.parent.routeConfig
        && route.snapshot.parent.routeConfig.children && route.snapshot.parent.routeConfig.children.length > 0
        ? route.snapshot.parent.routeConfig.children.filter((item: Route) => item.path && item.path !== '')
        : null;
      if (items && items.length > 1 && route.snapshot.routeConfig.path !== '') {
        const parentUrl = breadcrumbs[0].url;
        this.list = items.map((item) => {
          return {
            key: parentUrl + `/${item.path}`,
            value: item.data['breadcrumb']
          }
        });
      } else {
        this.list = [];
      }
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data['breadcrumb'];
      const withComponent = !!child.component;
      if (!!label) {
        breadcrumbs.push({label, url, withComponent});
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  public changePage(item: ISelectItem) {
    this.router.navigate([item.key]);
  }

}

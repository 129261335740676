import { TrimOnBlurDirective } from "./directives/trim-on-blur.directive";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { ToastrModule } from "ngx-toastr";
import { ClickOutsideModule } from "ng-click-outside";
import { ToastrComponent } from "@shared/components/toastr/toastr.component";
import { TextMaskModule } from "angular2-text-mask";
import { ContextMenuModule } from "ngx-contextmenu";
import { PageNotFoundComponent } from "@shared/components/page-not-found/page-not-found.component";
import { LoaderComponent } from "@shared/components/loader/loader.component";
import { ConfirmModalComponent } from "@shared/components/confirm-modal/confirm-modal.component";
import { NoAccessComponent } from "@shared/components/no-access/no-access.component";
import { PasswordInputComponent } from "@shared/components/password-input/password-input.component";
import { CheckboxComponent } from "@shared/components/checkbox/checkbox.component";
import { SwitchComponent } from "@shared/components/switch/switch.component";
import { TabsComponent } from "@shared/components/tabs/tabs.component";
import { RadioboxListComponent } from "@shared/components/radiobox-list/radiobox-list.component";
import { SearchFieldComponent } from "@shared/components/search-field/search-field.component";
import { FilterPanelComponent } from "@shared/components/filter-panel/filter-panel.component";
import { CheckboxListComponent } from "@shared/components/checkbox-list/checkbox-list.component";
import { AgGridPaginationComponent } from "@shared/components/ag-grid-pagination/ag-grid-pagination.component";
import { TextareaComponent } from "@shared/components/textarea/textarea.component";
import { FilterPanelToggleBtnComponent } from "@shared/components/filter-panel/filter-panel-toggle-btn/filter-panel-toggle-btn.component";
import { ActionMenuComponent } from "@shared/components/action-menu/action-menu.component";
import { EventLogCardComponent } from "@shared/components/event-log-card/event-log-card.component";
import { AccordionItemComponent } from "@shared/components/accordion-item/accordion-item.component";
import { MultiSelectComponent } from "@shared/components/multi-select/multi-select.component";
import { QuarterSelectComponent } from "@shared/components/quarter-select/quarter-select.component";
import { SimpleSelectComponent } from "@shared/components/simple-select/simple-select.component";
import { ChangePasswordModalComponent } from "@shared/components/change-password-modal/change-password-modal.component";
import { HeaderSupportComponent } from "@shared/components/header-support/header-support.component";
import { HeaderComponent } from "@shared/components/header/header.component";
import { BreadcrumsComponent } from "@shared/components/breadcrums/breadcrums.component";
import { MenuComponent } from "@shared/components/menu/menu.component";
import { ChangePasswordFormComponent } from "@shared/components/change-password-modal/change-password-form/change-password-form.component";
import { NoSelectStubComponent } from "@shared/components/no-select-stub/no-select-stub.component";
import { TemporaryPasswordModalComponent } from "@shared/components/temporary-password-modal/temporary-password-modal.component";
import { MultiDictionaryModalComponent } from "@shared/components/multi-dictionary/multi-dictionary-modal/multi-dictionary-modal.component";
import { MultiDictionaryComponent } from "@shared/components/multi-dictionary/multi-dictionary.component";
import { RestoreAccessFormComponent } from "@shared/components/restore-access-form/restore-access-form.component";
import { ExitComponent } from "@shared/components/exit/exit.component";
import { OpenModalService } from "@shared/services/open-modal.service";
import { DisableControlDirective } from "./directives/disable-control.directive";
import { PageWrongComponent } from "@shared/components/page-wrong/page-wrong.component";
import { SimpleDictionaryComponent } from "@shared/components/simple-dictionary/simple-dictionary.component";
import { SimpleDictionaryModalComponent } from "@shared/components/simple-dictionary/simple-dictionary-modal/simple-dictionary-modal.component";
import { ExtendFilterPanelComponent } from "@shared/components/extend-filters/extend-filter-panel/extend-filter-panel.component";
import { ExtendFiltersComponent } from "@shared/components/extend-filters/extend-filters.component";
import { CalendarModule } from "./components/calendar/calendar.module";
import { ButtonModule } from "./components/button/button.module";
import { InputModule } from "./components/input/input.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    TranslateModule,
    RouterModule,
    NgbModule,
    ContextMenuModule,
    ClickOutsideModule,
    ToastrModule.forRoot({
      toastComponent: ToastrComponent,
      positionClass: "toast-bottom-right",
      iconClasses: {
        success: "font-icons icon-info",
        error: "font-icons icon-error",
      },
      enableHtml: true,
      closeButton: true,
      timeOut: 15000,
      tapToDismiss: false,
    }),
    CalendarModule,
    ButtonModule,
    InputModule,
  ],
  providers: [OpenModalService],
  declarations: [
    PageNotFoundComponent,
    TextareaComponent,
    LoaderComponent,
    ToastrComponent,
    ConfirmModalComponent,
    NoAccessComponent,
    PasswordInputComponent,
    CheckboxComponent,
    CheckboxListComponent,
    SwitchComponent,
    TabsComponent,
    RadioboxListComponent,
    SearchFieldComponent,
    FilterPanelComponent,
    FilterPanelToggleBtnComponent,
    AgGridPaginationComponent,
    PageWrongComponent,
    ActionMenuComponent,
    EventLogCardComponent,
    AccordionItemComponent,
    MultiSelectComponent,
    QuarterSelectComponent,
    SimpleSelectComponent,
    ChangePasswordModalComponent,
    HeaderSupportComponent,
    HeaderComponent,
    BreadcrumsComponent,
    MenuComponent,
    ChangePasswordFormComponent,
    NoSelectStubComponent,
    TemporaryPasswordModalComponent,
    MultiDictionaryModalComponent,
    MultiDictionaryComponent,
    RestoreAccessFormComponent,
    ExitComponent,
    DisableControlDirective,
    TrimOnBlurDirective,
    SimpleDictionaryComponent,
    SimpleDictionaryModalComponent,
    ExtendFilterPanelComponent,
    ExtendFiltersComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    TranslateModule,
    RouterModule,
    NgbModule,
    ContextMenuModule,
    ClickOutsideModule,
    ToastrComponent,
    ContextMenuModule,
    PageNotFoundComponent,
    NoAccessComponent,
    LoaderComponent,
    PasswordInputComponent,
    CheckboxComponent,
    SwitchComponent,
    TabsComponent,
    ConfirmModalComponent,
    RadioboxListComponent,
    SearchFieldComponent,
    FilterPanelComponent,
    CheckboxListComponent,
    AgGridPaginationComponent,
    TextareaComponent,
    PageWrongComponent,
    FilterPanelToggleBtnComponent,
    ActionMenuComponent,
    AccordionItemComponent,
    MultiSelectComponent,
    QuarterSelectComponent,
    SimpleSelectComponent,
    ChangePasswordModalComponent,
    HeaderSupportComponent,
    HeaderComponent,
    BreadcrumsComponent,
    MenuComponent,
    ChangePasswordFormComponent,
    NoSelectStubComponent,
    TemporaryPasswordModalComponent,
    MultiDictionaryModalComponent,
    MultiDictionaryComponent,
    RestoreAccessFormComponent,
    ExitComponent,
    DisableControlDirective,
    TrimOnBlurDirective,
    SimpleDictionaryComponent,
    SimpleDictionaryModalComponent,
    ExtendFilterPanelComponent,
    ExtendFiltersComponent,
    CalendarModule,
    ButtonModule,
    InputModule,
  ],
  entryComponents: [
    ToastrComponent,
    TabsComponent,
    ConfirmModalComponent,
    EventLogCardComponent,
    ChangePasswordModalComponent,
    TemporaryPasswordModalComponent,
    MultiDictionaryModalComponent,
    RestoreAccessFormComponent,
    SimpleDictionaryModalComponent,
    ExtendFilterPanelComponent,
  ],
})
export class SharedModule {}

import { AfterViewInit, ContentChild, Directive, ElementRef, Host, Inject, Input, OnDestroy, OnInit, Optional, SkipSelf } from "@angular/core";
import { animationFrameScheduler, BehaviorSubject, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, takeUntil } from "rxjs/operators";
import { CalendarWithApplySupportComponent } from "./calendar-with-apply-support.component";
import { CalendarWithApplyComponent } from "./calendar-with-apply.component";
import * as cloneDeep from 'lodash.clonedeep';

@Directive({
    selector: '[calendarWithApply]'
})
export class CalendarWithApplyDirective<T>
    implements OnInit, AfterViewInit, OnDestroy {

    /**
     * Флаг включения calendarWithApply.
     * @default true
     */
    @Input() calendarWithApplyEnabled: boolean = true;
    /**
     * Селектор родительского элемента,
     * который также может использовать calendarWithApply.
     * Директива будет применена только если
     * к родительскому элементу она также применена.
     * @example <app-month-select>
     *              ...
     *              <app-year-select
     *                  ...
     *                  calendarWithApply
     *                  [calendarWithApplyParentSelector]="'app-month-select'">
     *                      <app-calendar-with-apply></app-calendar-with-apply>
     *              </app-year-select>
     *          </app-month-select>
     */
    @Input() calendarWithApplyParentSelector: string = null;
    /**
     * Компонент применения.
     * Для случаев применения директивы
     * на компонент без ng-content.
     */
    @Input() calendarWithApplyComponent: CalendarWithApplyComponent = null;
    /**
     * Компонент применения.
     * Для случаев применения директивы
     * на компонент с ng-content.
     */
    @ContentChild(CalendarWithApplyComponent) contentApplyComponent: CalendarWithApplyComponent;
    private get applyComponent(): CalendarWithApplyComponent {
        return this.calendarWithApplyComponent || this.contentApplyComponent;
    }

    private readonly originalEmitChanges = this.component.emitChanges.bind(this.component);
    private readonly originalWriteValue = this.component.writeValue.bind(this.component);
    private readonly originalArrowClick = this.component.arrowClick ? this.component.arrowClick.bind(this.component) : null;
    private readonly originalClear = this.component.clear ? this.component.clear.bind(this.component) : null;

    private readonly _currentValue = new BehaviorSubject<T>(null);
    private set currentValue(value: T) {
        this._currentValue.next(
            cloneDeep(value)
        );
    }
    private get currentValue(): T {
        return cloneDeep(this._currentValue.getValue());
    }

    private readonly destroy$ = new Subject<void>();

    /**
     * Флаг активности директивы.
     */
    private get isActive(): boolean {
        return this.calendarWithApplyEnabled &&
            (
                !this.calendarWithApplyParentSelector ||
                (
                  this.parent && this.parent.host && this.parent.host.nativeElement &&
                  this.parent.host.nativeElement.tagName === this.calendarWithApplyParentSelector.toUpperCase()
                )
            );
    }

    constructor(
        /**
         * Компонент, к которому применяется директива.
         */
        private component: CalendarWithApplySupportComponent<T>,
        /**
         * Host элемент директивы.
         */
        @Host()
        private host: ElementRef,
        /**
         * Опциональная родительская директива.
         */
        @SkipSelf()
        @Inject(CalendarWithApplyDirective)
        @Optional()
        private parent?: CalendarWithApplyDirective<any>
    ) { }

    ngOnInit() {
        if (this.isActive) {
            this.component.writeValue = (value: T) => {
                this.originalWriteValue(value);
                this.currentValue = value;
            }
            this.component.emitChanges = value => {
                this.component.openedSelect$.next(true);
            };
            this.component.arrowClick = direction => {
                this.originalArrowClick(direction);
                this.onApply();
            }
            this.component.clear = (...args) => {
                this.originalClear(...args);
                this.onApply();
            }
            this.component.openedSelect$
                .pipe(
                    debounceTime(17, animationFrameScheduler),
                    distinctUntilChanged(),
                    filter(flag => !flag),
                    takeUntil(this.destroy$)
                ).subscribe(() => this.onCancel());
            this.currentValue = this.component.selected;
        }
    }

    ngAfterViewInit() {
        this.applyComponent.isVisible = this.isActive;
        this.applyComponent.OnApply
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.onApply());
        this.applyComponent.OnCancel
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.onCancel());
    }

    /**
     * Коллбек на подтверждение значения.
     */
    private onApply() {
        const selected = this.component.selected;
        this.currentValue = selected;
        this.originalEmitChanges(selected);
        if(this.parent) {
          this.parent.onApply();
        }
        this.component.openedSelect$.next(false);
    }

    /**
     * Коллбек на отмену значения.
     */
    private onCancel() {
        this.originalWriteValue(this.currentValue);
        this.component.openedSelect$.next(false);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

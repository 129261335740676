import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";
import {Location} from "@angular/common";
import {AuthService} from "@core/services/auth/auth.service";

@Injectable()
export class AuthorizationGuard implements CanActivateChild {

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService
  ) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const redirectUrl: string = route.queryParamMap.get('state') ? atob(route.queryParamMap.get('state')) : null;
    if (redirectUrl && redirectUrl !== '' && this.authService.isAuthorized()) {
      this.authService.refreshToken().subscribe(
        (res) => {
          document.location.href = redirectUrl;
          return false;
          }, (error) => {
          this.router.navigate(['/auth']);
          return false;
        });
    } else {
      return true;
    }
  }
}

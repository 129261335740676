
/**
 * Склоняет слово. Слова в массиве words указываются по следующей логике:
 * ['{*одно*} устройство', '{*два*} устройства', '{*много*} устройств']
 * @param value - число на основе которого производится склонение
 * @param words - массив слов. Например ['устройство', 'устройства', 'устройств']
 * @return Один из элементов "words"
 */
export function wordDeclination(
  value: number,
  words: string[]
): string {
  if (typeof value !== 'number') {
    console.error('"value" is not a number', value);
  }

  const wordList = words;
  const num = Math.abs(value) % 100;
  const sum = num % 10;

  if (num > 10 && num < 20) { return wordList[2]; }
  if (sum > 1 && sum < 5) { return wordList[1]; }
  if (sum === 1) { return  wordList[0]; }

  return wordList[2];
}

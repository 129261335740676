import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { PasswordValidator } from "@shared/validators/password.validator";
import { IValidator } from "@core/interfaces/validator";
import { ActivatedRoute, Router } from "@angular/router";
import { PasswordApiService } from "@core/services/api/password-api.service";
import { ToastrService } from "ngx-toastr";
import { EnvService } from "@core/services/env.service";
import { SYSTEM_PARAMETER_NAME } from "@app/app.enums";
import { SystemParamsService } from "@core/services/system-params.service";
import { finalize } from 'rxjs/operators';
import { ngThrottle } from '@app/shared/decorators/throttle.decorator';

@Component({
  selector: 'app-restore-access-form',
  templateUrl: './restore-access-form.component.html',
  styleUrls: ['./restore-access-form.component.scss']
})
export class RestoreAccessFormComponent {

  @Input() secretKey: string = null;

  public mainIconUrl: string = null;

  public pasMinLength: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_LENGTH)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_LENGTH)) : 8;
  public pasMinUpper: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_UPPERCASES)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_UPPERCASES))
    : 1;
  public pasMinLower: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_LOWERCASES)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_LOWERCASES)) : 1;
  public pasMinNumbers: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_NUMBERS)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_NUMBERS)) : 1;
  public pasMinSpec: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_SPECIAL_CHARACTERS)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_SPECIAL_CHARACTERS)) : 1;


  public form: FormGroup = new FormGroup({
    newPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(this.pasMinLength),
      PasswordValidator(this.pasMinUpper, this.pasMinLower, this.pasMinNumbers, this.pasMinSpec)
    ]),
    newPasswordAgain: new FormControl('', [
      Validators.required,
      Validators.minLength(this.pasMinLength),
      PasswordValidator(this.pasMinUpper, this.pasMinLower, this.pasMinNumbers, this.pasMinSpec)
    ])
  });

  public isLoading: boolean = false;
  public isNewPasswordError: boolean = false;
  public newPasswordResult: string = null;
  public isNewPasswordAgainError: boolean = false;
  public newPasswordAgainResult: string = null;
  public newPasswordTooltip: string[] = [];

  private successTitles: string[] = [];
  private errorTitles: string[] = [];
  private requirements: string = 'PASSWORD.REQUIREMENT';
  private errorRequest: string = '';

  constructor(
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private passwordApiService: PasswordApiService,
    private toastr: ToastrService,
    private router: Router,
    private systemParamsService: SystemParamsService,
    private envService: EnvService
  ) {
    this.mainIconUrl = this.envService.mainIcon;
    this.translateService.get(['PASSWORD', 'GENERAL.ERROR_REQUEST']).subscribe((result: string[]) => {
      this.successTitles = result['PASSWORD']['SUCCESS'];
      this.errorTitles = result['PASSWORD']['ERRORS'];
      this.errorRequest = result['GENERAL.ERROR_REQUEST'];
    });
    this.newPasswordTooltip = [
      this.translateService.instant(`${this.requirements}.NO_RUS`),
      this.translateService.instant(`${this.requirements}.LENGTH`, { value: this.pasMinLength }),
      this.translateService.instant(`${this.requirements}.CAPITAL`, { value: this.pasMinUpper }),
      this.translateService.instant(`${this.requirements}.SMALL`, { value: this.pasMinLower }),
      this.translateService.instant(`${this.requirements}.NUMBER`, { value: this.pasMinNumbers }),
      this.translateService.instant(`${this.requirements}.SPEC`, { value: this.pasMinSpec })
    ];
  }

  public restoreAccess() {
    const new_password = this.form.get('newPassword').value;
    const new_password_again = this.form.get('newPasswordAgain').value;
    if (this.form.valid && new_password_again === new_password) {
      this.resetPassword(new_password);
    } else {
      this.checkNewPassword();
      this.checkNewPasswordAgain();
    }
  }

  @ngThrottle()
  private resetPassword(newPassword: string) {
    this.isLoading = true;
    this.passwordApiService.resetPassword(newPassword, this.secretKey)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        () => {
          this.clear();
          this.toastr.success(this.successTitles['SAVE']);
          this.router.navigate(['/auth']);
        },
        error => {
          if (error.error && error.error['message']) {
            this.toastr.error(error.error['message'], this.errorTitles['SAVE']);
          } else {
            this.toastr.error(this.errorTitles['SAVE']);
          }
        });
  }

  private clear() {
    this.form.reset();
    this.isNewPasswordError = false;
    this.newPasswordResult = null;
    this.isNewPasswordAgainError = false;
    this.newPasswordAgainResult = null;
  }

  public changeField(value: any, field: string) {
    switch (field) {
      case 'newPassword': {
        this.checkNewPassword();
        if (this.form.controls.newPasswordAgain.touched) {
          this.checkNewPasswordAgain();
        }
        break;
      }
      case 'newPasswordAgain': {
        this.checkNewPasswordAgain();
        if (this.form.controls.newPassword.touched) {
          this.checkNewPassword();
        }
        break;
      }
    }
  }

  private checkNewPassword() {
    const new_password = this.form.get('newPassword').value;
    const errors = this.form.get('newPassword').errors;
    if (errors && errors['required']) {
      this.isNewPasswordError = true;
      this.newPasswordResult = this.errorTitles['REQUIRED'];
    } else if (errors && ((errors['password'] && errors['password'].length > 0) || errors['minlength'])) {
      this.isNewPasswordError = true;
      this.newPasswordResult = this.errorTitles['NO_CORRECT'];
    } else {
      this.isNewPasswordError = false;
      this.newPasswordResult = this.successTitles['CORRECT'];
    }
    this.prepareTooltip(errors);
  }

  private checkNewPasswordAgain() {
    const new_password = this.form.get('newPassword').value;
    const new_password_again = this.form.get('newPasswordAgain').value;
    const errors = this.form.get('newPasswordAgain').errors;
    if (errors && errors['required']) {
      this.isNewPasswordAgainError = true;
      this.newPasswordAgainResult = this.errorTitles['REQUIRED'];
    } else if (errors && ((errors['password'] && errors['password'].length > 0) || errors['minlength'])) {
      this.isNewPasswordAgainError = true;
      this.newPasswordAgainResult = this.errorTitles['NO_CORRECT'];
    } else if (new_password_again !== new_password) {
      this.isNewPasswordAgainError = true;
      this.newPasswordAgainResult = this.errorTitles['NO_CHECK'];
    } else {
      this.isNewPasswordAgainError = false;
      this.newPasswordAgainResult = this.successTitles['CHECK'];
    }
  }

  private prepareTooltip(errors: ValidationErrors) {
    this.newPasswordTooltip = [];
    if (errors && errors['minlength']) {
      this.newPasswordTooltip.push(
        this.translateService.instant(`${this.requirements}.LENGTH`, { value: this.pasMinLength })
      );
    }
    if (errors && errors['password'] && errors['password'].length > 0) {
      const passwordErrors = errors['password'];
      passwordErrors.forEach((error: IValidator) => {
        if (error['password-rus']) {
          this.newPasswordTooltip.push(
            this.translateService.instant(`${this.requirements}.NO_RUS`)
          );
        }
        if (error['password-AZ']) {
          this.newPasswordTooltip.push(
            this.translateService.instant(`${this.requirements}.CAPITAL`, { value: this.pasMinUpper })
          );
        }
        if (error['password-az']) {
          this.newPasswordTooltip.push(
            this.translateService.instant(`${this.requirements}.SMALL`, { value: this.pasMinLower })
          );
        }
        if (error['password-num']) {
          this.newPasswordTooltip.push(
            this.translateService.instant(`${this.requirements}.NUMBER`, { value: this.pasMinNumbers })
          );
        }
        if (error['password-spec']) {
          this.newPasswordTooltip.push(
            this.translateService.instant(`${this.requirements}.SPEC`, { value: this.pasMinSpec })
          );
        }
      });
    }
  }
}

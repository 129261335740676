import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { NoAccessComponent } from "@shared/components/no-access/no-access.component";
import { PageNotFoundComponent } from "@shared/components/page-not-found/page-not-found.component";
import { AuthGuard } from "@core/guards/auth-guard.service";
import { NgModule } from '@angular/core';
import { AdminAccessGuardService } from "@core/guards/admin-access-guard.service";
import { AuthorizationGuard } from "@core/guards/authorization-guard.service";
import { ExitComponent } from "@shared/components/exit/exit.component";
import { ExitGuard } from "@core/guards/exit-guard.service";
import {PageWrongComponent} from "@shared/components/page-wrong/page-wrong.component";

const appRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: './modules/main/main.module#MainModule'
  },
  {
    path: 'auth',
    canActivate: [AuthorizationGuard],
    loadChildren: './modules/auth/auth.module#AuthModule'
  },
  {
    path: 'restore',
    loadChildren: './modules/restore-access/restore-access.module#RestoreAccessModule'
  },
  {
    path: 'change-password',
    loadChildren: './modules/change-password/change-password.module#ChangePasswordModule'
  },
  {
    path: 'access-admin',
    canActivate: [AdminAccessGuardService],
    loadChildren: './modules/admin/admin.module#AdminModule'
  },
  { path: 'exit', component: ExitComponent, canActivate: [ExitGuard] },
  { path: 'not-access', component: NoAccessComponent },
  { path: 'page-wrong', component: PageWrongComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { preloadingStrategy: NoPreloading })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }


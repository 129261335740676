import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { LockEditableModule } from '../lock-editable/lock-editable.module';

@NgModule({
    declarations: [
        InputComponent
    ],
    imports: [
        CommonModule,
        TextMaskModule,
        FormsModule,
        ReactiveFormsModule,
        LockEditableModule
    ],
    exports: [
        InputComponent
    ]
})
export class InputModule { }

import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MenuService} from "@shared/services/menu.service";
import {IUserMenu} from "@core/interfaces/user";
import {Observable, Subscription} from "rxjs";
import {delay, filter} from "rxjs/operators";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {MENU} from "@app/app.enums";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  public menu: IUserMenu[] = [];
  public isOpenMenu: Observable<boolean> = this.menuService.getOpenedMenu().pipe(delay(0));
  public isMenuCollapseOpen: boolean = false;
  private subscriptions: Subscription[] = [];

  @ViewChild('menuList') menuList: any;

  constructor(
    private menuService: MenuService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetection: ChangeDetectorRef
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.menuService.setActiveUrl(this.activatedRoute['_routerState'].snapshot.url);
      });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.menuService.getUserMenu().subscribe((result: IUserMenu[]) => {
        this.menu = result;
        this.changeDetection.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this.changeDetection.detach();
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  public closeMenu() {
    this.menuService.setOpenedMenu(false);
  }

}

import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BaseFieldComponent } from './base-field.component';


export abstract class BaseInputComponent
    extends BaseFieldComponent
    implements ControlValueAccessor {

    /**
     * Плейсхолдер
     */
    @Input() placeholder: string = '';
    /**
     * Тултип блокировки
     */
    @Input() disabledTooltip: string[] = null;
    /**
     * Максимальное кол-во символов
     * @default '255'
     */
    @Input() maxLength: string | number = '255';
    /**
     * Минимальное кол-во символов
     * @default '0'
     */
    @Input() minLength: string | number = '0';
    /**
     * Тип удаления пробелов перед blur эвентом.
     * @default 'both' удаление пробелов в начале и в конце.
     */
    @Input() trimType: 'start' | 'end' | 'both' | 'none' = 'both';
    /**
     * autocomplete для <input>
     * (всплывающие подсказки от браузера)
     */
    @Input() autocomplete: string = 'off';
    /**
     * spellcheck для <input>
     * (подчеркивание "ошибок")
     */
    @Input() spellcheck: boolean = false;

    /**
     * Событие изменения значения
     */
    @Output() readonly OnChange = new EventEmitter<any>();

    /**
     * Геттер значения
     */
    abstract get model(): any;
    /**
     * Сеттер значения
     */
    abstract set model(value: any);

    /**
     * Функция отправки событий изменения значения.
     * Объединяет вызов onChange и OnChange.emit
     * @param value значение
     */
    emitChanges(value: any) {
        this.onChange(value);
        this.OnChange.emit(value);
    }

    /**
     * Функция отправки события blur
     * @param event событие
     */
    emitBlurEvent(event: any) {
        this.model = this.trim(this.model);
        super.emitBlurEvent(event);
    }

    protected trim(value: any): string {
        if (typeof value !== 'string') {
            return value;
        }
        switch (this.trimType) {
            case 'start': {
                return (value as any).trimLeft();
            }
            case 'end': {
                return (value as any).trimRight();
            }
            case 'both': {
                return value.trim();
            }
            default: {
                return value;
            }
        }
    }
}

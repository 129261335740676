import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private errorMessage: string = '';

  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService,
    private httpClient: HttpClient
  ) {
    this.translateService.get('GENERAL.ERROR_DOWNLOAD_FILE').subscribe(result => {
      this.errorMessage = result;
    })
  }

  public getPhoto(url, queryParams?): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(`${url}`,{
      observe: 'response',
      responseType: 'blob',
      params: queryParams
    });
  }

  public downloadFile(url: string, fileName: string, requestBody: any = null, withPost: boolean = false): void {
    const func$ = withPost ? this.getBlobFileWithPost(url, requestBody) : this.getBlobFile(url);
    func$.subscribe(
      data => this.downloadFileBlob(data, fileName),
      error => this.toastr.error(this.errorMessage)
    );
  }

  public getBlobFile(url, queryParams?): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(`${url}`, {
      observe: 'response',
      responseType: 'blob',
      params: queryParams
    });
  }

  public getBlobFileWithPost(url, requestBody, queryParams?): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(`${url}`, requestBody,{
      observe: 'response',
      responseType: 'blob',
      params: queryParams
    });
  }

  public downloadFileBlob(data, fileNameWithExtension: string) {
    const fileName = fileNameWithExtension ? fileNameWithExtension : this.getFileName(data.headers.get('Content-Disposition'));
    const fileNameRus = fileName ? decodeURIComponent(fileName) : null;
    //IE11 fix
    if ((navigator as any).msSaveOrOpenBlob) {
      (navigator as any).msSaveBlob(data.body, fileNameRus);
    } else {
      const downloadLink = document.createElement('a');
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      const url = window.URL.createObjectURL(data.body);
      downloadLink.setAttribute('href', url);
      downloadLink.setAttribute('download', fileNameRus ? fileNameRus : null); // при изменении имени заголовка, filename, в /download/{dataObjectUuid}. Нужно изменить заголовок и здесь
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
    }
  }

  private getFileName(value: string) {
    return value && value.length > 0 ? value.slice(28): null;
  }

}

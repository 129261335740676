/**
 * Получение composedPath эвента
 * IE11 polifill
 * @param event - эвент
 */
export const getEventComposedPath = (event: Event) => {
  if (!event.composedPath) {
    event.composedPath = function () {
      if (this.path) {
        return this.path;
      }
      var target = this.target;

      this.path = [];
      while (target.parentNode !== null) {
        this.path.push(target);
        target = target.parentNode;
      }
      this.path.push(document, window);
      return this.path;
    };
  }
  return event.composedPath();
};

import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IMultiSelectItem } from "@core/interfaces/select-item";
import { IDictionaryBody, IDictionaryParams } from "@core/interfaces/dictionary";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as cloneDeep from 'lodash.clonedeep';
import {animationFrameScheduler, BehaviorSubject, Subject} from "rxjs";
import {SearchFieldComponent} from "@shared/components/search-field/search-field.component";
import {Observable} from "rxjs/internal/Observable";
import {filter, map, takeUntil, throttleTime} from "rxjs/operators";
import { ModalRedirectCloseService } from '@app/shared/services/modal-redirect-close.service';
import { scrollToTop } from '@app/shared/functions';

@Component({
  selector: 'app-multi-dictionary-modal',
  templateUrl: './multi-dictionary-modal.component.html',
  styleUrls: ['./multi-dictionary-modal.component.scss'],
  providers: [ModalRedirectCloseService]
})
export class MultiDictionaryModalComponent implements OnInit {

  @Input() loadingInProgress: BehaviorSubject<boolean>; // флаг загрузки
  @Input() disabled: boolean = false; // блокировка
  @Input() selected: IMultiSelectItem[] = null; // выбранные значения
  @Input() list: BehaviorSubject<IDictionaryBody>; // список значений
  @Input() params: IDictionaryParams; // список значений
  @Input() title: string = null; // тайтл при отсутствии выбранного значения (placeholder)
  @Output() OnSelect: Function; // применить выбранное значение
  @Output() OnLoadList: Function; // загрузить список значений
  @Output() OnClose: Function; // функция при закрытии

  @ViewChild('dictList') dictList: ElementRef;
  @ViewChild('searchField') searchField: SearchFieldComponent;

  public showList$: Observable<boolean>;
  public elements$: Observable<IMultiSelectItem[]>;
  public loaded$: Observable<number>;
  public total$: Observable<number>;

  public selectedItems: IMultiSelectItem[] = []; // список выбранных значений
  public selectedKeys: Array<string | number> = []; // список выбранный значений - keys
  public selectedRegime: boolean = false; // режим Только выбранные
  public selectedAll: boolean = false; // выбрать все
  public activeApply: boolean = false;
  public selectedItemsCount: string = '';

  //для режима Только выбранные
  public selectedItemsForRegime: IMultiSelectItem[] = []; // выбранные значения в режиме Только выбранные
  public selectedKeysForRegime: Array<string | number> = []; // выбранные значения в режиме Только выбранные - keys
  public selectedListForRegime: IMultiSelectItem[] = []; // полный список значений в режиме Только выбранные
  public orderingForRegime: string = 'asc'; // направление сортировки списка значений в режиме Только выбранные
  public selectedAllForRegime: boolean = false; // выбрать все из полного списка значений в режиме Только выбранные
  public searchTextForRegime: string = null; // строка поиска по списку значений в режиме Только выбранные

  public onScroll$: Subject<Event> = new Subject<Event>();
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public activeModal: NgbActiveModal,
    private modalRedirectCloseService: ModalRedirectCloseService
  ) { }

  ngOnInit() {
    this.showList$ = this.list.pipe(
      map( (res) => res && res.elements && res.elements.length > 0 && !this.selectedRegime)
    );
    this.elements$ = this.list.pipe(
      map((res) => res && res.elements && res.elements.length > 0 ? res.elements : [])
    );
    this.loaded$ = this.list.pipe(
      map((res) => res && res.elements && res.elements.length > 0 ? res.elements.length : 0)
    );
    this.total$ = this.list.pipe(
      map((res) => res && res.total ? res.total : 0)
    );
    this.onScroll$
      .pipe(
        filter(res => {
          const element = res.target;
          return element['scrollHeight'] - element['scrollTop'] <= element['clientHeight'] + 10;
        }),
        throttleTime(100, animationFrameScheduler),
        takeUntil(this.onDestroy$)
      )
      .subscribe(res => {
        this.loadMore();
      });
    this.selectedItems = this.selected && this.selected.length > 0
      ? this.selected.map((item: IMultiSelectItem) => {
        item.checked = true;
        return item;
      })
      : null;
    if (this.params && this.params.pagination && this.params.pagination.pageNumber
      && this.params.pagination.pageNumber === 1
      && this.list && this.list.getValue() && this.list.getValue().elements
      && this.list.getValue().elements.length === 0) {
      if (this.OnLoadList) {
        this.OnLoadList(this.params);
      }
    }
    this.prepareSelectedKeys();
    this.prepareSelectedCount(this.selectedItems);
    this.selectedAll = this.selectedItems && this.list && this.list.getValue()
      && this.list.getValue().elements && this.list.getValue().elements.length === this.selectedItems.length;
    this.selectedRegime = false;
    this.checkActiveApply();
  }

  ngAfterViewInit() {
    if (this.searchField) {
      this.searchField.focus();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private prepareSelectedCount(list: IMultiSelectItem[]) {
    this.selectedItemsCount = list && list.length > 0 ? ` (${list.length})` : '';
  }

  private checkActiveApply() {
    const selected = this.selectedRegime === true ? this.selectedItemsForRegime : this.selectedItems;
    const selectedArr = this.selected;
    if (selected && selected.length > 0) {
      selected.sort((a, b) =>
        a.key.toString().localeCompare(b.key.toString(), 'string', { numeric: true }));

    }
    if (selectedArr && selectedArr.length > 0) {
      selectedArr.sort((a, b) =>
        a.key.toString().localeCompare(b.key.toString(), 'string', { numeric: true }));
    }
    this.activeApply = (selected && selectedArr && JSON.stringify(selected) !== JSON.stringify(selectedArr))
      || (selected && selected.length > 0 && (!selectedArr || selectedArr.length === 0));
  }

  /**
   * выборка keys выбранных значений для полного справочника
   */
  private prepareSelectedKeys() {
    this.selectedKeys = this.selectedItems && this.selectedItems.length > 0
      ? this.selectedItems.map((item: IMultiSelectItem) => { return item.key })
      : [];
  }

  /**
   * выборка keys выбранных значений для ссписка Только выбранные
   */
  private prepareSelectedKeysForRegime() {
    this.selectedKeysForRegime = this.selectedItemsForRegime && this.selectedItemsForRegime.length > 0
      ? this.selectedItemsForRegime.map((item: IMultiSelectItem) => { return item.key })
      : [];
  }

  /**
   * смена режима Полный справочник / Только выбранные
   * @param value
   */
  public onChangeRegime(value: boolean) {
    this.selectedRegime = value;
    if (this.selectedRegime === true) {
      this.selectedItemsForRegime = cloneDeep(this.selectedItems);
      this.prepareSelectedKeysForRegime();
      this.orderingForRegime = 'asc';
      this.searchTextForRegime = null;
      this.searchForRegime(this.searchTextForRegime);
      this.selectedAllForRegime = true;
    } else {
      this.selectedItems = cloneDeep(this.selectedItemsForRegime);
      this.prepareSelectedKeys();
      this.selectedAll = this.selectedItems && this.list && this.list.getValue()
        && this.list.getValue().elements && this.list.getValue().elements.length === this.selectedItems.length;
      this.params.pagination.pageNumber = 1;
      if (this.dictList) {
        this.scrollToTop();
      }
      if (this.OnLoadList) {
        this.OnLoadList(this.params);
      }
    }
  }

  /**
   * выбор значения в полном справочнике
   * @param selectedItems
   */
  public onSelectItems(selectedItems: Array<string | number>) {
    const selectedFromList: IMultiSelectItem[] = this.list.getValue().elements.filter((item: IMultiSelectItem) => selectedItems.includes(item.key));
    this.selectedItems = this.selectedItems ? this.selectedItems.filter((item: IMultiSelectItem) => selectedItems.includes(item.key)) : [];
    selectedFromList.forEach((item: IMultiSelectItem) => {
      if (!this.selectedItems.find((selected: IMultiSelectItem) => selected.key.toString() === item.key.toString())) {
        this.selectedItems.push(item);
      }
    });
    this.prepareSelectedKeys();
    this.prepareSelectedCount(this.selectedItems);
    this.selectedAll = this.selectedItems && this.list && this.list.getValue()
      && this.list.getValue().elements && this.list.getValue().elements.length === this.selectedItems.length;
    this.checkActiveApply();
  }

  /**
   * выбрать/снять все в полном справочнике
   * @param selectAll
   */
  public onSelectAll(selectAll: boolean) {
    this.selectedAll = selectAll;
    this.selectedItems = this.selectedAll === true ? this.list.getValue().elements : [];
    this.prepareSelectedKeys();
    this.prepareSelectedCount(this.selectedItems);
    this.checkActiveApply();
  }

  /**
   * Применить выбор
   */
  public apply() {
    let selected: IMultiSelectItem[] = this.selectedRegime === true ? this.selectedItemsForRegime : this.selectedItems;
    if (this.OnSelect) {
      this.OnSelect(selected);
    }
    this.cancel();
  }

  /**
   * Отмена
   */
  public cancel(): void {
    if (this.OnClose) {
      this.OnClose();
    }
    this.activeModal.close();
  }

  /**
   * подгрузка значений полного справочника по скроллу
   */
  public loadMore() {
    if (this.params && this.params.pagination && this.params.pagination.pageNumber
      && this.list && this.list.getValue() && this.list.getValue().totalPages
      && this.params.pagination.pageNumber < this.list.getValue().totalPages) {
      this.params.pagination.pageNumber = this.params.pagination.pageNumber + 1;
      if (this.OnLoadList) {
        this.OnLoadList(this.params);
      }
    }
  }

  /**
   * сортировка полного справочника
   */
  public changeSorting() {
    this.selectedAll = false;
    if (this.params.sortings && this.params.sortings.length > 0 && this.params.sortings[0].direction === 'asc') {
      this.params.sortings[0].direction = 'desc';
    } else {
      this.params.sortings[0].direction = 'asc';
    }
    this.params.pagination.pageNumber = 1;
    if (this.dictList) {
      this.scrollToTop();
    }
    if (this.OnLoadList) {
      this.OnLoadList(this.params);
    }
  }


  /**
   * поиск по полному справочнику
   * @param value
   */
  public search(value: string) {
    this.selectedAll = false;
    this.params.search = value;
    this.params.pagination.pageNumber = 1;
    if (this.dictList) {
      this.scrollToTop();
    }
    if (this.OnLoadList) {
      this.OnLoadList(this.params);
    }
  }

  /**
   * выбрать/снять все в режиме Только выбранные
   * @param selectAll
   */
  public onSelectAllForRegime(selectAll: boolean) {
    this.selectedAllForRegime = selectAll;
    this.selectedItemsForRegime = this.selectedAllForRegime === true ? this.selectedItems : [];
    this.prepareSelectedKeysForRegime();
    this.prepareSelectedCount(this.selectedItemsForRegime);
    this.checkActiveApply();
  }

  /**
   * выбор значения в режиме Только выбранные
   * @param items
   */
  public onSelectItemsForRegime(items: Array<string | number>) {
    this.selectedItemsForRegime = cloneDeep(this.selectedItems.filter((item: IMultiSelectItem) => items.includes(item.key)));
    this.prepareSelectedKeysForRegime();
    this.prepareSelectedCount(this.selectedItemsForRegime);
    this.selectedAllForRegime = this.selectedItemsForRegime && this.selectedItems && this.selectedItemsForRegime.length === this.selectedItems.length;
    this.checkActiveApply();
  }

  /**
   * изменение сортировки в режиме Только выбранные
   */
  public changeSortingForRegime() {
    if (this.orderingForRegime === 'asc') {
      this.orderingForRegime = 'desc';
    } else {
      this.orderingForRegime = 'asc';
    }
    this.sortForRegime();
  }

  /**
   * сортировка в режиме Только выбранные
   */
  private sortForRegime() {
    const result = this.selectedListForRegime ? this.selectedListForRegime : [];
    this.selectedListForRegime = [];
    if (this.orderingForRegime === 'asc') {
      if (result.length > 0) {
        result.sort((a, b) => a.value.localeCompare(b.value, 'string', { numeric: true }));
      }
    } else {
      if (result.length > 0) {
        result.sort((a, b) => b.value.localeCompare(a.value, 'string', { numeric: true }));
      }
    }
    result.forEach((resItem: IMultiSelectItem) => {
      this.selectedListForRegime.push(resItem);
    });
  }

  /**
   * поиск в режиме Только выбранные
   * @param value
   */
  public searchForRegime(value: string) {
    this.selectedAllForRegime = false;
    this.searchTextForRegime = value;
    if (this.searchTextForRegime && this.searchTextForRegime !== '') {
      this.selectedListForRegime = this.selectedItems.filter(item => item.value.indexOf(this.searchTextForRegime) > -1);
    } else {
      this.selectedListForRegime = this.selectedItems;
    }
    this.sortForRegime();
  }


  /**
   * скролл карточки наверх
   */
  private scrollToTop(): void {
    scrollToTop(this.dictList);
  }
}

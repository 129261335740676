import { Injectable, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReplaySubject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
/**
 * Сервис автоматического закрытия модального окна при редиректе
 * Инжектим сервис в модалки, когда требуется автоматическое закрытие при редиректе
 */
@Injectable()
export class ModalRedirectCloseService implements OnDestroy {
  private readonly destroy$ = new ReplaySubject<boolean>(1);

  constructor(private activeModal: NgbActiveModal, private router: Router) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.activeModal.close());
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

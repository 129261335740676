import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CalendarComponent } from "./calendar/calendar.component";
import { CalendarMonthComponent } from "./calendar-month/calendar-month.component";
import { DateTimeInputComponent } from "./date-time-input/date-time-input.component";
import { RangeCalendarComponent } from "./range-calendar/range-calendar.component";
import { TimeSelectInputComponent } from "./time-select-input/time-select-input.component";
import { ClickOutsideModule } from "ng-click-outside";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TextMaskModule } from "angular2-text-mask";
import { MonthSelectComponent } from "./month-select/month-select.component";
import { QuarterSelectComponent } from "./quarter-select/quarter-select.component";
import { YearSelectComponent } from "./year-select/year-select.component";
import { TranslateModule } from "@ngx-translate/core";
import { TimeSelectComponent } from "./time-select/time-select.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CalendarWithApplyDirective } from "./calendar-with-apply/calendar-with-apply.directive";
import { CalendarWithApplyComponent } from "./calendar-with-apply/calendar-with-apply.component";
import { LockEditableModule } from "../lock-editable/lock-editable.module";
import { PopupContainerModule } from "../popup-container/popup-container.module";
import { ArrowModule } from "../arrow/arrow.module";
import { SelectFieldModule } from "../select-field/select-field.module";
import { InputModule } from "../input/input.module";
import { ButtonModule } from "../button/button.module";

@NgModule({
  declarations: [
    CalendarComponent,
    CalendarMonthComponent,
    DateTimeInputComponent,
    RangeCalendarComponent,
    TimeSelectInputComponent,
    MonthSelectComponent,
    QuarterSelectComponent,
    YearSelectComponent,
    TimeSelectComponent,
    CalendarWithApplyDirective,
    CalendarWithApplyComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ClickOutsideModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    LockEditableModule,
    InputModule,
    PopupContainerModule,
    ArrowModule,
    SelectFieldModule,
    ButtonModule,
    NgxSliderModule,
  ],
  exports: [
    CalendarComponent,
    CalendarMonthComponent,
    DateTimeInputComponent,
    RangeCalendarComponent,
    TimeSelectInputComponent,
    MonthSelectComponent,
    QuarterSelectComponent,
    YearSelectComponent,
    TimeSelectComponent,
    CalendarWithApplyDirective,
    CalendarWithApplyComponent,
  ],
})
export class CalendarModule {}

import { Component, Input, Output } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { PasswordValidator } from "@shared/validators/password.validator";
import { TranslateService } from "@ngx-translate/core";
import { PasswordApiService } from "@core/services/api/password-api.service";
import { ToastrService } from "ngx-toastr";
import { IValidator } from "@core/interfaces/validator";
import { SYSTEM_PARAMETER_NAME } from "@app/app.enums";
import { SystemParamsService } from "@core/services/system-params.service";
import { finalize } from 'rxjs/operators';
import { ngThrottle } from '@app/shared/decorators/throttle.decorator';
import { AdminUsersApiService } from '@app/core/services/api/admin-users-api.service';
import { OpenModalService } from '@app/shared/services/open-modal.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { ModalRedirectCloseService } from '@app/shared/services/modal-redirect-close.service';

@Component({
  selector: 'app-temporary-password-modal',
  templateUrl: './temporary-password-modal.component.html',
  styleUrls: ['./temporary-password-modal.component.scss'],
  providers: [ModalRedirectCloseService]
})
export class TemporaryPasswordModalComponent {

  @Input() userId: number = null;
  @Output() OnSave: Function;

  public pasMinLength: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_LENGTH)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_LENGTH)) : 8;
  public pasMinUpper: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_UPPERCASES)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_UPPERCASES))
    : 1;
  public pasMinLower: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_LOWERCASES)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_LOWERCASES)) : 1;
  public pasMinNumbers: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_NUMBERS)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_NUMBERS)) : 1;
  public pasMinSpec: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_SPECIAL_CHARACTERS)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_SPECIAL_CHARACTERS)) : 1;


  public form: FormGroup = new FormGroup({
    newPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(this.pasMinLength),
      PasswordValidator(this.pasMinUpper, this.pasMinLower, this.pasMinNumbers, this.pasMinSpec)
    ])
  });

  public isLoading: boolean = false;
  public isSubmitted: boolean = false;
  private passwordErrors: string[] = null;

  private successTitle: string = '[]';
  private errorTitle: string = '';
  private errorTitles: string[] = [];
  private requirements: string = 'PASSWORD.REQUIREMENT';
  private generalTranslates: any;
  private confirmUpdateActive: string;

  constructor(
    public activeModal: NgbActiveModal,
    private translateService: TranslateService,
    private passwordApiService: PasswordApiService,
    private systemParamsService: SystemParamsService,
    private toastr: ToastrService,
    private adminUserApiService: AdminUsersApiService,
    private modalService: OpenModalService,
    private modalRedirectCloseService: ModalRedirectCloseService
  ) {
    this.translateService.get(['PASSWORD', 'GENERAL', 'ADMIN.USERS.CARD'])
      .subscribe((result: string[]) => {
        this.successTitle = result['ADMIN.USERS.CARD']['SUCCESS']['TEMPORARY_PASSWORD'];
        this.errorTitle = result['ADMIN.USERS.CARD']['ERRORS']['TEMPORARY_PASSWORD'];
        this.confirmUpdateActive = result['ADMIN.USERS.CARD']['CONFIRMS']['ACTIVE_SESSION'];
        this.errorTitles = result['PASSWORD']['ERRORS'];
        this.generalTranslates = result['GENERAL'];
      });
  }

  @ngThrottle()
  public confirmChangePassword() {
    if (this.form.valid && this.userId) {
      this.isLoading = true;
      this.adminUserApiService.getUserSessionInfo(this.userId)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(
          res => {
            if (res.hasActiveTokens) {
              this.modalService.show({
                component: ConfirmModalComponent,
                data: {
                  question: this.confirmUpdateActive,
                  applyTitle: this.generalTranslates.CONFIRM,
                  cancelTitle: this.generalTranslates.CANCEL,
                  onApply: () => this.changePassword()
                },
                options: {
                  centered: true,
                  windowClass: 'modal-confirm',
                },
                callbacks: {
                  Enter: 'apply',
                  Escape: 'cancel'
                }
              });
            }
            else {
              this.changePassword()
            }
          },
          error => {
            this.toastr.error(
              error && error.error ? error.error.message : null,
              this.generalTranslates.ERROR_REQUEST
            );
          }
        );
    }
    else {
      const errors = this.form.get('newPassword').errors;
      const errorTitle = this.checkPassword();
      if (errorTitle && errorTitle === this.errorTitles['NO_CORRECT']) {
        this.prepareTooltip(errors);
      } else {
        this.passwordErrors = null;
      }
      this.toastr.error(
        this.passwordErrors ? this.passwordErrors.join('<br/>') : null,
        errorTitle);
    }
  }

  private changePassword() {
    const password = this.form.get('newPassword').value;
    this.isLoading = true;
    this.passwordApiService.setTemporaryPassword(this.userId, password)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        () => {
          this.toastr.success(this.generalTranslates.SUCCESS_SAVE);
          if (this.OnSave) {
            this.OnSave();
          }
          this.onCancel();
        },
        error => {
          this.toastr.error(
            error && error.error ? error.error.message : null,
            this.errorTitle
          );
        });
  }

  public onCancel() {
    this.form.reset();
    this.passwordErrors = null;
    this.activeModal.close();
  }

  public checkPassword() {
    const errors = this.form.get('newPassword').errors;
    if (errors && errors['required']) {
      return this.errorTitles['REQUIRED'];
    } else if (errors && ((errors['password'] && errors['password'].length > 0) || errors['minlength'])) {
      return this.errorTitles['NO_CORRECT'];
    } else {
      return null;
    }
  }

  private prepareTooltip(errors: ValidationErrors) {
    if (!errors) {
      return null;
    }
    this.passwordErrors = [];
    if (errors['minlength']) {
      this.passwordErrors.push(
        this.translateService.instant(`${this.requirements}.LENGTH`, { value: this.pasMinLength })
      );
    }
    if (errors['password'] && errors['password'].length > 0) {
      const passwordErrors = errors['password'];
      passwordErrors.forEach((error: IValidator) => {
        if (error['password-rus']) {
          this.passwordErrors.push(
            this.translateService.instant(`${this.requirements}.NO_RUS`)
          );
        }
        if (error['password-AZ']) {
          this.passwordErrors.push(
            this.translateService.instant(`${this.requirements}.CAPITAL`, { value: this.pasMinUpper })
          );
        }
        if (error['password-az']) {
          this.passwordErrors.push(
            this.translateService.instant(`${this.requirements}.SMALL`, { value: this.pasMinLower })
          );
        }
        if (error['password-num']) {
          this.passwordErrors.push(
            this.translateService.instant(`${this.requirements}.NUMBER`, { value: this.pasMinNumbers })
          );
        }
        if (error['password-spec']) {
          this.passwordErrors.push(
            this.translateService.instant(`${this.requirements}.SPEC`, { value: this.pasMinSpec })
          );
        }
      });
    }
  }
}

import { ElementRef } from "@angular/core";

/**
 * Функция скролла к началу компонента
 * @param block - компонент
 */
export const scrollToTop = (block: ElementRef) => {
  // IE fix
  const canScroll =
    block &&
    block.nativeElement &&
    block.nativeElement.scrollTo &&
    typeof block.nativeElement.scrollTo === "function";

  if (canScroll) {
    block.nativeElement.scrollTo(0, 0);
  }
};

import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { passwordPatterns } from "@app/app.enums";

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true
    }
  ]
})
export class PasswordInputComponent implements OnInit, ControlValueAccessor {

  private innerValue: any;
  public type: string = 'password';
  public showPassword: boolean = false;
  private pattern = passwordPatterns.rus;

  @Input() inputId: string = ''; // ид инпута
  @Input() inputHeight: number = 35; // высота инпута
  @Input() withShowIcon: boolean = true; // флаг показа иконки Показать/Скрыть пароль
  @Input() placeholder: string = ''; // плейсхолдер
  @Input() disabled: boolean = false; // блокировка
  @Input() hasError: boolean = false; // флаг наличия ошибки
  @Input() hasSuccess: boolean = false; // флаг успешной проверки
  @Input() resultText: string = null; // текст результата проверки
  @Output() OnChange = new EventEmitter<any>(); // событие ввода
  @Output() OnFocus = new EventEmitter<any>(); // событие фокуса
  @Output() OnBlur = new EventEmitter<any>(); // событие снятия фокуса
  @ViewChild('input') input: ElementRef;

  onChange: any = () => { };
  onTouched: any = () => { };

  get model(): string {
    return this.innerValue;
  }

  set model(val) {
    this.innerValue = this.checkMask(val);
    this.input.nativeElement.value = this.innerValue;
    this.onChange(this.innerValue);
    this.OnChange.emit(this.innerValue);
  }

  constructor() { }

  ngOnInit() {
  }

  writeValue(value): void {
    this.innerValue = this.checkMask(value);
    this.onChange(this.innerValue);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  emitFocusEvent() {
    this.OnFocus.emit();
  }

  emitBlurEvent() {
    this.OnBlur.emit();
    this.onTouched();
  }

  private checkMask(value: string): string {
    let result: string = value;
    if (value) {
      for (let i = 0; i < value.length; i++) {
        if (!value.charAt(i).match(this.pattern)) {
          result = value.replace(value.charAt(i), '');
          break;
        }
      }
      result = result.replace(/\s+/g, '');
    }
    return result;
  }

  public changeVisiblePassword() {
    if (this.withShowIcon) {
      this.showPassword = !this.showPassword;
      this.type = this.showPassword ? 'text' : 'password';
    }
  }

}

import { Component, forwardRef, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import * as dayjsFormats from 'dayjs/plugin/customParseFormat';
import * as dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as cloneDeep from 'lodash.clonedeep';
import { CalendarWithApplySupportComponent } from '../calendar-with-apply/calendar-with-apply-support.component';
import { IRangeDate } from '@app/core/interfaces/calendar';
import { IBaseCalendarProperties } from '../../base-components/base-calendar.component';
import { DateFormat, DateTimeService, TimeUnit } from '@app/shared/services/date-time.service';
import { wordDeclination } from '@shared/functions/word-declination.function';

dayjs.extend(dayjsFormats);
dayjs.extend(dayjsIsSameOrBefore);
dayjs.extend(dayjsIsSameOrAfter);

@Component({
  selector: 'app-range-calendar',
  templateUrl: './range-calendar.component.html',
  styleUrls: ['./range-calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeCalendarComponent),
      multi: true
    },
    {
      provide: CalendarWithApplySupportComponent,
      useExisting: forwardRef(() => RangeCalendarComponent)
    }
  ]
})
export class RangeCalendarComponent
  extends CalendarWithApplySupportComponent<IRangeDate>
  implements OnChanges,
  ControlValueAccessor,
  IBaseCalendarProperties<IRangeDate> {

  private readonly dateFormat = DateFormat.DATE;
  public readonly timeWithSecondFormat = DateFormat.TIME_WITH_SECONDS;
  public maxRangeTooltipDefaultText: string;
  /**
   * Стандартное "пустое" значение.
   * { start: null, end: null }
   */
  private get defaultSelected(): IRangeDate {
    return { start: null, end: null };
  }

  /**
   * Текущий год.
   */
  private readonly currentYear = this.currentMoment.year();
  /**
   * Текущий месяц.
   */
  private readonly currentMonth = this.currentMoment.month() + 1;
  /**
   * Флаг наличия даты.
   */
  public withDate: boolean = true;
  /**
   * Флаг наличия времени.
   */
  public withTime: boolean = false;
  /**
   * Флаг наличия секунд.
   */
  public withSeconds: boolean = false;
  public startMinDateForCalendar: string = null;
  public startMaxDateForCalendar: string = null;
  public endMinDateForCalendar: string = null;
  public endMaxDateForCalendar: string = null;

  public hasErrorStartInner: boolean = false;
  public errorStartInner: string = null;
  private hasErrorPeriod: boolean = false;
  private hasErrorMinDate: boolean = false;
  public hasErrorMaxDate: boolean = false;
  private errorText: string = null;
  private errorMinDate: string = null;
  public errorMaxDate: string = null;

  private readonly errorPeriod: string = this.translateService.instant('GENERAL.ERROR_PERIOD');
  private readonly errorMinDateText: string = this.translateService.instant('GENERAL.ERROR_PERIOD_START_MIN');
  private readonly errorMaxDateText: string = this.translateService.instant('GENERAL.ERROR_PERIOD_END_MAX');

  /**
   * @inheritdoc
   * @default DateFormat.DATE
   */
  @Input() format: DateFormat = this.dateFormat;
  /**
   * @inheritdoc
   * @default false
   */
  @Input() noManualInput: boolean = false;
  /**
   * Флаг ошибки начала периода.
   */
  @Input() hasErrorStart: boolean = false;
  /**
   * Флаг ошибки конца периода.
   */
  @Input() hasErrorEnd: boolean = false;
  /**
   * Текст ошибки начала периода.
   */
  @Input() errorStart: string = null;
  /**
   * Текст ошибки конца периода.
   */
  @Input() errorEnd: string = null;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withMinutes: boolean = true;
  /**
   * @inheritdoc
   */
  @Input() minDate: string = null;
  /**
   * @inheritdoc
   */
  @Input() maxDate: string = null;
  /**
   * Минимальный месяц в минимальном году для начала периода.
   * @default 1
   */
  @Input() startMinMonth: number = 1;
  /**
   * Максимальный месяц в максимальном году для начала периода.
   * @default текущий месяц.
   */
  @Input() startMaxMonth: number = this.currentMonth;
  /**
   * Минимальный год для начала периода.
   * @default текущий год - 20.
   */
  @Input() startMinYear: number = this.currentYear - 20;
  /**
   * Максимальный год для начала периода.
   * @default текущий год.
   */
  @Input() startMaxYear: number = this.currentYear;
  /**
   * Минимальный месяц в минимальном году для начала периода.
   * @default 1
   */
  @Input() endMinMonth: number = 1;
  /**
   * Максимальный месяц в максимальном году для начала периода.
   * @default текущий месяц.
   */
  @Input() endMaxMonth: number = this.currentMonth;
  /**
   * Минимальный год для начала периода.
   * @default текущий год - 20.
   */
  @Input() endMinYear: number = this.currentYear - 20;
  /**
   * Максимальный год для начала периода.
   * @default текущий год.
   */
  @Input() endMaxYear: number = this.currentYear;
  /**
   * @inheritdoc
   * @default 'bottom left'
   */
  @Input() openDirection: string = 'bottom left';
  /**
   * Минимальный размер интервала.
   * @default 0
   */
  @Input() minRange = 0;
  /**
   * Максимальный размер интервала.
   */
  @Input() maxRange: number;
  /**
   * Единица измерения интервала.
   * @default TimeUnit.DAY
   */
  @Input() rangeTimeUnit: TimeUnit = TimeUnit.DAY;
  /**
   * Флаг отображения подсказки о максимальном периоде дат
   * @default true
   */
  @Input() showMaxRangeTooltip: boolean = true;
  /**
   * Текст/шаблон подсказки о максимальном периоде дат.
   * По умолчанию отображается 'COMPONENT_LIBRARY.DATE.MAX_RANGE_TOOLTIP - {@link maxRange}'
   * @default null
   */
  @Input() maxRangeTooltip: string | TemplateRef<any> = null;
   /**
   * Положение подсказки о максимальном периоде дат.
   * @default 'bottom-center'
   */
   @Input() maxRangeTooltipPlacement: string = 'bottom-center';
  /**
   * Показывать ли единицы измерения в тултипе при заданном maxRange.
   * Если true - "Максимальный диапазон - 7 суток"
   * Если false - "Максимальный диапазон - 7"
   */
  @Input() maxRangeTooltipShowUnit = false;

  constructor(
    private dateTimeService: DateTimeService,
    private translateService: TranslateService
  ) {
    super();
    this.selected = this.defaultSelected;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.format) {
      this.prepareDateTimeFormats();
    }
    if (changes.format ||
      changes.isNeedLimit ||
      changes.minDate ||
      changes.maxDate) {
      this.setMinMax();
      this.checkDate();
    }
    this.buildMaxRangeTooltip();
  }

  /**
   * Изменить лимиты на основе входных значений.
   */
  private setMinMax() {
    if (this.isNeedLimit) {
      if (this.minDate) {
        this.startMinDateForCalendar = dayjs(this.minDate, this.format).format(this.dateFormat);
        this.startMinMonth = dayjs(this.minDate, this.format).month() + 1;
        this.startMinYear = dayjs(this.minDate, this.format).year();
        this.endMinDateForCalendar = dayjs(this.minDate, this.format).format(this.dateFormat);
        this.endMinMonth = dayjs(this.minDate, this.format).month() + 1;
        this.endMinYear = dayjs(this.minDate, this.format).year();
      }
      if (this.maxDate) {
        this.startMaxDateForCalendar = dayjs(this.maxDate, this.format).format(this.dateFormat);
        this.startMaxMonth = dayjs(this.maxDate, this.format).month() + 1;
        this.startMaxYear = dayjs(this.maxDate, this.format).year();
        this.endMaxDateForCalendar = dayjs(this.maxDate, this.format).format(this.dateFormat);
        this.endMaxMonth = dayjs(this.maxDate, this.format).month() + 1;
        this.endMaxYear = dayjs(this.maxDate, this.format).year();
      }
    }
  }

  /**
   * Изменить лимиты на основе измененного значения.
   */
  private setMinMaxFromChange() {
    if (this.isNeedLimit) {
      if (this.selected && !!this.selected.start) {
        const start = dayjs(this.selected.start, this.format)
        this.endMinDateForCalendar = start.format(this.dateFormat);
        this.endMinMonth = start.month() + 1;
        this.endMinYear = start.year();
      }
      if (this.selected && !!this.selected.end) {
        const end = dayjs(this.selected.end, this.format);
        this.startMaxDateForCalendar = end.format(this.dateFormat);
        this.startMaxMonth = end.month() + 1;
        this.startMaxYear = end.year();
      }
    }
  }

  /**
   * Подготовить форматы даты и времени.
   */
  private prepareDateTimeFormats() {
    this.withDate = this.format && !!this.format.includes(this.dateFormat);
    const timeFormat: string = this.format !== this.dateFormat
      ? !!this.format.split(' ')[1]
        ? this.format.split(' ')[1]
        : this.format
      : null;
    this.withTime = !!timeFormat;
    this.withSeconds = timeFormat === this.timeWithSecondFormat;
  }

  /**
   * Коллбек на изменение в поле.
   * @param value
   * @param key
   */
  public enterDateTime(value: string, key: keyof IRangeDate) {
    if (!value) {
      this.clear(key);
      return;
    }
    this.selectDateTime(value, key);
  }

  public clear(key?: keyof IRangeDate) {
    this.writeValue({
      ...this.selected,
      [key]: null
    });
    this.emitChanges(this.selected);
  }

  /**
   * Коллбек на изменение в календаре.
   * @param value значение.
   * @param key ключ значения.
   */
  public selectDateTime(value: string, key: keyof IRangeDate) {
    const maxRange = this.getCorrectMaxRange();
    if (this.selected[key] !== value) {
      this.selected[key] = value;
      if (
        (this.minRange || this.maxRange) &&
        (this.selected.start && this.selected.end)
      ) {
        const otherKey: keyof IRangeDate = key == 'start'
          ? 'end'
          : 'start';
        const multiplier: 1 | -1 = key == 'start'
          ? -1
          : 1;
        const rangeDatesDiff = this.dateTimeService.getDateDifference(
          this.rangeTimeUnit,
          this.selected.end,
          this.format,
          this.selected.start,
          this.format
        ) + 1;
        const delta: number = this.minRange > 0 && rangeDatesDiff < this.minRange
          ? rangeDatesDiff - this.minRange
          : maxRange > 0 && rangeDatesDiff > maxRange
            ? rangeDatesDiff - maxRange
            : null;
        const otherDate = !!delta
          ? dayjs(this.selected[otherKey], this.format)
            .add(
              delta * multiplier,
              this.rangeTimeUnit as any
            )
            .format(this.format)
          : this.selected[otherKey];
        this.selected[otherKey] = otherDate;
      }
      this.setMinMaxFromChange();
      this.emitChanges(this.selected);
    }
  }

  /**
   * Метод проверки ошибок в датах.
   */
  private checkDate() {
    if (this.selected && this.selected.start && this.minDate) {
      const start = dayjs(this.selected.start, this.format);
      const minMoment = dayjs(this.minDate, this.format);
      this.hasErrorMinDate = start.isBefore(minMoment);
      this.errorMinDate = start.isBefore(minMoment) ? `${this.errorMinDateText} ${this.minDate}` : null;
    } else {
      this.hasErrorMinDate = false;
      this.errorMinDate = null;
    }
    if (this.selected && this.selected.end && this.maxDate) {
      const end = dayjs(this.selected.end, this.format);
      const maxMoment = dayjs(this.maxDate, this.format);
      this.hasErrorMaxDate = end.isAfter(maxMoment);
      this.errorMaxDate = end.isAfter(maxMoment) ? `${this.errorMaxDateText} ${this.maxDate}` : null;
    } else {
      this.hasErrorMaxDate = false;
      this.errorMaxDate = null;
    }
    if (this.selected && this.selected.start && this.selected.end) {
      const start = dayjs(this.selected.start, this.format);
      const end = dayjs(this.selected.end, this.format);
      this.hasErrorPeriod = end.isBefore(start);
      this.errorText = end.isBefore(start) ? this.errorPeriod : null;
    } else {
      this.hasErrorPeriod = false;
      this.errorText = null;
    }
    this.hasErrorStartInner = this.hasErrorMinDate || this.hasErrorPeriod;
    this.errorStartInner = this.errorMinDate ? this.errorMinDate : this.errorText;
  }

  writeValue(value: IRangeDate): void {
    this.selected = !!value
      ? cloneDeep(value)
      : this.defaultSelected;
    this.setMinMaxFromChange();
    this.checkDate();
  }

  private buildMaxRangeTooltip() {
    if (this.maxRange == null) { return; }
    if (!this.maxRangeTooltipShowUnit) {
      this.maxRangeTooltipDefaultText = this.translateService.instant(
        'DATE.MAX_RANGE_TOOLTIP',
        { value: this.maxRange }
      );
    } else {
      const words = [
        'DATE.DAY',
        'DATE.DAYS',
        'DATE.DAYS',
      ];

      const tooltipText = this.translateService.instant(
        'DATE.MAX_RANGE_TOOLTIP',
        { value: this.maxRange }
      );
      const dayText: string = this.translateService.instant(wordDeclination(this.maxRange, words));
      this.maxRangeTooltipDefaultText = tooltipText + ' ' + dayText.toLowerCase();
    }
  }

  /**
   * Для фильтра с форматами "дата и время" увеличиваем значение maxRange на "+1", Например:
   * по ОПЗ ограничение в 2суток, значит maxRange = 2(дня/суток)
   * 15.02.24 15:30 - 48ч(2суток) = 13.02.24 15.30, тут все правильно, но визуально в календаре
   * будет выбрано 3 дня - 13.02, 14.02. 15.02
   */
  private getCorrectMaxRange(): number {
    if (!this.maxRange) { return 0; }
    if (
      this.format !== DateFormat.DATE_TIME
      && this.format !== DateFormat.DATE_TIME_WITH_SECONDS
      && this.format !== DateFormat.DATE_TIME_FOR_DATE
      && this.format !== DateFormat.DATE_FOR_FILE
    ) {
      return this.maxRange;
    }

    return this.maxRange + 1;
  }
}

import { fromEvent, Observable, Subject } from "rxjs";
import { finalize } from "rxjs/operators";

/**
   * Оборачивает Web Api ResizeObserver в Observable.
   * Если текущий браузер не поддерживает ResizeObserver,
   * возвращает fromEvent(window, 'resize')
   * @param target nativeElement
   */
export const getResizeObservable = (target: HTMLElement): Observable<any> => {
    const ResizeObserver = (window as any).ResizeObserver ? (window as any).ResizeObserver : null;
    const resizeSubject = ResizeObserver ? new Subject<void>() : null;
    const resizeObserver = ResizeObserver
      ? new ResizeObserver(() => resizeSubject.next())
      : null;
      if(resizeObserver) {
        resizeObserver.observe(target ? target : window);
      }
    return resizeSubject
      ? resizeSubject
        .asObservable()
        .pipe(finalize(() => resizeObserver.disconnect()))
      : fromEvent(window, 'resize');
  }

import {Component, EventEmitter, Input, Output, AfterContentInit, OnChanges} from '@angular/core';
import {ISelectItem} from "@core/interfaces/select-item";

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {

  @Input() tabs: ISelectItem[] = []; // список табов
  @Input() set selected(value: string | number) {
    if (!value) {
      this.selectedTab = this.tabs && this.tabs.length > 0 ? this.tabs[0].key : null;
    } else {
      this.selectedTab = value;
    }
  }; // выбранный таб
  @Output() OnSelect: EventEmitter<string | number> = new EventEmitter<string|number>();

  public selectedTab:  string | number = null;

  constructor() { }

  public change(tab: ISelectItem) {
    if (tab && !tab.disabled) {
      this.OnSelect.emit(tab.key);
    }
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { PasswordValidator } from "@shared/validators/password.validator";
import { TranslateService } from "@ngx-translate/core";
import { PasswordApiService } from "@core/services/api/password-api.service";
import { ToastrService } from "ngx-toastr";
import { IValidator } from "@core/interfaces/validator";
import { UserService } from "@core/services/user.service";
import { EnvService } from "@core/services/env.service";
import { SYSTEM_PARAMETER_NAME } from "@app/app.enums";
import { SystemParamsService } from "@core/services/system-params.service";
import { PasswordChangesService } from "@shared/services/password-changes.service";
import { finalize } from 'rxjs/operators';
import { ngThrottle } from '@app/shared/decorators/throttle.decorator';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent {

  @Input() forModal: boolean = true;
  @Output() OnCancel: EventEmitter<any> = new EventEmitter<any>();

  public pasMinLength: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_LENGTH)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_LENGTH)) : 8;
  public pasMinChanges: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASSWORD_MIN_NUM_CHANGING_CHARACTERS)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASSWORD_MIN_NUM_CHANGING_CHARACTERS))
    : 0;
  public pasMinUpper: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_UPPERCASES)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_UPPERCASES))
    : 1;
  public pasMinLower: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_LOWERCASES)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_LOWERCASES)) : 1;
  public pasMinNumbers: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_NUMBERS)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_NUMBERS)) : 1;
  public pasMinSpec: number = this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_SPECIAL_CHARACTERS)
    ? Number(this.systemParamsService.getSystemParam(SYSTEM_PARAMETER_NAME.PASS_COMPLEXITY_SPECIAL_CHARACTERS)) : 1;

  public isLoading: boolean = false;

  /**
   * Валидатор неравенства старого и нового паролей.
   */
  private currentNewPasswordsValidator: ValidatorFn = (form: FormGroup) => {
    const currentPassword = form.controls.currentPassword.value;
    const newPassword = form.controls.newPassword.value;
    return !!currentPassword && !!newPassword
      ? currentPassword === newPassword
        ? { currentNewPasswords: true }
        : null
      : null;
  }
  /**
   * Валидатор равенства новых паролей.
   */
  private newPasswordsValidator: ValidatorFn = (form: FormGroup) => {
    const first = form.controls.newPassword.value;
    const second = form.controls.newPasswordAgain.value;
    return !!first && !!second
      ? first !== second
        ? { newPasswords: true }
        : null
      : null;
  }

  private newPasswordChangesValidator: ValidatorFn = (form: FormGroup) => {
    const currentPassword = form.controls.currentPassword.value;
    const newPassword = form.controls.newPassword.value;
    if (!!currentPassword && !!newPassword && this.pasMinChanges) {
      const diff = this.passwordChangesService.levenshtein(currentPassword, newPassword);
      return diff && diff < this.pasMinChanges ? { minCountChanges: true } : null;
    } else {
      return null;
    }
  }


  public form: FormGroup = new FormGroup(
    {
      currentPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(this.pasMinLength),
        PasswordValidator(this.pasMinUpper, this.pasMinLower, this.pasMinNumbers, this.pasMinSpec)]),
      newPasswordAgain: new FormControl('', [
        Validators.required,
        Validators.minLength(this.pasMinLength),
        PasswordValidator(this.pasMinUpper, this.pasMinLower, this.pasMinNumbers, this.pasMinSpec)])
    },
    [this.currentNewPasswordsValidator, this.newPasswordsValidator, this.newPasswordChangesValidator]
  );

  public isCurrentPasswordError: boolean = false;
  public currentPasswordResult: string = null;
  public isNewPasswordError: boolean = false;
  public newPasswordResult: string = null;
  public isNewPasswordAgainError: boolean = false;
  public newPasswordAgainResult: string = null;
  public newPasswordTooltip: string[] = [];

  private successTitles: string[] = [];
  private errorTitles: string[] = [];
  private requirements: string = 'PASSWORD.REQUIREMENT';
  private errorRequest: string = '';
  public btnTitleForModal: string = '';
  public btnTitle: string = '';

  public mainIconUrl: string = null;

  constructor(
    private translateService: TranslateService,
    private passwordApiService: PasswordApiService,
    private toastr: ToastrService,
    private userService: UserService,
    private envService: EnvService,
    private systemParamsService: SystemParamsService,
    private passwordChangesService: PasswordChangesService
  ) {
    this.mainIconUrl = this.envService.mainIcon;
    this.translateService.get(['PASSWORD', 'GENERAL']).subscribe((result: string[]) => {
      this.successTitles = result['PASSWORD']['SUCCESS'];
      this.errorTitles = result['PASSWORD']['ERRORS'];
      this.errorRequest = result['GENERAL']['ERROR_REQUEST'];
      this.btnTitleForModal = result['GENERAL']['CHANGE'];
      this.btnTitle = result['GENERAL']['CONFIRM'];
    });
    this.newPasswordTooltip = [
      this.translateService.instant(`${this.requirements}.NO_RUS`),
      this.translateService.instant(`${this.requirements}.LENGTH`, { value: this.pasMinLength }),
      this.translateService.instant(`${this.requirements}.CAPITAL`, { value: this.pasMinUpper }),
      this.translateService.instant(`${this.requirements}.SMALL`, { value: this.pasMinLower }),
      this.translateService.instant(`${this.requirements}.NUMBER`, { value: this.pasMinNumbers }),
      this.translateService.instant(`${this.requirements}.SPEC`, { value: this.pasMinSpec }),
      this.translateService.instant(`${this.requirements}.CHANGE`, { value: this.pasMinChanges })
    ];
  }

  public cancel() {
    this.clearAndClose();
  }

  @ngThrottle()
  public changePassword() {
    if (this.form.valid) {
      this.isLoading = true;
      this.passwordApiService.changePassword(
        this.form.controls.newPassword.value,
        this.form.controls.currentPassword.value
      )
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(
          () => {
            this.clearAndClose();
            this.toastr.success(this.successTitles['CHANGE']);
            this.userService.logout();
          }, error => {
            if (error.error && error.error['message']) {
              this.toastr.error(error.error['message'], this.errorTitles['CHANGE']);
            } else {
              this.toastr.error(this.errorTitles['CHANGE']);
            }
            this.isCurrentPasswordError = error.error && error.error['params'] && error.error['params']['old_password'];
            this.currentPasswordResult = this.isCurrentPasswordError ? this.errorTitles['NO_VALID'] : null;
          });
    } else {
      this.checkCurrentPassword();
      this.checkNewPassword();
      this.checkNewPasswordAgain();
    }
  }

  private clearAndClose() {
    this.form.reset();
    this.isCurrentPasswordError = false;
    this.currentPasswordResult = null;
    this.isNewPasswordError = false;
    this.newPasswordResult = null;
    this.isNewPasswordAgainError = false;
    this.newPasswordAgainResult = null;
    if (this.forModal) {
      this.OnCancel.emit();
    }
  }

  public changeField(value: any, field: string) {
    switch (field) {
      case 'currentPassword': {
        this.checkCurrentPassword();
        if (this.form.controls.newPassword.touched) {
          this.checkNewPassword();
        }
        break;
      }
      case 'newPassword': {
        this.checkNewPassword();
        if (this.form.controls.newPasswordAgain.touched) {
          this.checkNewPasswordAgain();
        }
        break;
      }
      case 'newPasswordAgain': {
        this.checkNewPasswordAgain();
        if (this.form.controls.newPassword.touched) {
          this.checkNewPassword();
        }
        break;
      }
    }
  }

  private checkCurrentPassword() {
    const errors = this.form.get('currentPassword').errors || {};
    if (errors.required) {
      this.isCurrentPasswordError = true;
      this.currentPasswordResult = this.errorTitles['REQUIRED'];
    } else {
      this.isCurrentPasswordError = false;
      this.currentPasswordResult = null;
    }
  }

  private checkNewPassword() {
    const errors = this.form.get('newPassword').errors || {};

    if (errors.required) {
      this.isNewPasswordError = true;
      this.newPasswordResult = this.errorTitles['REQUIRED'];
    }
    else if ((errors.password && errors.password.length > 0) || errors.minlength
      || (this.form.errors && this.form.errors.minCountChanges)) {
      this.isNewPasswordError = true;
      this.newPasswordResult = this.errorTitles['NO_CORRECT'];
    }
    else if (this.form.errors && this.form.errors.currentNewPasswords) {
      this.isNewPasswordError = true;
      this.newPasswordResult = this.errorTitles['NO_VALID_TO_CURRENT'];
    }
    else {
      this.isNewPasswordError = false;
      this.newPasswordResult = this.successTitles['CORRECT'];
    }
    this.prepareTooltip(errors, this.form.errors);
  }

  private checkNewPasswordAgain() {
    const errors = this.form.get('newPasswordAgain').errors || {};
    if (errors.required) {
      this.isNewPasswordAgainError = true;
      this.newPasswordAgainResult = this.errorTitles['REQUIRED'];
    }
    else if ((errors.password && errors.password.length > 0) || errors.minlength) {
      this.isNewPasswordAgainError = true;
      this.newPasswordAgainResult = this.errorTitles['NO_CORRECT'];
    }
    else if (this.form.errors && this.form.errors.newPasswords) {
      this.isNewPasswordAgainError = true;
      this.newPasswordAgainResult = this.errorTitles['NO_CHECK'];
    } else {
      this.isNewPasswordAgainError = false;
      this.newPasswordAgainResult = this.successTitles['CHECK'];
    }
  }

  private prepareTooltip(errors: ValidationErrors, formErrors?: ValidationErrors) {
    this.newPasswordTooltip = [];
    if (!errors && !formErrors) {
      return;
    }

    if (errors.minlength) {
      this.newPasswordTooltip.push(
        this.translateService.instant(`${this.requirements}.LENGTH`, { value: this.pasMinLength })
      );
    }
    if (errors.password) {
      errors.password.forEach((error: IValidator) => {
        if (error['password-rus']) {
          this.newPasswordTooltip.push(
            this.translateService.instant(`${this.requirements}.NO_RUS`)
          );
        }
        if (error['password-AZ']) {
          this.newPasswordTooltip.push(
            this.translateService.instant(`${this.requirements}.CAPITAL`, { value: this.pasMinUpper })
          );
        }
        if (error['password-az']) {
          this.newPasswordTooltip.push(
            this.translateService.instant(`${this.requirements}.SMALL`, { value: this.pasMinLower })
          );
        }
        if (error['password-num']) {
          this.newPasswordTooltip.push(
            this.translateService.instant(`${this.requirements}.NUMBER`, { value: this.pasMinNumbers })
          );
        }
        if (error['password-spec']) {
          this.newPasswordTooltip.push(
            this.translateService.instant(`${this.requirements}.SPEC`, { value: this.pasMinSpec })
          );
        }
      });
    }
    if (formErrors && formErrors.minCountChanges) {
      this.newPasswordTooltip.push(
        this.translateService.instant(`${this.requirements}.CHANGE`, { value: this.pasMinChanges })
      );
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ExtendFilterPanelComponent } from "./extend-filter-panel/extend-filter-panel.component";
import {
  IExtendFilter,
  ISelectExtendFilter
} from "@shared/components/base/ag-base-grid/ag-base-grid.interface";
import {OpenModalService} from "@shared/services/open-modal.service";

@Component({
  selector: 'app-extend-filters',
  templateUrl: './extend-filters.component.html',
  styleUrls: ['./extend-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtendFiltersComponent {

  @Input() selectedFilters: ISelectExtendFilter[] = [];
  @Input() filters: IExtendFilter[] = [];
  @Input() forFilter: boolean = false; // флаг типа Расширенного фильтра - для панели фильтров или отдельно над гридом
  @Input() countShowValuesForFilter: number = 1; // кол-во отображаемых выбранных значений для панели фильтров
  @Output() changeFilters: EventEmitter<ISelectExtendFilter[]> = new EventEmitter<ISelectExtendFilter[]>(); //изменение фильтров
  constructor(
    private modalService: OpenModalService
  ) { }

  public openFilterPanel() {
    this.modalService.show({
      component: ExtendFilterPanelComponent,
      data: {
        selectedFilters: this.selectedFilters,
        filters: this.filters,
        changeFilters: (extendFilters: ISelectExtendFilter[]) => {
          this.changeFilters.emit(extendFilters);
        }
      },
      options:{
        centered: true,
        windowClass: 'modal-extend-filter'
      },
      callbacks: {
        Enter: 'applyExtendFilters',
        Escape: 'close'
      }
    });
  }

  public clearExtendFilters() {
    this.changeFilters.emit([]);
  }

  public removeExtendFilter(index: number) {
    this.selectedFilters = this.selectedFilters.filter((filter: ISelectExtendFilter, ind: number) => ind !== index);
    this.changeFilters.emit(this.selectedFilters);
  }
}

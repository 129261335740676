import { Component, Input, OnChanges, forwardRef, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import * as dayjs from 'dayjs';
import * as dayjsFormats from 'dayjs/plugin/customParseFormat';
import * as dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { CalendarWithApplySupportComponent } from '../calendar-with-apply/calendar-with-apply-support.component';
import { DateFormat } from '@app/shared/services/date-time.service';
import { IBaseCalendarProperties } from '../../base-components/base-calendar.component';
dayjs.extend(dayjsFormats);
dayjs.extend(dayjsIsSameOrBefore);
dayjs.extend(dayjsIsSameOrAfter);

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true
    },
    {
      provide: CalendarWithApplySupportComponent,
      useExisting: forwardRef(() => CalendarComponent)
    }
  ]
})
export class CalendarComponent
  extends CalendarWithApplySupportComponent<string>
  implements OnChanges,
  ControlValueAccessor,
  IBaseCalendarProperties<string> {

  private readonly dateFormat = DateFormat.DATE;
  private readonly timeFormat = DateFormat.TIME;
  private readonly timeWithSecondFormat = DateFormat.TIME_WITH_SECONDS;
  /**
   * Текущий год
   */
  private readonly currentYear = this.currentMoment.year();
  /**
   * Текущий месяц
   */
  private readonly currentMonth = this.currentMoment.month() + 1;
  /**
   * Флаг использования даты.
   */
  public withDate: boolean = true;
  /**
   * Флаг использования времени.
   */
  public withTime: boolean = false;
  /**
   * Флаг использования секунд.
   */
  public withSeconds: boolean = false;
  /**
   * Минимальная дата для компонента календаря.
   */
  public minDateForCalendar: string = null;
  /**
   * Максимальная дата для компонента календаря.
   */
  public maxDateForCalendar: string = null;

  public hasErrorMin: boolean = false;
  public hasErrorMax: boolean = false;
  public errorMin: string = null;
  public errorMax: string = null;
  private readonly errorMinText: string = this.translateService.instant('GENERAL.ERROR_DATE_MIN');
  private readonly errorMaxText: string = this.translateService.instant('GENERAL.ERROR_DATE_MAX');

  /**
   * @inheritdoc
   * @default DateFormat.DATE
   */
  @Input() format: DateFormat = this.dateFormat;
  /**
   * @inheritdoc
   * @default false
   */
  @Input() noManualInput: boolean = false;
  /**
   * Флаг наличия ошибки.
   */
  @Input() hasError: boolean = false;
  /**
   * Текст ошибки.
   */
  @Input() errorText: string = null;
  /**
   * @inheritdoc
   * @default true
   */
  @Input() withMinutes: boolean = true;
  /**
   * @inheritdoc
   */
  @Input() minDate: string = null;
  /**
   * @inheritdoc
   */
  @Input() maxDate: string = null;
  /**
   * @inheritdoc
   * @default 1
   */
  @Input() minMonth: number = 1;
  /**
   * @inheritdoc
   * @default текущий месяц
   */
  @Input() maxMonth: number = this.currentMonth;
  /**
   * @inheritdoc
   * @default 'bottom left'
   */
  @Input() openDirection: string = 'bottom left';
  /**
   * Положение тултипа.
   * @default 'top'
   */
  @Input() tooltipPlacement: string = 'top';

  constructor(
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.format) {
      this.prepareDateTimeFormats();
    }
    if (changes.format ||
      changes.isNeedLimit ||
      changes.minDate ||
      changes.maxDate) {
      this.setMinMax();
    }
  }

  /**
   * Метод установки минимальных и максимальных значений.
   */
  private setMinMax() {
    if (this.isNeedLimit) {
      if (this.minDate) {
        this.minDateForCalendar = dayjs(this.minDate, this.format).format(this.dateFormat);
        this.minMonth = dayjs(this.minDate, this.format).month() + 1;
        this.minYear = dayjs(this.minDate, this.format).year();
      }
      if (this.maxDate) {
        this.maxDateForCalendar = dayjs(this.maxDate, this.format).format(this.dateFormat);
        this.maxMonth = dayjs(this.maxDate, this.format).month() + 1;
        this.maxYear = dayjs(this.maxDate, this.format).year();
      }
    }
  }

  /**
   * Подготовить формат даты и времени.
   */
  private prepareDateTimeFormats() {
    this.withDate = this.format.includes(this.dateFormat);
    const timeFormat: string = this.format !== this.dateFormat
      ? !!this.format.split(' ')[1]
        ? this.format.split(' ')[1]
        : this.format
      : null;
    this.withTime = timeFormat === this.timeWithSecondFormat || timeFormat === this.timeFormat;
    this.withSeconds = timeFormat === this.timeWithSecondFormat;
  }

  /**
   * Коллбек изменения даты в поле.
   * @param value
   * @returns
   */
  public enterDateTime(value: string) {
    if (!value) {
      this.clear();
      return;
    }
    this.selectDateTime(value);
  }

  /**
   * Коллбек выбора даты в календаре.
   * @param value значение.
   */
  public selectDateTime(value: string) {
    if (this.selected != value) {
      this.selected = value;
      this.checkDate();
      this.emitChanges(this.selected);
    }
  }

  /**
   * Метод проверки даты.
   */
  private checkDate() {
    if (this.selected && this.minDate) {
      const date = dayjs(this.selected, this.format);
      const minMoment = dayjs(this.minDate, this.format);
      this.hasErrorMin = date.isBefore(minMoment);
      this.errorMin = date.isBefore(minMoment) ? `${this.errorMinText} ${this.minDate}` : null;
    } else {
      this.hasErrorMin = false;
      this.errorMin = null;
    }
    if (this.selected && this.maxDate) {
      const date = dayjs(this.selected, this.format);
      const maxMoment = dayjs(this.maxDate, this.format);
      this.hasErrorMax = date.isAfter(maxMoment);
      this.errorMax = date.isAfter(maxMoment) ? `${this.errorMaxText} ${this.maxDate}` : null;
    } else {
      this.hasErrorMax = false;
      this.errorMax = null;
    }
  }

  /**
   * @inheritdoc
   */
  writeValue(value: string): void {
    this.selected = value ? value : null;
  }
}

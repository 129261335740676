import {Injectable} from "@angular/core";
import {IDictionaryBody, IDictionaryField, IDictionaryParams} from "@core/interfaces/dictionary";
import * as cloneDeep from 'lodash.clonedeep';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  constructor() {}

  private defaultList: IDictionaryBody = {
    elements: [],
    total: 0,
    pageSize: 20,
    pageNumber: 1,
    totalPages: 0
  };

  // сортировка по умолчанию
  private defaultSorting = [{field: "name", direction: "asc", index: 0}];

  private defaultParams: IDictionaryParams = {
    pagination: {
      pageSize: 20,
      pageNumber: 1
    },
    search: '',
    sortings: this.defaultSorting
  };

  public getDefaultList(): IDictionaryBody {
    return cloneDeep(this.defaultList);
  }

  public getDefaultList$(): BehaviorSubject<IDictionaryBody> {
    return new BehaviorSubject(cloneDeep(this.defaultList));
  }

  public getDefaultParams(): IDictionaryParams {
    return cloneDeep(this.defaultParams);
  }

  public updateList(dictionary: IDictionaryField, params: IDictionaryParams) {
    dictionary.dictLoading.next(true);
    dictionary.dictParams = params;
    dictionary.dictListRequest$(dictionary.dictParams).subscribe(
      (data: IDictionaryBody) => {
        if (dictionary.dictParams.pagination && dictionary.dictParams.pagination.pageNumber === 1) {
          dictionary.dictList.next(data);
        } else {
          const current = dictionary.dictList.getValue();
          dictionary.dictList.next({
            ...current,
            elements: [...current.elements, ...data.elements],
            pageNumber: data.pageNumber

          });
        }
        dictionary.dictLoading.next(false);
      },
      error => {
        if (dictionary.dictParams.search !== '') {
          dictionary.dictList = this.getDefaultList$();
        }
        dictionary.dictLoading.next(false);
      }
    );
  }
}

import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    Renderer2,
    RendererFactory2
} from "@angular/core";
import { PopupContainerComponent } from "../components/popup-container/popup-container.component";

@Injectable()
export class PopupContainerService {

    private renderer2: Renderer2;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private rendererFactory: RendererFactory2,
        private injector: Injector
    ) {
        this.renderer2 = this.rendererFactory.createRenderer(null, null);
    }

    private componentRef: ComponentRef<PopupContainerComponent> = null;

    public getComponent(): ComponentRef<PopupContainerComponent> {
        return this.componentRef;
    }

    /**
     * Создать компоненнт-контейнер и добавить его в body
     * @param params параметры
     * @returns ссылка на компонент
     */
    public createPopup(params: Partial<PopupContainerComponent>): ComponentRef<PopupContainerComponent> {
        if (!params || !params.popupContainer) {
            return;
        }
        const componentRef = this.componentFactoryResolver
            .resolveComponentFactory(PopupContainerComponent)
            .create(this.injector);
        Object.assign(componentRef.instance, params);
        this.applicationRef.attachView(componentRef.hostView);
        const componentRootNode = (componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
        this.renderer2.appendChild(document.body, componentRootNode);
        this.componentRef = componentRef;
        return componentRef;
    }

    /**
     * Удалить компонент-контейнер и убрать его из body
     */
    public deletePopup() {
        if (!this.componentRef) {
            return;
        }
        this.applicationRef.detachView(this.componentRef.hostView);
        this.componentRef.destroy();
        this.componentRef = null;
    }
}

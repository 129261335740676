import {Component, OnDestroy} from '@angular/core';
import {SYSTEM_PARAMETER_NAME} from "@app/app.enums";
import {SystemParamsService} from "@core/services/system-params.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnDestroy {

  public supportPhone: string;
  public supportEmail: string;
  private publicSystemParamsSubscription: Subscription = new Subscription();

  constructor(
    private systemParamsService: SystemParamsService
  ) {
    this.publicSystemParamsSubscription = this.systemParamsService.getPublicSystemParams()
      .subscribe((systemParams: any) => {
      this.supportPhone = systemParams ? systemParams[SYSTEM_PARAMETER_NAME.DEVELOPER_PHONE] : '';
      this.supportEmail = systemParams ? systemParams[SYSTEM_PARAMETER_NAME.DEVELOPER_EMAIL] : '';
    });
  }

  ngOnDestroy() {
    this.publicSystemParamsSubscription.unsubscribe();
  }

}

import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { tap, catchError } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
import { BroadcastChannelKeys } from "@app/app.enums";
import { BroadcastChannelService } from "@core/services/broadcast-channel.service";

export interface ITokenResponse {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number; //в секундах
  scope: string;
}

const GRANT_TYPE_PASSWORD: string = "password";
const GRANT_TYPE_REFRESH_TOKEN: string = "refresh_token";

@Injectable({
  providedIn: "root"
})
export class OAuth2Service implements AuthService {

  private tokenResponse: ITokenResponse;
  private readonly broadcastChannelKey = BroadcastChannelKeys.AUTH;

  constructor(
    private HttpClient: HttpClient,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private broadcastChannelService: BroadcastChannelService
  ) {
    this.tokenResponse = this.getTokenResponseFromCookie();
    this.createBroadcastChannel();
  }

  public login(username, password): Observable<ITokenResponse> {
    this.clearCredentials();
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Basic ${this.getAppAuthorizationCode()}`
    });
    let authPayload = new FormData();
    authPayload.append("grant_type", GRANT_TYPE_PASSWORD);
    authPayload.append("username", username);
    authPayload.append("password", password);
    return this.HttpClient.post<ITokenResponse>(`${environment.AUTH_URL}/oauth/token`, authPayload, { headers, withCredentials: true })
      .pipe(tap(res => this.setCredentials(res)));
  }

  public logout() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`
    });
    return this.HttpClient.post(`${environment.AUTH_URL}/oauth/revoke`, {}, { headers })
      .pipe(tap(() => this.clearCredentials()));
  }

  public refreshToken(): Observable<ITokenResponse> {
    const formData = new FormData();
    const refreshToken = this.tokenResponse ? this.tokenResponse.refresh_token : null;
    formData.append("grant_type", GRANT_TYPE_REFRESH_TOKEN);
    formData.append("refresh_token", refreshToken);
    return this.HttpClient.post<ITokenResponse>(`${environment.AUTH_URL}/oauth/token`, formData)
      .pipe(
        tap(res => this.setCredentials(res)),
        catchError(error => {
          this.clearCredentials();
          return throwError(error);
        })
      );
  }

  public getToken(): string {
    return this.tokenResponse ? this.tokenResponse.access_token : null;
  }

  public setCredentials(tokenResponse: ITokenResponse): void {
    this.tokenResponse = tokenResponse;
    this.setTokenResponseToCookie(tokenResponse);
    this.broadcastChannelService.postMessageToBroadcastChannel(
      tokenResponse,
      this.broadcastChannelKey,
    );
  }

  public clearCredentials(): void {
    this.tokenResponse = null;
    this.removeTokenResponseFromCookie();
  }

  public isAuthorized(): Boolean {
    return !!this.tokenResponse;
  }

  public getAppAuthorizationCode(): string {
    return btoa(`${environment.AUTH_USERNAME}:${environment.AUTH_PASSWORD}`);
  }

  private getTokenResponseFromCookie(): ITokenResponse {
    return JSON.parse(this.localStorageService.getObjectByName('auth'));
  }

  private setTokenResponseToCookie(tokenResponse: ITokenResponse): void {
    this.localStorageService.setObjectByName('auth', JSON.stringify(tokenResponse));
  }

  private removeTokenResponseFromCookie(): void {
    this.localStorageService.removeObjectByName('auth');
  }

  private createBroadcastChannel(): void {
    this.broadcastChannelService.setBroadcastChannelByName(
      this.broadcastChannelKey,
    );
    this.broadcastChannelService.addMessageListener(
      () => {
        setTimeout(() => {
          this.tokenResponse = this.getTokenResponseFromCookie();
        });
      },
      this.broadcastChannelKey,
    );
  }
}

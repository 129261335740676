import { AfterViewInit, Component, ElementRef, Host, HostBinding, OnDestroy, Renderer2 } from "@angular/core";
import { getResizeObservable } from "@app/shared/functions/resize-observable.function";
import { animationFrameScheduler, fromEvent, merge, Subject } from "rxjs";
import { filter, takeUntil, throttleTime } from "rxjs/operators";
import { PopupContainerDirective } from "../../directives/popup-container.directive";
import { PopupContainerBase } from "../popup-container-base/popup-container-base";

/**
 * Компонент отображения поповера
 */
@Component({
    selector: 'app-popup-container',
    templateUrl: './popup-container.component.html',
    styleUrls: ['./popup-container.component.scss']
})
export class PopupContainerComponent
    extends PopupContainerBase
    implements AfterViewInit, OnDestroy {

    @HostBinding('class') readonly defaultClass: string = 'popup-container';

    protected readonly destroy$ = new Subject<void>();

    /**
     * Родительский компонент попапа.
     */
    public parent: PopupContainerDirective;

    constructor(
        @Host() protected host: ElementRef<HTMLElement>,
        protected renderer2: Renderer2
    ) {
        super();
    }

    ngAfterViewInit(): void {
        if (this.popupContainerEventsList && this.popupContainerEventsList.length) {
            const handlers$ = this.popupContainerEventsList.map(eventName => fromEvent<Event>(
                this.host.nativeElement,
                eventName
            ));
            merge(...handlers$)
                .pipe(
                    filter(e => e.isTrusted),
                    takeUntil(this.destroy$)
                )
                .subscribe(e => {
                    if (this.parent) {
                        e.stopPropagation();
                    }
                    if (this.popupContainerEventTarget) {
                        e.stopPropagation();
                        const clone: Event = new (<any>e).constructor(e.type, e);
                        this.popupContainerEventTarget.dispatchEvent(clone);
                    }
                });
        }

        if (this.popupContainerCssProperties) {
          Object.keys(this.popupContainerCssProperties).forEach((key) => {
            this.host.nativeElement.style.setProperty(
              key,
              this.popupContainerCssProperties[key]
            );
          });
        }
        this.renderer2.setStyle(this.host.nativeElement, 'z-index', this.popupContainerZIndex);
        this.updatePosition();
        merge(
            fromEvent(document.defaultView, 'scroll', true as any),
            getResizeObservable(document.body)
        )
            .pipe(
                throttleTime(this.popupContainerEventsThrottle, animationFrameScheduler),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this.updatePosition());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    protected updatePosition() {
        if (!this.popupContainerTarget) {
            return;
        }
        const targetRect: DOMRect = this.popupContainerTarget.getBoundingClientRect() as DOMRect;
        const hostRect: DOMRect = this.host.nativeElement.getBoundingClientRect() as DOMRect;
        let top = targetRect.top + this.popupContainerOffsetTop;
        let left = targetRect.left + this.popupContainerOffsetLeft;
        if (this.popupContainerOpenDirection.includes('top')) {
            top -= hostRect.height + this.popupContainerOffsetRelative[1];
        }
        if (this.popupContainerOpenDirection.includes('bottom')) {
            top += targetRect.height + this.popupContainerOffsetRelative[1];
        }
        if (this.popupContainerOpenDirection.includes('left')) {
            left -= this.popupContainerOffsetRelative[0];
        }
        if (this.popupContainerOpenDirection.includes('center')) {
            left += (targetRect.width - hostRect.width) / 2;
        }
        if (this.popupContainerOpenDirection.includes('right')) {
            left += targetRect.width - hostRect.width + this.popupContainerOffsetRelative[0];
        }

        if (this.popupContainerPreventOutsideWindow) {
            left = Math.max(left, 0);
            top = Math.max(top, 0);
            const deltaRight = left + hostRect.width - window.innerWidth;
            if (deltaRight > 0) {
                left -= deltaRight;
            }
            const deltaBottom = top + hostRect.height - window.innerHeight;
            if (deltaBottom > 0) {
                top -= deltaBottom;
            }
        }

        this.renderer2.setStyle(this.host.nativeElement, 'top', `${top}px`);
        this.renderer2.setStyle(this.host.nativeElement, 'left', `${left}px`);
    }
}

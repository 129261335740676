import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ArrowComponent } from "./arrow.component";


@NgModule({
    declarations: [ArrowComponent],
    imports: [
        CommonModule
    ],
    exports: [ArrowComponent]
})
export class ArrowModule { }